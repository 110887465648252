<template>
  <div>
    <h4>Bitácora</h4>

    <b-card header="Filtros">
      <form @submit.prevent="actualizar">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Fecha:</label>
            <b-form-datepicker
              v-model="busqueda.fecha"
              placeholder="Todas"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="busqueda">
          <b-button type="submit"><font-awesome-icon icon="search" /> Buscar</b-button>
        </div>
      </form>
    </b-card>

    <ve-table
      rowKeyFieldName="bitacora_id"
      :columns="columnas"
      :table-data="listado"
      :border-y="true"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "Bitacora",
  data() {
    return {
      busqueda: { fecha: moment(Date.now()).format("YYYY-MM-DD") },
      listado: [],

      columnas: [
        {
          renderBodyCell: (d) => d.row.membresia.nombre_completo,
          key: "a",
          title: "Usuario",
          align: "left",
        },
        {
          field: "accion",
          key: "b",
          title: "Acción",
          align: "left",
        },

        {
          field: "adicional",
          key: "c",
          title: "Tabla",
          align: "left",
        },
        {
          field: "objeto_id",
          key: "d",
          title: "Id",
          align: "left",
        },
        {
          field: "ip",
          key: "e",
          title: "Ip",
          align: "left",
        },
        {
          field: "creado",
          key: "f",
          title: "Fecha",
          align: "left",
        },
      ],
    };
  },
  methods: {
    actualizar() {
      api.listarBitacora(this.busqueda).then((r) => {
        this.listado = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizar();
  },
};
</script>
