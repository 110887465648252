
<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos generales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="parroquia">Parroquia a la que asisten</label>
            <input
              maxlength="60"
              v-model.trim="matrimonio.parroquia"
              class="form-control"
            />
            <div class="errores" v-if="!$v.matrimonio.parroquia.letras">
              Ingrese solo letras
            </div>
          </div>
          <div class="form-group col-md-3">
            <div class="form-check">
              <input
                type="checkbox"
                id="casado_civil"
                v-model="matrimonio.casados_civil"
                class="form-check-input"
              />
              <label class="form-check-label" for="casado_civil"
                >¿Casados por el civil?
              </label>
            </div>
            <!--            <div class="form-group" v-if="matrimonio.casado_civil">
              <input
                type="date"
                id="fecha_boda_civil"
                v-model.trim="matrimonio.fecha_boda_civil"
                class="form-control"
              />
              <div class="errores" v-if="!$v.matrimonio.fecha_boda_civil.required">
                Requerido
              </div>
            </div> -->
          </div>
          <div class="form-group col-md-3">
            <div class="form-check">
              <input
                type="checkbox"
                id="casado_iglesia"
                v-model="matrimonio.casados_iglesia"
                class="form-check-input"
              />
              <label class="form-check-label" for="casado_iglesia"
                >¿Casados por la iglesia?</label
              >
            </div>
            <div class="form-group" v-if="matrimonio.casados_iglesia">
              <input
                type="date"
                id="fecha_boda_religiosa"
                v-model.trim="matrimonio.fecha_boda_religiosa"
                class="form-control"
              />
              <div
                class="errores"
                v-if="!$v.matrimonio.fecha_boda_religiosa.required"
              >
                Requerido
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales de él</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nombre_el">Nombre(s)</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.nombre_el"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.matrimonio.nombre_el.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.matrimonio.nombre_el.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop_el">Apellido paterno</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.apellidop_el"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidop_el.required"
                >
                  Requerido
                </div>
                <div class="errores" v-if="!$v.matrimonio.apellidop_el.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom_el">Apellido materno</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.apellidom_el"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidom_el.required"
                >
                  Requerido
                </div>
                <div class="errores" v-if="!$v.matrimonio.apellidom_el.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac_el">Fecha de nacimiento</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="matrimonio.fecha_nac_el"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.fecha_nac_el.required"
                >
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion_el">Ocupación</label>
                <input
                  maxlength="50"
                  v-model.trim="matrimonio.ocupacion_el"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.ocupacion_el.required"
                >
                  Requerido
                </div>
                <div class="errores" v-if="!$v.matrimonio.ocupacion_el.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_el_id">Grado de estudios</label>
                <select
                  v-model="matrimonio.grado_estudio_el_id"
                  class="form-control"
                >
                  <option
                    v-for="grado in gradoestudios"
                    :value="grado.grado_estudio_id"
                    :key="grado.grado_estudio_id"
                  >
                    {{ grado.nom_grado }}
                  </option>
                </select>
                <div
                  class="errores"
                  v-if="!$v.matrimonio.grado_estudio_el_id.required"
                >
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular_el">Celular</label>
                <input
                  maxlength="10"
                  type="text"
                  v-model.trim="matrimonio.celular_el"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.matrimonio.celular_el.telefono">
                  Ingrese solo números (10 dígitos)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo_el">Correo electrónico</label>
                <input
                  maxlength="50"
                  type="email"
                  v-model.trim="matrimonio.correo_el"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.matrimonio.correo_el.correo">
                  Ingrese una dirección de correo válido
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <archivo-imagen
              v-if="mostrarFoto"
              style="width: 180px; height: 180px"
              ref="foto_el"
              v-model="matrimonio.foto_el"
            ></archivo-imagen>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales de ella</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nombre_ella">Nombre(s)</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.nombre_ella"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.matrimonio.nombre_ella.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.matrimonio.nombre_ella.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop_ella">Apellido paterno</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.apellidop_ella"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidop_ella.required"
                >
                  Requerido
                </div>
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidop_ella.letras"
                >
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom_ella">Apellido materno</label>
                <input
                  maxlength="30"
                  v-model.trim="matrimonio.apellidom_ella"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidom_ella.required"
                >
                  Requerido
                </div>
                <div
                  class="errores"
                  v-if="!$v.matrimonio.apellidom_ella.letras"
                >
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac_ella">Fecha de nacimiento</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="matrimonio.fecha_nac_ella"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.fecha_nac_ella.required"
                >
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion_ella">Ocupación</label>
                <input
                  maxlength="50"
                  v-model.trim="matrimonio.ocupacion_ella"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.ocupacion_ella.required"
                >
                  Requerido
                </div>
                <div
                  class="errores"
                  v-if="!$v.matrimonio.ocupacion_ella.letras"
                >
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_ella_id">Grado de estudios</label>
                <select
                  v-model="matrimonio.grado_estudio_ella_id"
                  class="form-control"
                >
                  <option
                    v-for="grado in gradoestudios"
                    :value="grado.grado_estudio_id"
                    :key="grado.grado_estudio_id"
                  >
                    {{ grado.nom_grado }}
                  </option>
                </select>
                <div
                  class="errores"
                  v-if="!$v.matrimonio.grado_estudio_ella_id.required"
                >
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular_ella">Celular</label>

                <input
                  maxlength="10"
                  type="text"
                  v-model.trim="matrimonio.celular_ella"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.matrimonio.celular_ella.telefono"
                >
                  Ingrese solo números (10 dígitos)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo_ella">Correo electrónico</label>
                <input
                  maxlength="50"
                  type="email"
                  v-model.trim="matrimonio.correo_ella"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.matrimonio.correo_ella.correo">
                  Ingrese una dirección de correo válido (ejemplo@dominio.com)
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <archivo-imagen
              v-if="mostrarFoto"
              style="width: 180px; height: 180px"
              ref="foto_ella"
              v-model="matrimonio.foto_ella"
            ></archivo-imagen>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los hijos</h6>
      <div class="card-body">
        <div class="">
          <div class="text-right">
            <button class="btn btn-secondary" v-on:click="agregar_hijo">
              Agregar hijo
            </button>
          </div>
          <br />
          <table class="table table-bordered">
            <tr>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>
              <th></th>
            </tr>
            <tr
              v-for="(hijo, pos) in $v.matrimonio.hijos.$each.$iter"
              :key="hijo.$model.hijo_id"
            >
              <td>
                <input
                  maxlength="40"
                  v-model.trim="hijo.$model.nombre"
                  class="form-control"
                />
                <div class="errores" v-if="!hijo.nombre.required">
                  Requerido
                </div>
                <div class="errores" v-if="!hijo.nombre.letras">
                  Ingrese solo letras
                </div>
              </td>
              <td>
                <input
                  type="date"
                  v-model.trim="hijo.$model.fecha_nac"
                  class="form-control"
                />
                <div class="errores" v-if="!hijo.fecha_nac.required">
                  Requerido
                </div>
              </td>
              <td>
                <button
                  class="btn btn-secondary"
                  title="Quitar"
                  v-on:click="quitar_hijo(pos)"
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </button>
              </td>
            </tr>
            <tr v-if="matrimonio.hijos.length == 0">
              <td colspan="2">No se han agregado hijos</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <input
              maxlength="50"
              v-model.trim="matrimonio.calle"
              class="form-control"
            />
            <div class="errores" v-if="!$v.matrimonio.calle.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <select
              v-model="matrimonio.estado_id"
              v-on:change="actualizaMunicipios"
              class="form-control"
            >
              <option
                v-for="estado in estados"
                :value="estado.estado_id"
                :key="estado.estado_id"
              >
                {{ estado.nom_estado }}
              </option>
            </select>
            <div class="errores" v-if="!$v.matrimonio.estado_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <select
              v-model="matrimonio.municipio_id"
              v-on:change="actualizaLocalidades"
              class="form-control"
            >
              <option
                v-for="municipio in municipios"
                :value="municipio.municipio_id"
                :key="municipio.municipio_id"
              >
                {{ municipio.nom_municipio }}
              </option>
            </select>
            <div class="errores" v-if="!$v.matrimonio.municipio_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <select
              v-model="matrimonio.localidad_id"
              v-on:change="actualizaCp"
              class="form-control"
            >
              <option
                v-for="localidad in localidades"
                :value="localidad.localidad_id"
                :key="localidad.localidad_id"
              >
                {{ localidad.nom_localidad }} - {{ localidad.tipo_localidad }}
              </option>
            </select>
            <div class="errores" v-if="!$v.matrimonio.localidad_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <input
              maxlength="4"
              type="number"
              v-model="matrimonio.codigo_postal"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <input
              maxlength="10"
              type="text"
              v-model="matrimonio.telefono_domicilio"
              class="form-control"
            />
            <div
              class="errores"
              v-if="!$v.matrimonio.telefono_domicilio.telefono"
            >
              Ingrese solo números (número con Lada)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import { telefono, letras, correo } from "@/libs/validadores";
export default {
  name: "DatosMatrimonio",
  components: { ArchivoImagen },
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      estados: [],
      municipios: [],
      localidades: [],
      matrimonio: { hijos: [] },
      gradoestudios: [],
    };
  },
  validations() {
    return {
      matrimonio: {
        parroquia: { letras },
        nombre_el: { required, letras },
        apellidop_el: { required, letras },
        apellidom_el: { required, letras },
        fecha_nac_el: { required },
        ocupacion_el: { required, letras },
        grado_estudio_el_id: { required },
        celular_el: { telefono },
        correo_el: { correo },
        nombre_ella: { required, letras },
        apellidop_ella: { required, letras },
        apellidom_ella: { required, letras },
        fecha_nac_ella: { required },
        ocupacion_ella: { required, letras },
        grado_estudio_ella_id: { required },
        celular_ella: { telefono },
        correo_ella: { correo },
        calle: { required },
        estado_id: { required },
        municipio_id: { required },
        localidad_id: { required },
        telefono_domicilio: { telefono },
        fecha_boda_religiosa: {
          required: requiredIf(function (s) {
            return s.casado_iglesia;
          }),
        },
        hijos: {
          $each: {
            nombre: { required, letras },
            fecha_nac: { required },
          },
        },
      },
    };
  },
  methods: {
    valido: function () {
      this.$v.matrimonio.$touch();
      return !this.$v.matrimonio.$invalid;
    },
    agregar_hijo: function () {
      this.matrimonio.hijos.push({
        hijo_id: -this.matrimonio.hijos.length,
        nombre: null,
        fecha_nac: null,
      });
    },
    quitar_hijo: function (pos) {
      if (this.matrimonio.hijos[pos].hijo_id > 0) {
        this.matrimonio.hijos_quitar.push(this.matrimonio.hijos[pos].hijo_id);
      }
      this.matrimonio.hijos.splice(pos, 1);
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.matrimonio.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.matrimonio.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.matrimonio.localidad_id;
      let matrimonio = this.matrimonio;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          matrimonio.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaMatrimonio: function () {
      this.matrimonio.estado_id != null && this.actualizaMunicipios();
      this.matrimonio.municipio_id != null && this.actualizaLocalidades();
      this.matrimonio.localidad_id != null && this.actualizaCp();
      this.matrimonio.hijos_quitar = [];
    },
  },
  computed: {},
  mounted: function () {
    this.matrimonio = this.value;
    this.inicializaMatrimonio();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
