<template>
  <div>
    <div class="fondo" :class="{ 'compacta': compacta }"></div>
    <div class="sidebar" :class="{ 'compacta': compacta }">
      <div class="header">
        <img src="../assets/img/mfc.png" />
        <span class="logo_name">Sistema MFC</span>
      </div>
      <ul class="content">
        <li v-for="(m, pos) in menu" :key="pos" v-show="$parent.permisos(m.path)">
          <div class="iocn-link">
            <a href="#">
              <box-icon color="white" :name="m.icon" />
              <span class="link_name">{{ m.title }}</span>
            </a>
          </div>
          <ul class="sub-menu">
            <li> <a class="link_name" href="#">{{ m.title }}</a></li>
            <li v-for="(sm, spos) in m.child" :key="spos" v-show="$parent.permisos(sm.path)">
              <router-link :to="sm.href" :key="sm.fullPath">
                {{ sm.title }}
                <span v-if="sm.child" class="arrow-icon">&gt;</span>
              </router-link>
              <ul class="sub-sub-menu" v-if="sm.child">
                <li v-for="(ssm, sspos) in sm.child" :key="sspos" v-show="$parent.permisos(ssm.path)"> 
                  <router-link :to="ssm.href" :key="ssm.fullPath">{{ ssm.title }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li v-if="false">
          <div class="profile-details">
            <div class="profile-content">
              <img src="../assets/img/usuario.png" />
            </div>
            <div class="name-job">
              <div class="profile_name">{{ nombreCompleto }}</div>
              <div class="job">{{ rolCompleto }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
//import store from '../store'
export default {
  props: {
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      visibles: {},
      compacta: true,
      permisos: [],
    };
  },
  methods: {
    barra() {
      this.compacta = !this.compacta;
    }
  },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    
    sesionIniciada() {
      return this.$store.getters.sesionIniciada();
    },
    esAdministrador() {
      return this.$store.getters.esAdmin;
    },
  },
  mounted() {
    let temp = {};
    this.menu.forEach(function(m, pos) { temp['menu'+pos] = false});
    this.visibles = temp;
    this.permisos = this.$store.getters.permisos;

    /*let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
        arrowParent.classList.toggle("showMenu");
      });
    }

    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector(".bx-menu");
    sidebarBtn.addEventListener("click", () => {
      sidebar.classList.toggle("compacta");
    });*/
  },
};
</script>
<style>
.fondo {
  width: 260px;
  transition: all 0.5s ease;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #005043;
  z-index: 100;
  transition: all 0.5s ease;
}
.fondo.compacta {
  width: 60px;
}
.sidebar.compacta {
  width: 60px;
}
.sidebar .header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .header i {
  font-size: 20px;
  color: #fff;
  height: 50px;
  min-width: 60px;
  text-align: center;
  line-height: 50px;
}
.sidebar .header img {
  font-size: 30px;
  color: #fff;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  line-height: 50px;
}
.sidebar .header .logo_name {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.compacta .header .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .content {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.compacta .content {
  overflow: visible;
}
.sidebar .content::-webkit-scrollbar {
  display: none;
}
.sidebar .content li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .content li:hover {
  background: #1d1b31;
}
.sidebar .content li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.compacta .content li .iocn-link {
  display: block;
}
.sidebar .content li box-icon {
  height: 50px;
  min-width: 60px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sidebar .content li.showMenu box-icon.arrow {
  transform: rotate(-180deg);
}
.sidebar.compacta .content box-icon.arrow {
  display: none;
}
.sidebar .content li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .content li a .link_name {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.compacta .content li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .content li .sub-menu {
  display: none;
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
}

.sidebar .content li:hover .sub-menu {
  display: block;
}

.sidebar .content li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
/* New CSS for sub-sub-menu */
.sidebar .content li .sub-menu li {
  position: relative;
}

.sidebar .content li .sub-menu li .sub-sub-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 10px 20px;
  background: #2b2a43;
  border-radius: 0 6px 6px 0;
  white-space: nowrap;
  z-index: 10;
}

.sidebar .content li .sub-menu li:hover > .sub-sub-menu {
  display: block;
}

.sidebar .content li .sub-menu li .sub-sub-menu a {
  color: #fff;
  font-size: 14px;
  padding: 5px 0;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .content li .sub-menu li .sub-sub-menu a:hover {
  opacity: 1;
}

.sidebar .content li .sub-menu li .sub-sub-menu .link_name {
  display: none;
}

.sidebar.compacta .content li .sub-menu li:hover > .sub-sub-menu {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.arrow-icon {
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .content li .sub-menu li:hover .arrow-icon {
  opacity: 1;
}

.sidebar .content li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.compacta .content li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.compacta .content li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .content li .sub-menu .link_name {
  display: none;
}
.sidebar.compacta .content li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .content li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .content li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.compacta .profile-details {
  background: none;
}
.sidebar.compacta .profile-details {
  width: 60px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img,
.sidebar .profile-details i {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.compacta .profile-details img,
.sidebar.compacta .profile-details i {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.compacta .profile-details i,
.sidebar.compacta .profile-details .profile_name,
.sidebar.compacta .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}

@media screen and (max-width: 400px) {
  .sidebar {
    width: 240px;
  }
  .sidebar.compacta {
    width: 60px;
  }
  .sidebar .profile-details {
    width: 240px;
  }
  .sidebar.compacta .profile-details {
    background: none;
  }
  .sidebar.compacta .profile-details {
    width: 60px;
  }
}
</style>