<template>
  <div>
    <div class="card">
      <h2 class="card-header">Reporte S-03: Concentrado de Matrimonios por Sector</h2>
      <div id="tabla-container"> <!-- Datos del reporte -->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Sector:</th>
              <td>{{ reporte.general.sector }}</td>
            </tr>
            <tr>
              <th class="titulo">Diócesis:</th>
              <td>{{ reporte.general.diocesis }}</td>
            </tr>
            <tr>
              <th class="titulo">Región:</th>
              <td>{{ reporte.general.region }}</td>
            </tr>
            <tr>
              <th class="titulo">Ciclo:</th>
              <td>{{ reporte.general.ciclo }}</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Datos del Reporte -->

      <div class="card-body"> <!--Matrimonios en el CBF-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Datos de Matrimonios que están cursando el Ciclo Básico de Formación (CBF)</th>
                <th>Primer Nivel</th>
                <th>Segundo Nivel</th>
                <th>Tercer Nivel</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Matrimonios</td>
                <td class="text-center">{{ reporte.datos.equiperos['1'] }}</td>
                <td class="text-center">{{ reporte.datos.equiperos['2'] }}</td>
                <td class="text-center">{{ reporte.datos.equiperos['3'] }}</td>
                <td class="text-center">{{ reporte.datos.equiperos.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de Equipos</td>
                <td class="text-center">{{ reporte.datos.equipos['1'] }}</td>
                <td class="text-center">{{ reporte.datos.equipos['2'] }}</td>
                <td class="text-center">{{ reporte.datos.equipos['3'] }}</td>
                <td class="text-center">{{ reporte.datos.equipos.total }}</td>
              </tr>
              <tr> <!--Renglón 3-->
                <td>Número de Equipos Zonales</td>
                <td class="text-center">{{ reporte.datos.zonales['1'] }}</td>
                <td class="text-center">{{ reporte.datos.zonales['2'] }}</td>
                <td class="text-center">{{ reporte.datos.zonales['3'] }}</td>
                <td class="text-center">{{ reporte.datos.zonales.total }}</td>
              </tr>
              <tr> <!--Renglón 4-->
                <td>Número de Asistentes eclesiales de Zona</td>
                <td class="text-center">{{ reporte.datos.asistentes['1'] }}</td>
                <td class="text-center">{{ reporte.datos.asistentes['2'] }}</td>
                <td class="text-center">{{ reporte.datos.asistentes['3'] }}</td>
                <td class="text-center">{{ reporte.datos.asistentes.total }}</td>
              </tr>
              <tr> <!--Renglón 5-->
                <td>Número de matrimonios que han vivido el Kerigma (K)</td>
                <td class="text-center">{{ reporte.datos.kerigma['1'] }}</td>
                <td class="text-center">{{ reporte.datos.kerigma['2'] }}</td>
                <td class="text-center">{{ reporte.datos.kerigma['3'] }}</td>
                <td class="text-center">{{ reporte.datos.kerigma.total }}</td>
              </tr>
              <tr> <!--Renglón 6-->
                <td>Número de matrimonios que han vivido el Encuentro Conyugal (EC)</td>
                <td class="text-center">{{ reporte.datos.conyugal['1'] }}</td>
                <td class="text-center">{{ reporte.datos.conyugal['2'] }}</td>
                <td class="text-center">{{ reporte.datos.conyugal['3'] }}</td>
                <td class="text-center">{{ reporte.datos.conyugal.total }}</td>
              </tr>
              <tr> <!--Renglón 7-->
                <td>Número de matrimonios que han vivido el Encuentro Familiar (EF)</td>
                <td class="text-center">{{ reporte.datos.familiar['1'] }}</td>
                <td class="text-center">{{ reporte.datos.familiar['2'] }}</td>
                <td class="text-center">{{ reporte.datos.familiar['3'] }}</td>
                <td class="text-center">{{ reporte.datos.familiar.total }}</td> 
              </tr>
              <tr> <!--Renglón 8-->
                <td>Número de matrimonios que han vivido la Capacitación Integral progresiva I (CIP I)</td>
                <td class="text-center">{{ reporte.datos.cp1['1'] }}</td>
                <td class="text-center">{{ reporte.datos.cp1['2'] }}</td>
                <td class="text-center">{{ reporte.datos.cp1['3'] }}</td>
                <td class="text-center">{{ reporte.datos.cp1.total }}</td>
              </tr>
              <tr> <!--Renglón 9-->
                <td>Número de matrimonios que han vivido la Capacitación Integral progresiva II (CIP II)</td>
                <td class="text-center">{{ reporte.datos.cp2['1'] }}</td>
                <td class="text-center">{{ reporte.datos.cp2['2'] }}</td>
                <td class="text-center">{{ reporte.datos.cp2['3'] }}</td>
                <td class="text-center">{{ reporte.datos.cp2.total }}</td>
              </tr>
              <tr> <!--Renglón 10-->
                <td>Número de matrimonios que han vivido otras capacitaciones</td>
                <td class="text-center">{{ reporte.datos.otr['1'] }}</td>
                <td class="text-center">{{ reporte.datos.otr['2'] }}</td>
                <td class="text-center">{{ reporte.datos.otr['3'] }}</td>
                <td class="text-center">{{ reporte.datos.otr.total }}</td>
              </tr>
              <tr> <!--Renglón 11-->
                <td>Número de matrimonios que prestan servicio</td>
                <td class="text-center">{{ reporte.datos.servicio['1'] }}</td>
                <td class="text-center">{{ reporte.datos.servicio['2'] }}</td>
                <td class="text-center">{{ reporte.datos.servicio['3'] }}</td>
                <td class="text-center">{{ reporte.datos.servicio.total }}</td>
              </tr>
              <tr> <!--Renglón 12-->
                <td>Número de matrimonios que cumplen con su ofrenda mensual</td>
                <td class="text-center">{{ reporte.datos.ofrenda['1'] }}</td>
                <td class="text-center">{{ reporte.datos.ofrenda['2'] }}</td>
                <td class="text-center">{{ reporte.datos.ofrenda['3'] }}</td>
                <td class="text-center">{{ reporte.datos.ofrenda.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->
      </div> <!-- card-body Matrimonios en CBF -->

      <div class="card-body"> <!--Matrimonios en Servicio-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Datos de matrimonios en diversos servicios</th>
                <th>Promotores</th>
                <th>Equipo de encuentros</th>
                <th>Servicios de Área II</th>
                <th>Financieros de Zona</th>
                <th>Equipos de capacitación</th>
                <th>Equipos de Sector y Diocesano</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Matrimonios</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.matrimonios.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de matrimonios con rol adicional</td>
                <td class="text-center">{{ reporte.servicios.rola.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.rola.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.rola.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.rola.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.rola.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.rola.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.rola.total }}</td>
              </tr>
              <tr> <!--Renglón 3-->
                <td>Número  de matrimonios que han vivido el Kerigma (K)</td>
                <td class="text-center">{{ reporte.servicios.kerigma.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.total }}</td>
              </tr>
              <tr> <!--Renglón 4-->
                <td>Número de matrimonios que han vivido el Encuentro Conyugal (EC)</td>
                <td class="text-center">{{ reporte.servicios.conyugal.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.conyugal.total }}</td>
              </tr>
              <tr> <!--Renglón 5-->
                <td>Número de matrimonios que han vivido el Encuentro Familiar (EF)</td>
                <td class="text-center">{{ reporte.servicios.familiar.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.familiar.total }}</td>
              </tr>
              <tr> <!--Renglón 6-->
                <td>Número de matrimonios que han vivido la Capacitación Integral Progresiva I (CIP I)</td>
                <td class="text-center">{{ reporte.servicios.cp1.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.total }}</td>
              </tr>
              <tr> <!--Renglón 7-->
                <td>Número de matrimonios que han vivido la Capacitación Integral Progresiva II (CIP II)</td>
                <td class="text-center">{{ reporte.servicios.cp2.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.total }}</td>
              </tr>
              <tr> <!--Renglón 8-->
                <td>Número de matrimonios con el curso de Metodología (M)</td>
                <td class="text-center">{{ reporte.servicios.metodologia.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.metodologia.total }}</td>
              </tr>
              <tr> <!--Renglón 9-->
                <td>de matrimonios con el curso Ser y Hacer Equipo Zonal (SHEZ)</td>
                <td class="text-center">{{ reporte.servicios.zonal.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.encuentros }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.zonal.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->
      </div> <!-- card-body Matrimonios en Servicio -->

      <div id="tabla-container"> <!-- Total Miembros-->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Total Miembros:</th>
              <td class="text-center">{{ parseInt(reporte.datos.equiperos.total) + parseInt(reporte.servicios.matrimonios.total) }}</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Total Miembros-->

      <div class="card-body"> <!--Grados de Estudio-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Grado de Estudios</th>
                <th>Primaria</th>
                <th>Secundaria</th>
                <th>Preparatoria</th>
                <th>Licenciatura</th>
                <th>Postgrado</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Personas en el Ciclo Básico de Formación (CBF)</td>
                <td class="text-center">{{ reporte.estudios.cbf.primaria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.secundaria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.preparatoria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.licenciatura }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.postgrado }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de Personas en diversos servicios</td>
                <td class="text-center">{{ reporte.estudios.servicios.primaria }}</td>
                <td class="text-center">{{ reporte.estudios.servicios.secundaria }}</td>
                <td class="text-center">{{ reporte.estudios.servicios.preparatoria }}</td>
                <td class="text-center">{{ reporte.estudios.servicios.licenciatura }}</td>
                <td class="text-center">{{ reporte.estudios.servicios.postgado }}</td>
                <td class="text-center">{{ reporte.estudios.servicios.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->

      </div> <!-- card-body Grado de Estudios -->
      <div id="contenedor">
        <h6 align="Center">Enviado a Presidentes Diocesanos (PD)</h6>
        <div id="cuadro-firma">
          <div id="linea-firma"></div>
          <div id="label-el">Fecha de envío</div>
          <br/>
          <div id="linea-cargo"></div>
          <div id="label-cargo">Nombre y Rol de quien envía</div>
        </div>
      </div>
    </div> <!-- card Reporte S-03 -->

    <br />
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div> <!--Del botón Regresar--> 
  </div> <!-- Del Template-->
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      Constantes: Constantes,
      reporte: {},
      guardando: false,
      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  //  };
  methods: {


    reporteS03: function (sector) {
      api.reporteS03(sector).then((r) => {
        this.reporte = r.data; 
      });
    },

  },
  computed: {},
  mounted: function () {
    const sector = this.$route.params.id ?? 0;
    this.reporteS03(sector);
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-container {
  width: 25%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 50%;
  border: 1px solid #ccc;
  padding: 20px;
  position: relative;
  align-self: center;
}

#cuadro-firma {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#linea-firma, #linea-cargo {
  width: 80%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-top: 25px;
}

#label-el, #label-cargo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

</style>
