import Inicio from '@/views/Inicio.vue';
import store from '../store'

export default  [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio,
    beforeEnter(to, from, next) {
      if (store.getters.ciclo != null) {
        next()
      } else {
        next('/pausa')
      }
    }
  },
];
