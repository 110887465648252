
<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nombre">Nombre(s)</label>
                <input
                  maxlength="30"
                  v-model.trim="mare.nombre"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.nombre.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.mare.nombre.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <input
                  maxlength="30"
                  v-model.trim="mare.apellidop"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.apellidop.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.mare.apellidop.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <input
                  maxlength="30"
                  v-model.trim="mare.apellidom"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.apellidom.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.mare.apellidom.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="mare.fecha_nac"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.fecha_nac.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion">Ocupación</label>
                <input
                  maxlength="50"
                  v-model.trim="mare.ocupacion"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.ocupacion.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_id">Grado de estudios</label>
                <select v-model="mare.grado_estudio_id" class="form-control">
                  <option
                    v-for="grado in gradoestudios"
                    :value="grado.grado_estudio_id"
                    :key="grado.grado_estudio_id"
                  >
                    {{ grado.nom_grado }}
                  </option>
                </select>
                <div class="errores" v-if="!$v.mare.grado_estudio_id.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>

                <input
                  maxlength="10"
                  type="text"
                  v-model.trim="mare.celular"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.celular.telefono">
                  Ingrese solo números (10 dígitos)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo">Correo electrónico</label>
                <input
                  maxlength="50"
                  type="email"
                  v-model.trim="mare.correo"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.correo.correo">
                  Ingrese una dirección de correo válido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia a la que asiste</label>
                <input
                  maxlength="60"
                  v-model.trim="mare.parroquia"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.mare.parroquia.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="edo_civil">Estado Civil</label>
                <select v-model="mare.edo_civil" class="form-control">
                  <option value="SOLTERA">SOLTERA</option>
                  <option value="DIVORCIADA">DIVORCIADA</option>
                  <option value="VIUDA">VIUDA</option>
                  <option value="SEPARADA">SEPARADA</option>
                </select>
                <div class="errores" v-if="!$v.mare.edo_civil.required">
                  Requerido
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <archivo-imagen
              v-if="mostrarFoto"
              style="width: 180px; height: 180px"
              ref="foto"
              v-model="mare.foto"
            ></archivo-imagen>
          </div>          
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los hijos</h6>
      <div class="card-body">
        <div class="">
          <div class="text-right">
            <button class="btn btn-secondary" v-on:click="agregar_hijo">
              Agregar hijo
            </button>
          </div>
          <br />
          <table class="table table-bordered">
            <tr>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>
              <th></th>
            </tr>
            <tr
              v-for="(hijo, pos) in $v.mare.hijos.$each.$iter"
              :key="hijo.$model.hijo_id"
            >
              <td>
                <input
                  maxlength="40"
                  v-model.trim="hijo.$model.nombre"
                  class="form-control"
                />
                <div class="errores" v-if="!hijo.nombre.required">
                  Requerido
                </div>
                <div class="errores" v-if="!hijo.nombre.letras">
                  Ingrese solo letras
                </div>
              </td>
              <td>
                <input
                  type="date"
                  v-model.trim="hijo.$model.fecha_nac"
                  class="form-control"
                />
                <div class="errores" v-if="!hijo.fecha_nac.required">
                  Requerido
                </div>
              </td>
              <td>
                <button
                  class="btn btn-secondary"
                  title="Quitar"
                  v-on:click="quitar_hijo(pos)"
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </button>
              </td>
            </tr>
            <tr v-if="mare.hijos.length == 0">
              <td colspan="2">No se han agregado hijos</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <input
              maxlength="50"
              v-model.trim="mare.calle"
              class="form-control"
            />
            <div class="errores" v-if="!$v.mare.calle.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <select
              v-model="mare.estado_id"
              v-on:change="actualizaMunicipios"
              class="form-control"
            >
              <option
                v-for="estado in estados"
                :value="estado.estado_id"
                :key="estado.estado_id"
              >
                {{ estado.nom_estado }}
              </option>
            </select>
            <div class="errores" v-if="!$v.mare.estado_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <select
              v-model="mare.municipio_id"
              v-on:change="actualizaLocalidades"
              class="form-control"
            >
              <option
                v-for="municipio in municipios"
                :value="municipio.municipio_id"
                :key="municipio.municipio_id"
              >
                {{ municipio.nom_municipio }}
              </option>
            </select>
            <div class="errores" v-if="!$v.mare.municipio_id.required">
              Requerido
            </div>
          </div>
          <!--REVISAR ESTE FRAGMENTO DE CÓDIGO PORQUE NO ME TENGO CONFIANZA :p -->
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <select
              v-model="mare.localidad_id"
              v-on:change="actualizaCp"
              class="form-control"
            >
              <option
                v-for="localidad in localidades"
                :value="localidad.localidad_id"
                :key="localidad.localidad_id"
              >
                {{ localidad.nom_localidad }} - {{ localidad.tipo_localidad }}
              </option>
            </select>
            <div class="errores" v-if="!$v.mare.localidad_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <input
              maxlength="4"
              type="number"
              v-model="mare.codigo_postal"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <input
              maxlength="10"
              type="text"
              v-model="mare.telefono_domicilio"
              class="form-control"
            />
            <div class="errores" v-if="!$v.mare.telefono_domicilio.telefono">
              Ingrese solo números (número con Lada)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import { required } from "vuelidate/lib/validators";
import { letras, telefono, correo } from "@/libs/validadores";
export default {
  name: "DatosMare",
  components: { ArchivoImagen },  
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      estados: [],
      municipios: [],
      localidades: [],
      mare: { hijos: [] },
      gradoestudios: [],
    };
  },
  validations() {
    return {
      mare: {
        nombre: { required, letras },
        apellidop: { required, letras },
        apellidom: { required, letras },
        fecha_nac: { required },
        ocupacion: { required },
        grado_estudio_id: { required },
        celular: { telefono },
        correo: { correo },
        parroquia: { letras },
        edo_civil: { required },
        calle: { required },
        estado_id: { required },
        municipio_id: { required },
        localidad_id: { required },
        telefono_domicilio: { telefono },
        hijos: {
          $each: {
            nombre: { required, letras },
            fecha_nac: { required },
          },
        },
      },
    };
  },
  methods: {
    valido: function () {
      this.$v.mare.$touch();
      return !this.$v.mare.$invalid;
    },
    agregar_hijo: function () {
      this.mare.hijos.push({
        hijo_id: -this.mare.hijos.length,
        nombre: null,
        fecha_nac: null,
      });
    },
    quitar_hijo: function (pos) {
      if (this.mare.hijos[pos].hijo_id > 0) {
        this.mare.hijos_quitar.push(this.mare.hijos[pos].hijo_id);
      }
      this.mare.hijos.splice(pos, 1);
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.mare.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.mare.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.mare.localidad_id;
      let mare = this.mare;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          mare.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaMare: function () {
      this.mare.estado_id != null && this.actualizaMunicipios();
      this.mare.municipio_id != null && this.actualizaLocalidades();
      this.mare.localidad_id != null && this.actualizaCp();
      this.mare.hijos_quitar = [];
    },
  },
  computed: {},
  mounted: function () {
    this.mare = this.value;
    this.inicializaMare();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
