<template>
  <div ref="graph" class="div" :style="cssVars">
    <span ref="span" class="span">{{ valor }}%</span>
    <canvas ref="canvas" class="canvas"></canvas>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    size: {
      type: Number,
      default: 150,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    line: {
      type: Number,
      default: 15,
    },
  },
  name: "BarraProgreso",
  data() {
    return {
      valor: this.value,
      ctx: null,
      radius: null,
    };
  },
  methods: {
    dibujar(color, lineWidth, percent) {
      percent = Math.min(Math.max(0, percent || 1), 1);
      this.ctx.beginPath();
      this.ctx.arc(0, 0, this.radius, 0, Math.PI * 2 * percent, false);
      this.ctx.strokeStyle = color;
      this.ctx.lineCap = "round"; // butt, round or square
      this.ctx.lineWidth = lineWidth;
      this.ctx.stroke();
    },
  },
  watch: {
    valor() {
      this.$emit("input", this.valor);
    },
    value(valor) {
      this.valor = valor;
      this.dibujar("#efefef", 15, 100 / 100);
      this.dibujar("#555555", 15, this.valor / 100);
    },
  },
  computed: {
    cssVars () {
      return {
        '--divsize': (this.size) + "px",
        '--fontsize': (this.size < 200 ? 20 : 40) + "px",
      }
    }
  },
  mounted() {

    /*if (typeof(G_vmlCanvasManager) !== 'undefined') {
        G_vmlCanvasManager.initElement(canvas);
    }*/

    var canvas = this.$refs.canvas;
    this.ctx = canvas.getContext("2d");
    canvas.width = canvas.height = this.size;

    this.ctx.translate(this.size / 2, this.size / 2); // change center
    this.ctx.rotate((-1 / 2 + this.rotate / 180) * Math.PI); // rotate -90 deg

    this.radius = (this.size - this.line) / 2;
    this.dibujar("#efefef", 15, 100 / 100);
    this.dibujar("#555555", 15, this.valor / 100);
  },
};
</script>

<style scoped>
.div {
  display: inline-block;
  position: relative;
  width: var(--divsize);
  height: var(--divsize);
}
.canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.span {
  color: #555;
  display: block;
  line-height: var(--divsize);
  text-align: center;
  width: var(--divsize);
  font-family: sans-serif;
  font-size: var(--fontsize);
  font-weight: 100;
  margin-left: 5px;
}
</style>