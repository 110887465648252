<template>
  <div>
    <h4>Evaluación de <b>{{ perfil }}</b></h4>

    <div class="card">
      <h6 class="card-header">{{ perfil }}</h6>
      <div class="card-body">
        <div class="form-row">

          <div class="form-group col-md-12" v-if="evaluados.length == 0">
            <label style="color:red;">¡¡¡No hay perfil para evaluar!!!</label>
          </div>
          <div class="form-group col-md-12" v-if="evaluados.length > 1">
            <label>{{ perfil }} a evaluar</label>

            <select v-model="evaluado" class="form-control" @change="cambiarEvaluado">
              <option :value="null">
                Elegir {{ perfil }}
              </option>
              <option :value="p" v-for="p in evaluados" :key="p.membresia_id">
                {{ p.nombre }} y {{ p.nombre_ella }}
              </option>
            </select>
            <div class="errores" v-if="!$v.evaluado.required">
              Requerido
            </div>
          </div>

          <table class="table table-bordered" v-if="evaluado != null">
            <tr>
              <td>Nombre el</td>
              <td>{{ evaluado.nombre }}</td>
            </tr>
            <tr>
              <td>Nombre ella</td>
              <td>{{ evaluado.nombre_ella }}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>
    <br />
    <div class="card" v-show="evaluado != null">
      <h6 class="card-header">Evaluación</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="domicilio">Periodo a evaluar</label>

            <select v-model="tema" class="form-control" v-on:change="cambiarTema">
              <option :value="null">
                Elegir periodo
              </option>
              <option :value="t" v-for="t in temas" :key="t.tema_id" :class="{ 'text-success': t.respuesta_id != null }">
                {{ t.respuesta_id ? '&check;' : '&nbsp;&nbsp;&nbsp;' }} {{ t.nombre }}
              </option>
            </select>
            <div class="errores" v-if="!$v.tema.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-12" v-for="(p, pos) in $v.preguntas2.$each.$iter" :key="pos">
            <div v-if="tema != null">
              <label>{{ p.$model.pregunta }} </label>
              <br />
              <div class="btn-group btn-group-toggle active" data-toggle="buttons">
                <label class="btn"
                  :class="p.$model.respuesta == r.evalua_pregunta_opcion_id ? 'btn-success' : 'btn-outline-success'"
                  v-for="r in p.$model.opciones" :key="r.evalua_pregunta_opcion_id">
                  <input type="radio" :name="'options' + r.evalua_pregunta_opcion_id"
                    :value="r.evalua_pregunta_opcion_id" v-model="p.$model.respuesta" autocomplete="off"> {{ r.opcion }}
                </label>
              </div>
              <div class="errores" v-if="!p.respuesta.required">
                Requerido
              </div>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <button class="btn btn-primary" v-bind:disabled="guardando"
              v-on:click.stop.prevent="guardar">Guardar</button>
            <button class="btn btn-secondary" v-on:click.stop.prevent="cancelar">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <b-modal ref="retro" title="Evaluación en línea">
      <p>Tu evaluación se ha registrado exitósamente, muchas gracias.</p>
      <template #modal-footer="{ hide }">
        <button class="btn btn-success" @click="hide()">Aceptar</button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import api from "@/api";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Evalua",
  components: {},
  data() {
    return {
      roles: {
        ss: 'Secretarío de Sector',
        pd: 'Presidente Diocesano',
      },
      rol: null,
      perfil: null,
      tema: null,
      temas: [
        { id: 'evaluacion1', nombre: '1ra. Evaluación', tipo: 'tema' },
        { id: 'evaluacion2', nombre: '2da. Evaluación', tipo: 'tema' },
      ],
      tipoMembresias: [],
      preguntas: [],
      preguntas2: [],
      registro: {},
      evaluados: [],
      evaluado: null,
      diocesis: [],
      guardando: false,
      tipo_membresia_id: null,
      contestadas: {},

      errores: {}
    };
  },
  validations() {
    return {
      evaluado: { required },
      tema: { required },
      preguntas2: {
        $each: {
          respuesta: {
            required: requiredIf(() => {
              return true
            })
          },
        },
      }
    };
  },
  methods: {
    cargarInformacion: function () {
      api.evaluacionDatosRol(this.rol).then(resp => {
        if (resp.data.respuesta) {

          this.evaluados = resp.data.evaluados;
          if (this.evaluados.length == 1) {
            this.evaluado = this.evaluados[0];
            this.listarEvaluacionesContestadas();
          } else {
            this.evaluado = null;
          }
        }
      });
    },
    guardar: function () {
      this.$v.evaluado.$touch();
      this.$v.tema.$touch();
      this.$v.preguntas2.$touch();
      let valido = !this.$v.evaluado.$invalid && !this.$v.tema.$invalid && !this.$v.preguntas2.$invalid;

      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        const respuestas = this.preguntas2.filter(p => p.respuesta != null).map(p => { return { 'evalua_pregunta_id': p.evalua_pregunta_id, 'evalua_pregunta_opcion_id': p.respuesta } });
        const datos = {
          respuestas: respuestas,
          tema: this.tema.id,
          evaluado_id: this.evaluado.membresia_id,
          evalua_respuesta_id: this.contestadas[this.tema.id]?.id,
        };

        api.evaluacionGuardar(datos).then((response) => {
          let datos = response.data;
          if (datos != null && datos.respuesta) {
            this.$refs.retro.show();
            this.nuevo();
          } else {
            this.$parent.error("Error al guardar la información: " + (datos != null ? datos.mensaje : 'error interno'));
          }
          this.guardando = false;
        }, function () {
          this.$parent.error("Error al guardar la información");
          this.guardando = false;
        });
      } else {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    cancelar: function () {
      if (confirm('¿Desea cancelar su evaluación?')) {
        this.nuevo();
      }
    },
    cambiarTema: function () {
      const contestada = this.contestadas[this.tema.id]?.resp || {};
      this.preguntas2.forEach(p => {
        p.respuesta = contestada[p.evalua_pregunta_id];
      });
      console.log(this.preguntas2);
    },
    nuevo: function () {
      this.evaluado = null;
      this.tema = null;
      this.temas.forEach(t => t.evalua_respuesta_id = null);
      this.cargarInformacion();
    },
    listarPreguntas: function () {
      api.evaluacionListarPreguntas(this.rol).then((response) => {
        response.data.forEach(p => p.respuesta = null)
        this.preguntas2 = response.data;
      }, () => {
        this.$parent.error('Error 100: No se pueden obtener las preguntas');
      });
    },
    listarEvaluacionesContestadas: function () {
      this.contestadas = {}
      this.temas.forEach(t => t.respuesta_id = null);
      if (this.evaluado == null) return;

      const datos = {
        evaluado_id: this.evaluado.membresia_id,
      }
      api.evaluacionContestadas(datos).then((response) => {
        this.contestadas = response.data;
        this.temas.forEach(t => {
          if (t.id in this.contestadas) {
            t.respuesta_id = this.contestadas[t.id].id;
          }
        });
      });
    },
  },
  mounted() {
    this.rol = this.$route.params.rol;
    this.perfil = this.roles[this.rol];
    this.listarPreguntas();
    this.cargarInformacion();
    this.$watch(
      () => this.$route.params,
      () => {
        this.rol = this.$route.params.rol;
        this.perfil = this.roles[this.rol];
        this.listarPreguntas();
        this.cargarInformacion();
      }
    );
  },
};
</script>
