<template>
  <div>
    <encabezado />
    <div class="row">
    <img src="../assets/img/foto0.jpg" class="img-fluid w-100 "  alt="Fluid-grow image" v-if="false" />
    </div>
    <div class="d-flex justify-content-center align-items-center">

      <div>
        
        <b-carousel
          id="carousel-1"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          style="max-width: 1024px; text-shadow: 1px 1px 2px #333"
        >
          <!-- Text slides with image -->
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/1.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/2.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/3.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/4.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/5.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid"
                src="../assets/img/6.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Encabezado from "@/components/Encabezado.vue";
export default {
  name: "Inicio",
  components: { Encabezado },
  props: {
    msg: String,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
