<template>
  <div>
    <h4>
      <font-awesome-icon icon="calendar" /> {{ titulo }}
    </h4>
    <div class="card">
      <h6 class="card-header">Datos del Evento</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="eventono_id">ID del Evento</label>
            <input
              readonly="readonly"
              maxlength="40"
              v-model.trim="eventono.eventono_id"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <select
              v-model="eventono.diocesis_id"
              class="form-control"
              v-on:change="actualizaSectores"
            >
              <option
                v-for="dio in diocesis"
                :value="dio.diocesis_id"
                :key="dio.diocesis_id"
              >
                {{ dio.nom_diocesis }}
              </option>
            </select>
            <div class="errores" v-if="!$v.eventono.diocesis_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <select v-model="eventono.sector_id" class="form-control">
              <option
                v-for="sector in sectores"
                :value="sector.sector_id"
                :key="sector.sector_id"
              >
                {{ sector.nom_sector }}
              </option>
            </select>
            <!--Creo que esta validación que sigue está de más, porque cuando entra a esta ventana
            ya hay un Sector seleccionado, y además no te permite dejar en blanco este "Select"-->
            <div class="errores" v-if="!$v.eventono.sector_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Título">
            <input
              type="text"
              id="titulo"
              v-model.trim="eventono.titulo"
              class="form-control"
            />
              <div class="errores" v-if="!$v.eventono.titulo.required">
                Requerido
              </div>
            </b-form-group>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Descripción</label>
            <input
              maxlength="250"
              v-model.trim="eventono.descripcion"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="lugar_evento">Lugar</label>
            <input
              maxlength="255"
              v-model.trim="eventono.lugar"
              class="form-control"
            />
            <div class="errores" v-if="!$v.eventono.lugar.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_ini">Fecha de Inicio:</label>
            <input
              type="date"
              id="fecha_ini"
              v-model.trim="eventono.fecha_ini"
              class="form-control"
            />
            <div class="errores" v-if="!$v.eventono.fecha_ini.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora de Inicio:</label>
            <input
              type="time"
              id="hora_ini"
              v-model.trim="eventono.hora_ini"
              class="form-control"
            />
            <div class="errores" v-if="!$v.eventono.hora_ini.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_fin">Fecha de término:</label>
            <input
              type="date"
              id="fecha_fin"
              v-model.trim="eventono.fecha_fin"
              class="form-control"
            />
            <div class="errores" v-if="!$v.eventono.fecha_fin.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <input
              type="time"
              id="hora_fin"
              v-model.trim="eventono.hora_fin"
              class="form-control"
            />
            <div class="errores" v-if="!$v.eventono.hora_fin.required">
              Requerido
            </div>
          </div>

        </div>
        <!-- form-row Datos del evento -->
      </div>
      <!-- card-body Datos del evento -->
    </div>
    <!-- card Datos del evento -->
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-primary"
        :disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Guardar
      </button>
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { letras } from "@/libs/validadores";
import { exportarExcel } from "@/utils"

function debounce(fn, delay){
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
   }
}

export default {
  name: "eventosEditar",
  components: {  },
  data() {
    return {
      diocesis: [],
      sectores: [],
      guardando: false,
      roles: [],
      tipos_membresia: [],

      Constantes: Constantes,
      titulo: "",
      eventono: {
        eventono_id: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        lugar: null,
        descripcion: null,
      },

      permisos: {
        agregar: ["", "eventono-agregar"],
        editar: ["", "eventono-editar"],
        estatus: ["", "eventono-estatus"],
      },
      columnas: [
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nom_tipo_membresia",
          key: "d",
          title: "Tipo miembro",
          align: "left",
        },
        {
          field: "titulo",
          key: "e",
          title: "Titulo",
          align: "left",
        },
        {
          field: "fecha",
          key: "e",
          title: "Fecha",
          align: "left",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => this.eliminarParticipante(d.row, d.rowIndex)}
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      
      eventono: {
        titulo: { required, letras },
        diocesis_id: { required },
        sector_id: { required },
        fecha_ini: { required },
        hora_ini: { required },
        fecha_fin: { required },
        hora_fin: { required },
        rol_id: { required: false }, // En el sistema anterior este campo era "capacitador"
        lugar: { required },
      },
    };
  },
  //  };
  methods: {
    exportarExcel() {
      exportarExcel(this.eventono.asistentes);
    },


    guardar() {
      this.$v.eventono.$touch();
      let valido = !this.$v.eventono.$invalid;
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarEventoNo(this.eventono)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.eventono.eventono_id = datos.id;
              this.$parent.mensaje("Información guardada correctamente");
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    listaTiposMembresia: function () {
      api.listarTipoMembresias().then((r) => {
        this.tipos_membresia = r.data;
      });
    },

    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },


    actualizaSectores: function () {
      var id = this.eventono.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    obtenerEventoNo: function (id) {
      api.obtenerEventoNo(id).then((r) => {
        this.eventono = r.data.eventono;
        this.actualizaSectores();
        this.titulo =
          "Edición de evento no oficial";
      });
    },
  },
  computed: {},
  mounted: function () {
    const tipo = parseInt(this.$route.params.tipo) ?? 0;
    if (this.$route.params.id) {
      this.obtenerEventoNo(this.$route.params.id);
    } else {
      this.tipo = Number.parseInt(this.$route.params.tipo);
      this.titulo = "Agregar evento de " + Constantes.TIPOS_EVENTOS[tipo];
    }
    this.listaDiocesis();
    this.listaTiposMembresia();
  },
  created() {
    this.cambioNombreDebounced = debounce(() => {
      this.cambioNombre();
    }, 2500);
  },
};
</script>
<style scoped>

</style>
