<template>
  <div>
    <h4><font-awesome-icon icon="list" /> Consultar acta de región</h4>
    <div class="card">
      <h6 class="card-header">Datos del Acta</h6>
      <div class="card-body">
        <div class="form-row">
          <b-col md="3">
            <label for="diocesis_id">Región</label>
            <div class="form-info">
              {{ regiones.filter(s => s.region_id == acta.region_id)[0]?.nom_region }}
            </div>   
          </b-col>

          <div class="form-group col-md-3">
            <label for="diocesis_id">Mes reportado</label>
            <div class="form-info">
              {{ meses.filter(m => m.mes == acta.mes)[0]?.nombre }}
            </div>   
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Ciclo</label>
            <div class="form-info">
              {{ ciclos.filter(c => c.ciclo_id == acta.ciclo_id)[0]?.nom_ciclo }}
            </div>   
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_registro">Fecha de resumen:</label>
            <div class="form-info">
              {{ acta.fecha_registro }}
            </div>   
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_registro">Fecha de envío a a4n:</label>
            <div class="form-info">
              {{ acta.fecha_envio_a4n }}
            </div>   
          </div>
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Diócesis</th>
                <th>Cantidad</th>
                <th>Tipo</th>
              </tr>
            </thead>
            <tr
              v-for="act in acta.actas"
              :key="act.diocesis_id"
            >
              <td>
                {{ act.nom_diocesis }}
              </td>
              <td>
                {{ act.cantidad }}
              </td>
              <td>
                {{  act.tipo }}
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Datos del acta -->
      </div>
      <!-- card-body Datos del acta -->
    </div>
    <!-- card Datos del acta -->
    <br />
    <br />
    <div class="card">
      <h6 class="card-header">Observaciones</h6>
      <div class="form-group col-md-12">
        <br/>
        <div class="form-info">
          {{ acta.resumen }}
        </div>
      </div>
    </div>
    <br />
    <br />

    <div class="card">
      <h6 class="card-header">Problemática a informar</h6>
      <div class="card-body">
        <div class="form-row">
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th>Diócesis</th>
                <th>Problemática</th>
                <th>Acción a seguir</th>
              </tr>
            </thead>
            <tr
              v-for="acuerdo in acta.acuerdos"
              :key="acuerdo.diocesis_id"
            >
              <td>
                {{ acuerdo.nom_diocesis }}
              </td>
              <td>
                {{ acuerdo.problematica }}
              </td>
              <td>
                {{ acuerdo.accion }}
              </td>
            </tr>
          </table>
        </div>
        <!-- form-row Acuerdos -->
      </div>
      <!-- card-body Acuerdos -->
    </div>
    <!-- card Acuerdos -->
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";

export default {
  name: "ActasRegionConsultar",
  components: {},
  data() {
    return {
      guardando: false,
      regiones: [],
      ciclos: [],
      meses: [
        { mes: 1, nombre: "Enero" },
        { mes: 2, nombre: "Febrero" },
        { mes: 3, nombre: "Marzo" },
        { mes: 4, nombre: "Abril" },
        { mes: 5, nombre: "Mayo" },
        { mes: 6, nombre: "Junio" },
        { mes: 7, nombre: "Julio" },
        { mes: 8, nombre: "Agosto" },
        { mes: 9, nombre: "Septiembre" },
        { mes: 10, nombre: "Octubre" },
        { mes: 11, nombre: "Noviembre" },
        { mes: 12, nombre: "Diciembre" },
      ],

      Constantes: Constantes,
      acta: {
        actar_id: null,
        region_id: null,
        mes: null,
        ciclo_id: null,
        fecha_registro: null,
        resumen: null,
        actas: [],
        acuerdos: [],
      },

      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },

  //  };
  methods: {
    guardar() {
      this.$v.acta.$touch();
      let valido = !this.$v.acta.$invalid;
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarActaRegion(this.acta)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.acta.actar_id = datos.id;
              this.$parent.mensaje("Información guardada correctamente");
              this.$router.push('/actas_region/listar')
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    listarCiclos() {
      api.listarCiclos().then((r) => {
        if (Array.isArray(r.data)) {
          this.ciclos = r.data;
          if (this.acta.ciclo_id == null) {
            this.acta.ciclo_id = this.ciclos.find(
              (c) => c.ciclo_vigente
            ).ciclo_id;
          }
        } else {
          this.ciclos = [];
        }
      });
    },

    cambiarRegion() {
      this.acta.acuerdos = [];
      this.acta.actas = [];
      api.listarDiocesis(this.acta.region_id).then((r) => {
        let acuerdos = [];
        let actas = [];
        if (Array.isArray(r.data)) {
          r.data.forEach((d) => {
            acuerdos.push({
              diocesis_id: d.diocesis_id,
              nom_diocesis: d.nom_diocesis,
            });
            actas.push({
              diocesis_id: d.diocesis_id,
              nom_diocesis: d.nom_diocesis,
            });
          });
        }
        this.acta.acuerdos = acuerdos;
        this.acta.actas = actas;
      });
    },

    listarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.regiones = r.data;
        } else {
          this.regiones = [];
        }
      });
    },

    obtenerActa(id) {
      api.obtenerActaRegion(id).then((r) => {
        this.acta = r.data.acta;
      });
    },

  },
  computed: {},
  mounted: function () {
    if (this.$route.params.id) {
      this.obtenerActa(this.$route.params.id);
    }
    this.listarRegiones();
    this.listarCiclos();
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}
</style>
