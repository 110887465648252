import Eventos from '@/views/Eventos.vue';
import EventosConsultar from '@/views/EventosConsultar.vue';
import EventosEditar from '@/views/EventosEditar.vue';
import EventosNo from '@/views/EventosNo.vue';
import EventosNoEditar from '@/views/EventosNoEditar.vue';
import EventosQr from '@/views/EventosQr.vue';
import store from '@/store';

export default [
  {
    path: '/eventosno/listar',
    name: 'EventosNo',
    component: EventosNo,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/eventosno/agregar',
    name: 'EventosNoAgregar',
    component: EventosNoEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/eventosno/editar/:id',
    name: 'EventosNoEditar',
    component: EventosNoEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/eventos/listar/:tipo',
    name: 'Eventos',
    component: Eventos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/eventos/agregar/:tipo',
    name: 'EventosAgregar',
    component: EventosEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },   
  {
    path: '/eventos/consultar/:id',
    name: 'EventosConsultar',
    component: EventosConsultar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },   {
    path: '/eventos/editar/:id',
    name: 'EventosEditar',
    component: EventosEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/eventos/qr/:id',
    name: 'EventosQr',
    component: EventosQr,
  }, 
];