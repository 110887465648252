<template>
  <div>
    <div class="text-center" v-observe-visibility="visibilityChanged">
      <video
        class="source"
        ref="video"
        :width="width"
        :height="height"
        style="width: 100%; height: auto"
      ></video>
      <canvas hidden="" ref="canvas" id="qr-canvas"></canvas>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility)
export default {
  props: ["width", "height"],
  name: "Foto",
  data: function () {
    return {
      count: 0,
    };
  },
  methods: {
    tick: function () {
      this.canvasElement.height = this.video.height;
      this.canvasElement.width = this.video.width;
      this.canvas.drawImage(
        this.video,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );

      this.scanning && requestAnimationFrame(this.tick);
    },
    visibilityChanged: function (isVisible) {
      if (!isVisible) {
        this.detener();
      } else {
        this.iniciar();
      }
    },
    detener: function () {
      this.scanning = false;
      if (this.$refs.video && this.$refs.video.srcObject) {
        this.$refs.video.srcObject.getTracks().forEach((t) => t.stop());
      }
    },
    foto: function () {
      var imagen = this.canvasElement.toDataURL("image/jpeg");
      this.$emit("foto-tomada", imagen);
      this.detener();
    },
    iniciar: function () {
      this.scanning = true;
      let self = this;
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(function (stream) {
          self.canvasElement.hidden = true;
          self.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          self.video.srcObject = stream;
          self.video.play();
        });
      this.tick();
    },
  },
  mounted() {
    this.video = this.$refs.video;
    this.canvasElement = this.$refs.canvas;
    this.canvas = this.canvasElement.getContext("2d");

    this.salida = this.$refs.salida;
  },
};
</script>

<style scoped>
.source {
  text-align: center;
}
</style>