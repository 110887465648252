<template>
  <div>
    <encabezado />
    <div class="row">
      <div class="col-12 text-center">
        <h5 v-if="ciclo_id > 0">
          Registro de <b>Miembros</b> en Línea <br />Ciclo
          {{ nom_ciclo }}
        </h5>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-12 text-center"></div>
    </div>
    <br />
    <div class="card" v-show="fase != 3">
      <h6 class="card-header">Tipo de inscripción</h6>
      <div class="card-body">
        <div class="text-center botones-accion" v-if="ciclo_id > 0">
          <button
            v-on:click="seleccionNuevoIngreso"
            class="btn btn-primary"
            v-if="fase == 1"
          >
            Nuevo ingreso
          </button>
          <button
            v-on:click="reingreso"
            class="btn btn-primary"
            v-if="fase == 1"
          >
            Reingreso
          </button>
          <button
            v-on:click="fase = 1"
            v-if="fase == 2"
            class="btn btn-primary"
          >
            &nbsp;<font-awesome-icon
              icon="arrow-circle-left"
            ></font-awesome-icon>
            <i class="fas fa-arrow-circle-left"></i>&nbsp;
          </button>
          <button
            v-on:click="usuariopass"
            class="btn btn-primary"
            v-if="fase == 2"
          >
            Registrarme por usuario/contraseña
          </button>
          <button v-on:click="leerIdNacional" class="btn btn-primary" v-if="fase == 2">
            Registrarme por ID Nacional
          </button>
          <button v-on:click="leerId" class="btn btn-primary" v-if="fase == 2">
            Registrarme por ID Diocesano
          </button>
          <button v-on:click="leerQr" class="btn btn-primary" v-if="fase == 2">
            Registrarme por Credencial
          </button>
        </div>
        <div class="text-center" v-if="ciclo_id == 0">
          <h4>Registro cerrado</h4>
        </div>
      </div>
    </div>
    <br />
    <div class="card" v-if="fase == 3">
      <h6 class="card-header">Datos de miembro</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <select
              :disabled="reinscripcion"
              v-model="membresia.diocesis_id"
              class="form-control"
              v-on:change="actualizaSectores"
            >
              <option :value="null">Seleccione</option>
              <option
                v-for="dio in diocesis"
                :value="dio.diocesis_id"
                :key="dio.diocesis_id"
              >
                {{ dio.nom_diocesis }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.diocesis_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <select
              :disabled="reinscripcion"
              v-model="membresia.sector_id"
              class="form-control"
            >
              <option :value="null">Seleccione</option>
              <option
                v-for="sector in sectores"
                :value="sector.sector_id"
                :key="sector.sector_id"
              >
                {{ sector.nom_sector }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.sector_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3" v-show="reinscripcion">
            <label for="membresia_id">ID</label>
            <input
              readonly="readonly"
              maxlength="40"
              v-model.trim="membresia.membresia_id"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="rol">Rol Principal</label>
            <input
              readonly="readonly"
              maxlength="20"
              v-model.trim="membresia.rol.nom_rol"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="anio_ingreso">Año de Ingreso al MFC</label>
            <input
              v-model.trim="membresia.anio_ingreso"
              class="form-control"
              v-bind:readonly="true"
            />
<!--            <div class="errores" v-if="!$v.membresia.anio_ingreso.required">
              Requerido
            </div> -->
          </div>
          <div class="form-group col-md-3">
            <label for="inscripcion">Cuota de inscripción</label>
            <input
              maxlength="10"
              type="text"
              v-model.trim="membresia.inscripcion"
              class="form-control"
            />
            <div class="errores" v-if="!$v.membresia.inscripcion.required">
              Requerido
            </div>
            <div class="errores" v-if="!$v.membresia.inscripcion.cantidad">
              Incorrecto
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="ofrenda">Ofrenda mensual</label>
            <input
              maxlength="10"
              type="text"
              v-model.trim="membresia.ofrenda"
              class="form-control"
            />
            <div class="errores" v-if="!$v.membresia.ofrenda.required">
              Requerido
            </div>
            <div class="errores" v-if="!$v.membresia.ofrenda.cantidad">
              Incorrecto
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <datos-matrimonio
      v-model="membresia.matrimonio"
      :mostrar-foto="true"
      ref="matrimonio"
      v-if="fase == 3 && membresia.tipo_membresia_id == Constantes.TIPO_MATRIMONIO" 
    />
    <datos-mare
      v-model="membresia.mare"
      :mostrar-foto="true"
      ref="mare"
      v-if="fase == 3 && membresia.tipo_membresia_id == Constantes.TIPO_MARE" 
    />
    <datos-joven
      v-model="membresia.joven"
      :mostrar-foto="true"
      ref="joven"
      v-if="fase == 3 && (membresia.tipo_membresia_id == Constantes.TIPO_JOVEN || membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE)" 
    />
    <datos-ae
      v-model="membresia.ae"
      :mostrar-foto="true"
      ref="ae"
      v-if="fase == 3 && membresia.tipo_membresia_id == Constantes.TIPO_AE" 
    />        
    <br />

    <div class="card" v-show="fase == 3">
      <h6 class="card-header">Confirmación de datos</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-4">
            <div class="avisos form-check">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="membresia.aviso_privacidad"
              />
              Acepto el
              <a href="javascript:;" @click="$parent.avisoPrivacidad()"
                >aviso de privacidad</a
              >
            </div>
            <div class="errores" v-if="!$v.membresia.aviso_privacidad.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-4">
            <div class="avisos form-check">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="membresia.uso_imagen"
              />
              Acepto el
              <a href="javascript:;" @click="$parent.avisoUsoImagen()"
                >aviso de uso de imagen</a
              >
            </div>
            <div class="errores" v-if="!$v.membresia.uso_imagen.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-4"> 
            <vue-recaptcha
              ref="grecaptcha"
              @verify="capchaVerificado"
              @expire="capchaExpirado"
              sitekey="6LcovK8ZAAAAACYlOLWD_hn-kp5hHQypJ6_IiItr"
            />
            <div class="errores" v-if="capcha == null">Requerido</div>
          </div>
          <div class="col-md-12 text-center botones-accion">
            <button
              class="btn btn-primary"
              :disabled="guardando"
              v-on:click.stop.prevent="guardar"
            >
              Guardar
            </button>
            <button
              class="btn btn-secondary"
              v-on:click.stop.prevent="cancelar"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="retro" title="Registro en línea">
      <p>
        Tu registro se ha llevado a cabo exitósamente, en breve te llegará un
        correo de confirmación.
      </p>
      <p v-if="idnuevo != null">
        Tu nuevo ID de usuario es: <b>{{ idnuevo }}</b>
      </p>

      <template #modal-footer="{ hide }">
        <button class="btn btn-primary" @click="hide()">Aceptar</button>
      </template>
    </b-modal>

    <b-modal ref="tipomembresia" title="Selección de tipo de miembro">
      <div class="form-group row">
        <label for="diocesis_id" class="col-sm-4 col-form-label"
          >Tipo de miembro</label
        >
        <div class="col-sm-8">
          <select v-model="login0.tipo_membresia_id" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="tm in tipoMembresias"
              :value="tm.tipo_membresia_id"
              :key="tm.tipo_membresia_id"
            >
              {{ tm.nom_tipo_membresia }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login0.tipo_membresia_id.required">
            Requerido
          </div>
        </div>
      </div>

      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" v-on:click.stop.prevent="nuevoIngreso">
          Aceptar
        </b-button>
        <b-button class="btn" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="usuariopass" title="Reinscripción por usuario/contraseña">
      <div class="form-group row">
        <label for="usuario" class="col-sm-4 col-form-label"
          >Usuario/correo</label
        >
        <div class="col-sm-8">
          <input type="text" v-model="login1.usuario" class="form-control" />
          <div class="errores" v-if="!$v.login1.usuario.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="contrasena" class="col-sm-4 col-form-label"
          >Contraseña</label
        >
        <div class="col-sm-8">
          <input
            type="password"
            v-model="login1.contrasena"
            class="form-control"
          />

          <div class="errores" v-if="!$v.login1.contrasena.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" @click.stop.prevent="iniciarSesionLogin">
          Aceptar
        </b-button>
        <b-button @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="idNacional" title="Reinscripción por ID Nacional">
      <div class="form-group row">
        <label for="membresia_id" class="col-sm-4 col-form-label">ID Nacional</label>
        <div class="col-sm-8">
          <input
            type="number"
            v-model="login4.membresia_id"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login4.membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" v-on:click.stop.prevent="idNacionalTecleado">
          Aceptar
        </b-button>
        <b-button class="btn" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="id" title="Reinscripción por ID Diocesano">
      <div class="form-group row">
        <label for="diocesis_id" class="col-sm-4 col-form-label"
          >Diócesis</label
        >
        <div class="col-sm-8">
          <select v-model="login2.diocesis_id" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="dio in diocesis"
              :value="dio.diocesis_id"
              :key="dio.diocesis_id"
            >
              {{ dio.nom_diocesis }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login2.diocesis_id.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="diocesis_id" class="col-sm-4 col-form-label"
          >Tipo de miembro</label
        >
        <div class="col-sm-8">
          <select v-model="login2.tipo_membresia_id" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="tm in tipoMembresias"
              :value="tm.tipo_membresia_id"
              :key="tm.tipo_membresia_id"
            >
              {{ tm.nom_tipo_membresia }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login2.tipo_membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="membresia_id" class="col-sm-4 col-form-label">ID Diocesano</label>
        <div class="col-sm-8">
          <input
            type="number"
            v-model="login2.membresia_id"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login2.membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" v-on:click.stop.prevent="idTecleado">
          Aceptar
        </b-button>
        <b-button class="btn" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="qrmodal" title="Lectura de código QR">
      <p>Aproxime su credencial a la cámara para poder leer el código QR</p>
      <qrcode-stream @decode="codigoEscaneado"></qrcode-stream>

      <template #modal-footer="{ hide }">
        <b-button @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>

    <b-modal ref="pregunta" title="Confirmación de datos">
      <p>Por favor elige una pregunta para confirmar tus datos</p>
      <div class="form-group row">
        <div class="col-sm-6">
          <select v-model="login3.pregunta" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="pregunta in preguntas"
              :value="pregunta.campo"
              :key="pregunta.campo"
            >
              {{ pregunta.nombre }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login3.pregunta.required">
            Requerido
          </div>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            v-model="login3.respuesta"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login3.respuesta.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button
          class="btn-primary"
          :enabled="!guardando"
          @click.stop.prevent="iniciarSesionDatos"
        >
          Aceptar
        </b-button>
        <b-button @click="hide()">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
//const REGISTRANDO = 1, CONFIRMANDO = 2;

import { VueRecaptcha } from "vue-recaptcha";
import Encabezado from "@/components/Encabezado.vue";
import DatosMatrimonio from "@/components/DatosMatrimonio.vue";
import DatosJoven from "@/components/DatosJoven.vue";
import DatosMare from "@/components/DatosMare.vue";
import DatosAe from "@/components/DatosAe.vue"
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { cantidad } from "@/libs/validadores";
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: "Registro",
  components: { VueRecaptcha, Encabezado, QrcodeStream, DatosMatrimonio, DatosJoven, DatosMare, DatosAe },
  data() {
    return {
      Constantes: Constantes,
      diocesis: [],
      membresia: {},
      tipoMembresias: [],
      equiperos: [],
      login: {},
      login0: { tipo_membresia_id: null },
      login1: {},
      login2: { diocesis_id: null, tipo_membresia_id: null},
      login3: { pregunta: null, respuesta: null},
      login4: { membresia_id: null},
      estado: 1,
      pregunta: "",
      preguntas: {},
      guardando: false,
      reinscripcion: false,
      fase: 1,
      sectores: [],
      idnuevo: null,
      capcha: null,
      ciclo: null,
      ciclo_id: 0,
      nom_ciclo: '',
    };
  },
  validations() {
    return {
      
      membresia: {
        anio_ingreso: { },
        diocesis_id: { required },
        sector_id: { required },
        inscripcion: { required, cantidad },
        ofrenda: { required, cantidad },

        aviso_privacidad: { required },
        uso_imagen: { required },
      },
      login0: {
        tipo_membresia_id: { required },
      },
      login1: {
        usuario: { required },
        contrasena: { required },
      },
      login2: {
        diocesis_id: { required },
        membresia_id: { required },
        tipo_membresia_id: { required },
      },
      login3: {
        pregunta: { required },
        respuesta: { required },
      },
      login: {
        usuario: { required },
        contrasena: { required },
      },
      login4: {
        membresia_id: { required }
      },
    };
  },
  methods: {
    seleccionNuevoIngreso: function () {
      this.$refs.tipomembresia.show();
    },
    nuevoIngreso: function () {
      this.$v.login0.$touch();
      if (!this.$v.login0.$invalid) {
        
        this.$refs.tipomembresia.hide();

        const tipo_membresia_id = this.login0.tipo_membresia_id;
        const equipero = this.equiperos.filter(e => e.tipo_membresia_id == tipo_membresia_id)[0];
        api.registroNuevoDatos().then((r) => {
          if (r.data.respuesta) {
            this.membresia.rol = equipero;
            this.membresia.rol_id = equipero.rol_id;

            this.membresia.tipo_membresia_id = tipo_membresia_id;
            this.tokenr = r.data.tokenr;
            this.fase = 3;
          }
        });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    reingreso() {
      this.fase = 2;
    },
    guardar: function () {
      this.$v.membresia.$touch();
      let valido = !this.$v.membresia.$invalid;
      if (this.membresia.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) {
        valido = valido && this.$refs.matrimonio.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_JOVEN || this.membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE) {
        valido = valido && this.$refs.joven.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_MARE) {
        valido = valido && this.$refs.mare.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_AE) {
        valido = valido && this.$refs.ae.valido();
      }
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;
        this.idnuevo = null;

        let registro = {
          opcion: "guardar",
          membresia: this.membresia,
          capcha: this.capcha,
          tokenr: this.tokenr,
        };
        api
          .guardarRegistro(registro)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              if (!this.reinscripcion) {
                this.idnuevo = datos.id;
              }
              this.$refs.retro.show();
              this.nuevo();
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
            this.$refs.grecaptcha.reset(); 
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    cancelar: function () {
      this.$parent.confirmar("¿Desea cancelar su registro?").then(() => {
        //this.fase ;
        this.nuevo();
      });
    },
    nuevo: function () {
      this.login1 = {};
      this.fase = 1;

      this.membresia = {
        membresia_id: 0,
        rol: {},
        anio_ingreso: new Date().getFullYear(),
        matrimonio: { hijos: [] },
        mare: { hijos: [] },
        joven: {},
        ae: {},
      };
    
      this.membresia.anio_ingreso = new Date().getFullYear();
      this.membresia.matrimonio = { hijos: [] };

      this.reinscripcion = false;
      this.capcha = null;
      this.$refs.grecaptcha.reset();
    },
    usuariopass: function () {
      this.login1 = {};
      this.$refs.usuariopass.show();
    },
    leerIdNacional: function () {
      this.login3 = {pregunta: null, respuesta: null};
      this.$refs.idNacional.show();
    },
    leerId: function () {
      this.login3 = {pregunta: null, respuesta: null};
      this.$refs.id.show();
    },
    leerQr: function () {
      this.login = {};
      this.$refs.qrmodal.show();
      //this.$refs.qr.escanear();
    },
    codigoEscaneado: function (codigo) {
      if (codigo.match(/^[A-Z]{3}-[JM]A?-\d+?$/)) {
        this.$refs.qrmodal.hide();
        api.registroConsultarQR(codigo).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { pregunta: null, respuesta: null,  'qr': codigo };
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      } else {
        this.$parent.error("Codigo incorrecto '" + codigo + "'");
      }
    },
    capchaVerificado(valor) {
      this.capcha = valor;
    },
    capchaExpirado() {
      this.capcha = null;
    },
    idTecleado: function () {
      this.$v.login2.$touch();
      if (!this.$v.login2.$invalid) {
        api.registroConsultarFechas(this.login2).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { ...this.login2 };
            //this.login3.pregunta = null,
            //this.login3.respuesta = null,
            this.$refs.id.hide();
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },
    idNacionalTecleado: function () {
      this.$v.login4.$touch();
      if (!this.$v.login4.$invalid) {
        api.registroNacionalConsultarFechas(this.login4).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { ...this.login4 };
            //this.login3.pregunta = null,
            //this.login3.respuesta = null,
            this.$refs.idNacional.hide();
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },    

    iniciarSesionLogin: function () {
      this.$v.login1.$touch();
      if (!this.$v.login1.$invalid) {
        api.registroLogin(this.login1).then((r) => {
          let resp = r.data;

          if (resp.respuesta) {
            this.tokenr = resp.tokenr;
            this.cargaMembresia(resp);
            this.$refs.usuariopass.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },
    iniciarSesionDatos: function () {
      this.$v.login3.$touch();
      if (!this.$v.login3.$invalid) {
        api.registroLoginFechas(this.login3).then((r) => {
          let resp = r.data;
          if (resp.respuesta) {
            this.tokenr = resp.tokenr;
            this.cargaMembresia(resp);
            this.$refs.pregunta.hide();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },
    listaDiocesis: function () {
      api.listarRegistroDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },
    listaRolesEquiperos: function () {
      api.listarRegistroRolesEquiperos().then((r) => {
        this.equiperos = r.data;
      });
    },

    listaTipoMembresias: function () {
      api.listarRegistroTiposMembresia().then((r) => {
        this.tipoMembresias = r.data;
      });
    },
    actualizaSectores: function () {
      var id = this.membresia.diocesis_id;
      this.sectores = [];
      api.listarRegistroSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    verificaLogin: function () {
      /*
      TODO
      this.$http.get("servicios/reg_matrimonio.php").then(function (response) {
        let resp = response.body;
        if (resp.respuesta) {
          this.cargaMembresia(resp);
        }
      });*/
    },
    cargaMembresia: function (resp) {
      this.reinscripcion = true;
      this.fase = 3;
      this.membresia = resp.membresia;
      this.tipo_membresia_id = resp.membresia.tipo_membresia_id;
      this.actualizaSectores();
    },
    validaActivo: function () {
      api.obtenerCicloVigente().then((r) => {
        const c = r.data;
        this.ciclo_id = c.ciclo_id;
        this.nom_ciclo = c.nom_ciclo;
      });
    },
  },
  computed: {},
  mounted: function () {
    this.validaActivo();
    this.listaDiocesis();
    this.listaRolesEquiperos();
    this.listaTipoMembresias();
    this.verificaLogin();
    this.nuevo();
  },
};
</script>
<style scoped>
.avisos {
  border: 1px solid lightgray;
  background-color: #f9f9f9;
  padding: 25px;
}
</style>
