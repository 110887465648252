<template>
  <div>
    <h4>Administración de ciclos</h4>
    <b-card header="Filtros">
      <form @submit.prevent="actualizarCiclos">
        <b-form-row>
            <b-col md="3">
            <b-form-group label="Vigente">
              <b-form-select
                v-model="busqueda.ciclo_vigente"
                :options="listadoSiNo"
                value-field="valor"
                text-field="texto"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>

          </b-col>
        </b-form-row>
        <b-button @click="limpiarBusqueda"
          ><font-awesome-icon icon="eraser" /> Limpiar</b-button
        >&nbsp;
        <b-button type="submit"
          ><font-awesome-icon icon="search" /> Buscar</b-button
        >
      </form>
    </b-card>
    <div class="acciones">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <ve-table
      rowKeyFieldName="ciclo_id"
      :columns="columnas"
      :table-data="listadoCiclos"
      :border-y="true"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
    <b-modal ref="detalle" scrollable v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del ciclo</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-12">
          <label>Nombre</label>
          <input
            maxlength="30"
            style="max-width: 500px"
            v-model.trim="ciclo.nom_ciclo"
            class="form-control"
            :class="estatus($v.ciclo.nom_ciclo)"
          />
          <div class="errores" v-if="!$v.ciclo.nom_ciclo.required">Requerido</div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="es_presector"
              v-model.trim="ciclo.ciclo_vigente"
              class="form-check-input"
              :class="estatus($v.ciclo.ciclo_vigente)"
            />
            <label class="form-check-label" for="ciclo_vigente">Vigente</label>
          </div>
          <div class="errores" v-if="!$v.ciclo.ciclo_vigente.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="permiso_agregar"
              v-model.trim="ciclo.permiso_agregar"
              class="form-check-input"
              :class="estatus($v.ciclo.permiso_agregar)"
            />
            <label class="form-check-label" for="permiso_agregar">Permite Agregar</label>
          </div>
          <div class="errores" v-if="!$v.ciclo.permiso_agregar.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="permiso_editar"
              v-model.trim="ciclo.permiso_editar"
              class="form-check-input"
              :class="estatus($v.ciclo.permiso_editar)"
            />
            <label class="form-check-label" for="permiso_editar">Permitir editar</label>
          </div>
          <div class="errores" v-if="!$v.ciclo.permiso_editar.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="permiso_inscribir"
              v-model.trim="ciclo.permiso_inscribir"
              class="form-check-input"
              :class="estatus($v.ciclo.permiso_inscribir)"
            />
            <label class="form-check-label" for="permiso_inscribir">Permite Inscribir</label>
          </div>
          <div class="errores" v-if="!$v.ciclo.permiso_inscribir.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="permiso_cerrar"
              v-model.trim="ciclo.permiso_cerrar"
              class="form-check-input"
              :class="estatus($v.ciclo.permiso_cerrar)"
            />
            <label class="form-check-label" for="permiso_cerrar">Permite cerrar</label>
          </div>
          <div class="errores" v-if="!$v.ciclo.permiso_cerrar.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar ciclos",
      listadoCiclos: [],
      listadoRegiones: [],
      listadoSiNo: [
        { valor: true, texto: "Sí" },
        { valor: false, texto: "No" },
      ],
      ciclo: {},
      busqueda: {},
      columnas: [
        {
          field: "nom_ciclo",
          key: "a",
          title: "Ciclo",
          align: "left",
        },
        {
          renderBodyCell: (d) =>
            d.row.ciclo_vigente ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "b",
          title: "Vigente",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.permiso_agregar ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "c",
          title: "¿Agregar?",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.permiso_editar ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "d",
          title: "¿Editar?",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.permiso_inscribir ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "e",
          title: "¿Inscribir?",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.permiso_cerrar ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "f",
          title: "¿Cerra?",
          align: "center",
        },
        {
          renderBodyCell: (d) => (
            <span>
              <a href="javascript:;" onClick={() => this.editar(d.row)}>
                <font-awesome-icon size="lg" icon="edit" />
              </a>
              <a href="javascript:;" onClick={() => this.eliminar(d.row)}>
                <font-awesome-icon size="lg" icon="trash" />
              </a>
            </span>
          ),
          key: "g",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      ciclo: {
        nom_ciclo: {
          required,
        },
        ciclo_vigente: {
          required: false,
        },
        permiso_agregar: {
          required: false,
        },
        permiso_editar: {
          required: false,
        },
        permiso_inscribir: {
          required: false,
        },
        permiso_cerrar: {
          required: false,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar ciclo";
      this.ciclo = { region_id: null };
      this.$refs.detalle.show();
    },
    editar(elemento) {
      (this.titulo = "Editar ciclo"),
        (this.ciclo = Object.assign({}, elemento));
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar el ciclo: " + elemento.nom_ciclo)
        .then(() => {
          api
            .eliminarCiclo(elemento.ciclo_id)
            .then((resp) => {
              let r = resp.data;
              if (r.respuesta) {
                this.actualizarCiclos();
              } else {
                this.$parent.aviso(r.mensaje);
              }
            })
            .catch((error) => {
              this.$parent.aviso("No se pudo eliminar " + error);
            });
        });
    },
    guardar() {
      this.$v.ciclo.$touch();
      if (!this.$v.ciclo.$invalid) {
        api
          .guardarCiclo(this.ciclo)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarCiclos();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    limpiarBusqueda() {
      this.busqueda = {
        ciclo_vigente: null,
      };
    },

    actualizarCiclos() {
      api.listarCiclos(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoCiclos = r.data;
        } else {
          this.listadoCiclos = [];
        }
      });
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.limpiarBusqueda();
    this.actualizarCiclos();
  },
};
</script>

