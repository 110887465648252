<template>
  <div>
    <div class="card">
      <h2 class="card-header">Reporte DMR-01: Concentrado de MaRes por Diócesis</h2>
      <div id="tabla-container"> <!-- Datos del reporte -->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Diócesis:</th>
              <td>{{ reporte.general.diocesis }}</td>
            </tr>
            <tr>
              <th class="titulo">Región:</th>
              <td>{{ reporte.general.region }}</td>
            </tr>
            <tr>
              <th class="titulo">Bloque:</th>
              <td>{{ reporte.general.bloque }}</td>
            </tr>
            <tr>
              <th class="titulo">Ciclo:</th>
              <td>{{ reporte.general.ciclo }}</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Datos del Reporte -->

      <div class="card-body"> <!--MaRes en el CBF-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Datos de Madres Responsables (MaRes) que están cursando el Ciclo Básico de Formación (CBF)</th>
                <th>Primer Nivel</th>
                <th>Segundo Nivel</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Madres Responsables</td>
                <td class="text-center">{{ reporte.datos.equiperos['1'] }}</td>
                <td class="text-center">{{ reporte.datos.equiperos['2'] }}</td>
                <td class="text-center">{{ reporte.datos.equiperos.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de Equipos</td>
                <td class="text-center">{{ reporte.datos.equipos['1'] }}</td>
                <td class="text-center">{{ reporte.datos.equipos['2'] }}</td>
                <td class="text-center">{{ reporte.datos.equipos.total }}</td>
              </tr>
              <tr> <!--Renglón 3-->
                <td>Número de Equipos Zonales</td>
                <td class="text-center">{{ reporte.datos.zonales['1'] }}</td>
                <td class="text-center">{{ reporte.datos.zonales['2'] }}</td>
                <td class="text-center">{{ reporte.datos.zonales.total }}</td>
              </tr>
              <tr> <!--Renglón 4-->
                <td>Número de Asistentes Eclesiales de Zona</td>
                <td class="text-center">{{ reporte.datos.asistentes['1'] }}</td>
                <td class="text-center">{{ reporte.datos.asistentes['2'] }}</td>
                <td class="text-center">{{ reporte.datos.asistentes.total }}</td>
              </tr>
              <tr> <!--Renglón 5-->
                <td>Número de Madres Responsables que han vivido el Kerigma (K)</td>
                <td class="text-center">{{ reporte.datos.kerigma['1'] }}</td>
                <td class="text-center">{{ reporte.datos.kerigma['2'] }}</td>
                <td class="text-center">{{ reporte.datos.kerigma.total }}</td>
              </tr>
              <tr> <!--Renglón 6-->
                <td>Número de Madres Responsables que han vivido el Encuentro Familiar (EF)</td>
                <td class="text-center">{{ reporte.datos.ef['1'] }}</td>
                <td class="text-center">{{ reporte.datos.ef['2'] }}</td>
                <td class="text-center">{{ reporte.datos.ef.total }}</td>
              </tr>
              <tr> <!--Renglón 7-->
                <td>Número de Madres Responsables que han vivido el Curso de Promotoras</td>
                <td class="text-center">{{ reporte.datos.cursopromotoras['1'] }}</td>
                <td class="text-center">{{ reporte.datos.cursopromotoras['2'] }}</td>
                <td class="text-center">{{ reporte.datos.cursopromotoras.total }}</td>
              </tr>
              <tr> <!--Renglón 7-->
                <td>Número de Madres Responsables que han vivido la Capacitación Integral Progresiva I (CIP I)</td>
                <td class="text-center">{{ reporte.datos.cp1['1'] }}</td>
                <td class="text-center">{{ reporte.datos.cp1['2'] }}</td>
                <td class="text-center">{{ reporte.datos.cp1.total }}</td>
              </tr>
              <tr> <!--Renglón 8-->
                <td>Número de Madres Responsables que han vivido la Capacitación Integral Progresiva II (CIP II)</td>
                <td class="text-center">{{ reporte.datos.cp2['1'] }}</td>
                <td class="text-center">{{ reporte.datos.cp2['2'] }}</td>
                <td class="text-center">{{ reporte.datos.cp2.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->
      </div> <!-- card-body Matrimonios en CBF -->

      <div class="card-body"> <!--Matrimonios en Servicio-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Datos de Madres Responsables en diversos servicios</th>
                <th>Promotoras</th>
                <th>Servicios de Área II</th>
                <th>Financieros de Zona</th>
                <th>Equipos de capacitación</th>
                <th>Equipos de Sector y Diocesano</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Madres Responsables</td>
                <td class="text-center">{{ reporte.servicios.mares.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.mares.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.mares.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.mares.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.mares.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.mares.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de Madres Responsables que han vivido el Kerigma (K)</td>
                <td class="text-center">{{ reporte.servicios.kerigma.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.kerigma.total }}</td>
              </tr>
              <tr> <!--Renglón 3-->
                <td>Número  de Madres Responsables que han vivido el Curso de Promotoras</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.cursopromotoras.total }}</td>
              </tr>
              <tr> <!--Renglón 7-->
                <td>Número de Madres Responsables que han vivido la Capacitación Integral Progresiva I (CIP I)</td>
                <td class="text-center">{{ reporte.servicios.cp1.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.cp1.total }}</td>
              </tr>
              <tr> <!--Renglón 8-->
                <td>Número de Madres Responsables que han vivido la Capacitación Integral Progresiva II (CIP II)</td>
                <td class="text-center">{{ reporte.servicios.cp2.promotores }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.area2 }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.financieros }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.capacitacion }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.ecs }}</td>
                <td class="text-center">{{ reporte.servicios.cp2.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->
      </div> <!-- card-body Matrimonios en Servicio -->

      <div id="tabla-container"> <!-- Total Miembros-->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Total Miembros:</th>
              <td class="text-center">{{ parseInt(reporte.datos.equiperos.total)  + parseInt(reporte.servicios.mares.total) }}</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Total Miembros -->

      <div class="card-body"> <!--Grados de Estudio-->
        <div class="form-row">
          <table>
            <thead>
              <tr>
                <th>Grado de Estudios</th>
                <th>Primaria</th>
                <th>Secundaria</th>
                <th>Preparatoria</th>
                <th>Licenciatura</th>
                <th>Postgrado</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr> <!--Renglón 1-->
                <td>Número de Personas en el Ciclo Básico de Formación (CBF)</td>
                <td>Número de MaRes en la Diócesis</td>
                <td class="text-center">{{ reporte.estudios.cbf.primaria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.secundaria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.preparatoria }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.licenciatura }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.postgrado }}</td>
                <td class="text-center">{{ reporte.estudios.cbf.total }}</td>
              </tr>
              <tr> <!--Renglón 2-->
                <td>Número de Personas en diversos servicios</td>
                <td class="text-center">{{ reporte.estudios.servicio.primaria }}</td>
                <td class="text-center">{{ reporte.estudios.servicio.secundaria }}</td>
                <td class="text-center">{{ reporte.estudios.servicio.preparatoria }}</td>
                <td class="text-center">{{ reporte.estudios.servicio.licenciatura }}</td>
                <td class="text-center">{{ reporte.estudios.servicio.postgrado }}</td>
                <td class="text-center">{{ reporte.estudios.servicio.total }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- form-row form-row Tabla-->

      </div> <!-- card-body Grado de Estudios -->
      <div id="contenedor">
        <h6 align="Center">Enviado a Secretarios Nacionales de Región (SNR)</h6>
        <div id="cuadro-firma">
          <div id="linea-firma"></div>
          <div id="label-el">Fecha de envío</div>
          <br/>
          <div id="linea-cargo"></div>
          <div id="label-cargo">Nombre y Rol de quien envía</div>
        </div>
      </div>
    </div> <!-- card Reporte D-01 -->

    <br />
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div> <!--Del botón Regresar--> 
  </div> <!-- Del Template-->
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      Constantes: Constantes,
      modals: {},
    }
  },
  //  };
  methods: {
    reporteDMR01: function (diocesis) {
      api.reporteDMR01(diocesis).then((r) => {
        this.reporte = r.data; 
      });
    },
  },
  computed: {},
  mounted: function () {
    console.log(this.$route);
    const diocesis = this.$route.params.id ?? 0;
    this.reporteDMR01(diocesis);
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-container {
  width: 25%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 50%;
  border: 1px solid #ccc;
  padding: 20px;
  position: relative;
  align-self: center;
}

#cuadro-firma {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#linea-firma, #linea-cargo {
  width: 80%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-top: 25px;
}

#label-el, #label-cargo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

</style>
