<template>
    <div>
        <h4>Gráficas de miembros activos</h4>
        <div>
            <bar-chart :chart-data="datos" :width="8" :height="3" />
        </div>
    </div>
</template>

<script>
    import BarChart from "../components/BarChart";
    import api from "@/api";

    export default {
        name: "Graficas",
        components: { BarChart },
        data() {
            return {
                datos: {},
            };
        },
        methods: {
            graficar: function (sector_id) {
                api.graficas(sector_id).then((r) => {
                    this.datos = r.data; 
                })
                .catch(error => {
                    console.log(error);
                });
            },
        },
        mounted() {
            const sector_id = this.$route.params.id ?? 0;
            this.graficar(sector_id);
        },
    };
</script>