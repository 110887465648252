<template>
  <b-card header="Filtros">
        <b-form-row>
          <b-col md="4">
            <b-form-group label="Región">
              <b-form-select
                v-model="busqueda.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
                v-on:change="filtrarDiocesis"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Diócesis">
              <b-form-select
                v-model="busqueda.diocesis_id"
                :options="listadoDiocesisFiltro"
                value-field="diocesis_id"
                text-field="nom_diocesis"
                v-on:change="actualizarSectores"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Sector">
              <b-form-select
                v-model="busqueda.sector_id"
                :options="listadoSectores"
                value-field="sector_id"
                text-field="nom_sector"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" v-if="mostrarTipoMembresia">
            <b-form-group label="Tipo membresia">
              <b-form-select
                v-model="busqueda.tipo_membresia_id"
                :options="listadoTiposMembresia"
                value-field="tipo_membresia_id"
                text-field="nom_tipo_membresia"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Ciclo">
              <b-form-select
                v-model="busqueda.ciclo_id"
                :options="listadoCiclos"
                value-field="ciclo_id"
                text-field="nom_ciclo"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr>
        <b-button title="Limpiar filtros" @click="limpiarBusqueda()">
                <font-awesome-icon icon="eraser" /> Limpiar
              </b-button>
    </b-card>
</template>
<script >
import api from "@/api";
export default {
  name: "FiltrosEventos",
  props: {
    value: {
        type: Object,
        required: true
      },
    mostrarTipoMembresia: {
      type: Boolean,
      default: true,
    },
    tipoMembresia: Number,
  },
  data() {
    return {
      cicloActual: null,
      busqueda: this.value,
      listadoRegiones: [],
      listadoDiocesis: [],
      listadoDiocesisFiltro: [],
      listadoSectores: [],
      listadoCiclos: [],
      listadoTiposMembresia: [],
    };
  },

  validations() {
    return {
      
    };
  },
  methods: {
    buscar() {
      const busqueda = { ...this.busqueda };
      
      this.$store.dispatch("guardarFiltros", busqueda);
      this.$emit('input', busqueda);
    },
    limpiarBusqueda() {
      this.busqueda = {
        region_id:  null,
        diocesis_id: null,
        sector_id: null,
        tipo_membresia_id:  null,
        ciclo_id: this.cicloActual,
      }
      this.buscar();
    },
    inicializarBusqueda(ciclo_id) {
      if (!this.$store.getters.filtros) {
        this.$store.dispatch('consultarFiltros')
      }
      const val = this.$store.getters.filtros;
      this.busqueda = {
        region_id: val.region_id ?? null,
        diocesis_id: val.diocesis_id ?? null,
        sector_id: val.sector_id ?? null,
        tipo_membresia_id: val.tipo_membresia_id ?? null,
        ciclo_id: val.ciclo_id ?? ciclo_id,

      };
      //this.$emit('input', { ...this.busqueda });
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
        if (this.busqueda.region_id) {
          this.filtrarDiocesis(false);
        }
      });
    },
    actualizarDiocesis() {
      api.listarDiocesis().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
          this.listadoDiocesisFiltro = r.data;
        } else {
          this.listadoDiocesis = [];
        }
      });
    },
    filtrarDiocesis(ui=true) {

      let existe = false;
      this.listadoDiocesisFiltro = this.listadoDiocesis.filter(d => {
        const agregar = this.busqueda.region_id == null || this.busqueda.region_id == d.region_id;
        if (agregar) {
          if (d.diocesis_id == this.busqueda.diocesis_id) {
            existe = true;
          }
        }
        return agregar;
      });
      if (!existe && ui) {
        this.busqueda.diocesis_id = null;
        this.actualizarSectores();
      }
      if(ui) {
        this.buscar();
      }
    },
    actualizarSectores(ui=true) {
      if (ui) {
        this.busqueda.sector_id = null;
      }
      if (this.busqueda.diocesis_id != null) {
        api.listarSectoresDiocesis(this.busqueda.diocesis_id).then((r) => {
          if (Array.isArray(r.data)) {
            this.listadoSectores = r.data;
          } else {
            this.listadoSectores = [];
          }
        });
      } else {
        this.listadoSectores = [];
      }
      if (ui) {
        this.buscar();
      }
    },
    actualizarTiposMembresia() {
      api.listarTipoMembresias().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoTiposMembresia = r.data;
        } else {
          this.listadoTiposMembresia = [];
        }
      });
    },
    actualizarCiclos() {
      api.listarCiclos().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoCiclos = r.data;

          if (!this.busqueda.ciclo_id) {
            const ciclo = this.listadoCiclos.find(c => c.ciclo_vigente);
            this.busqueda.ciclo_id = ciclo.ciclo_id;
            this.cicloActual = ciclo.ciclo_id;
            this.buscar();
          }
        } else {
          this.listadoCiclos = [];
        }
      });
    },    
    inicializa() {
      this.inicializarBusqueda();
      this.actualizarCiclos();
      this.actualizarRegiones();
      this.actualizarDiocesis();
      this.actualizarSectores(false);
      if (this.mostrarTipoMembresia) {
        this.actualizarTiposMembresia();
      }
    }
  },
  mounted() {
    this.inicializa()
  },
}

</script>

<style>

  .input-select-container {
    display: flex; /* Hace que los elementos hijos se muestren en línea */
  }

  .input-select-container select.custom-width,
  .input-select-container input.custom-width {
    width: 250px; /* Establecer un ancho específico */
    margin-right: 10px; /* Espacio a la derecha entre los elementos del contenedor */
  }

  .button-container {
    /* Estilos para el contenedor del botón */
    display: flex;
    align-items: flex-end; /* Alinear el botón en la parte inferior */
  }

</style>