<template>
  <div>
    <h4 class="modal-title">Terminar sesión</h4>
    <p class="text-justify">
      Estas a punto de terminar tu sesión ¿Deseas continuar?
    </p>
    <br>
    <div class="text-center">
      <b-button class="btn btn-primary"  v-on:click.stop.prevent="salir">
        Si
      </b-button>
      <b-button class="btn btn-secondary" to="/">
        No
      </b-button>

    </div>
    <snackbar baseSize="5rem" ref="snackbar" :holdTime="5000" position="top-center"/>
  </div>
  
</template>

<script>
export default {
  name: "Salir",
  components: { },
  data() {
    return {

    };
  },
  methods: {
    salir() {
      this.$store
        .dispatch("terminarSesion")
        .then(() => { 
          this.$router.push('/');
          this.$parent.$forceUpdate();
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
