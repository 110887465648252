<template>
  <div>
    <h4>Evaluación de <b>Promotores</b> en Línea, Ciclo {{ ciclo.nom_ciclo }}</h4>
    <div class="card" v-show="fase != 3">
      <h6 class="card-header">Mi información</h6>
      <div class="card-body">
        <div class="text-center">
          <button v-on:click="leerIdNacional" class="btn btn-primary">
            Identificarme por ID Nacional
          </button>
          <button v-on:click="leerId" class="btn btn-primary">
            Identificarme por ID Diocesano
          </button>
          <button v-on:click="leerQr" class="btn btn-primary">
            Identificarme por Credencial
          </button>


          <div class="text-center" v-if="ciclo == null">
            <h4>Registro cerrado</h4>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card" v-if="fase == 3">
      <h6 class="card-header">Mi información</h6>
      <div class="card-body">
        <div class="form-row">
          <table class="table table-bordered">
            <tr>
              <td>Diócesis</td>
              <td>{{ info.diocesis }}</td>
            </tr>
            <tr>
              <td>Sector</td>
              <td>{{ info.sector }}</td>
            </tr>
            <tr>
              <td>ID</td>
              <td>{{ info.membresia_id }}</td>
            </tr>
            <tr>
              <td>Rol principal</td>
              <td>{{ info.rol }}</td>
            </tr>
            <tr>
              <td>Nombre el</td>
              <td>{{ info.nombre }} </td>
            </tr>
            <tr>
              <td>Nombre ella</td>
              <td>{{ info.nombre_ella }} </td>
            </tr>
          </table>

        </div>

      </div>
    </div>
    <br />
    <div class="card" v-if="fase == 3">
      <h6 class="card-header">Mis promotores</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-12" v-if="promotores.length == 0 && promotor == null">
            <label style="color:red;">¡¡¡No hay promotores!!!</label>

          </div>
          <div class="form-group col-md-12" v-if="promotores.length > 1">
            <label>Promotor a evaluar</label>

            <select v-model="promotor" class="form-control" @change="cambiarPromotor">
              <option :value="null">
                Elegir promotor
              </option>
              <option :value="p" v-for="p in promotores" :key="p.membresia_id">
                {{ p.nombre }} y {{ p.nombre_ella }}
              </option>
            </select>
            <div class="errores" v-if="!$v.promotor.required">
              Requerido
            </div>
          </div>
          <table v-if="promotor != null" class="table table-bordered">

            <tr>
              <td>Nombre el</td>
              <td>{{ promotor.nombre }}</td>
            </tr>
            <tr>
              <td>Nombre ella</td>
              <td>{{ promotor.nombre_ella }}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>

    <br />
    <div class="card" v-show="fase == 3 && promotor != null">
      <h6 class="card-header">Evaluación</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="domicilio">Tema a evaluar</label>

            <select v-model="tema" class="form-control" v-on:change="cambiarTema">
              <option :value="null">
                Elegir tema
              </option>
              <option :value="t" v-for="t in temas" :key="t.tema_id" :class="{'text-success': t.respuesta_id != null}">
                {{ t.respuesta_id ? '&check;' : '&nbsp;&nbsp;&nbsp;' }} {{ t.nombre }} 
              </option>
            </select>
            <div class="errores" v-if="!$v.tema.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-12" v-for="(p, pos) in $v.preguntas2.$each.$iter" :key="pos">
            <div v-if="tema != null && (p.$model.tipo == tema.tipo || p.$model.tipo == 'ambos')">
              <label>{{ p.$model.pregunta }} </label>
              <br />
              <div class="btn-group btn-group-toggle active" data-toggle="buttons">
                <label class="btn" :class="p.$model.respuesta == r.evalua_pregunta_opcion_id ? 'btn-success' : 'btn-outline-success'"
                  v-for="r in p.$model.opciones" :key="r.evalua_pregunta_opcion_id">
                  <input type="radio" :name="'options' + r.evalua_pregunta_opcion_id" :value="r.evalua_pregunta_opcion_id" v-model="p.$model.respuesta"
                    autocomplete="off"> {{ r.opcion }} 
                </label>
              </div>
              <div class="errores" v-if="!p.respuesta.required">
                Requerido
              </div>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <button class="btn btn-primary" v-bind:disabled="guardando"
              v-on:click.stop.prevent="guardar">Guardar</button>
            <button class="btn btn-secondary" v-on:click.stop.prevent="cancelar">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <b-modal ref="retro" title="Evaluación en línea">
      <p>Tu evaluación se ha registrado exitósamente, muchas gracias.</p>
      <template #modal-footer="{ hide }">
        <button class="btn btn-success" @click="hide()">Aceptar</button>
      </template>
    </b-modal>
    <b-modal ref="idNacional" title="Identificarme por ID Nacional">
      <div class="form-group row">
        <label for="membresia_id" class="col-sm-4 col-form-label">ID Nacional</label>
        <div class="col-sm-8">
          <input
            type="number"
            v-model="login4.membresia_id"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login4.membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" v-on:click.stop.prevent="idNacionalTecleado">
          Aceptar
        </b-button>
        <b-button class="btn" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="id" title="Identificarme por ID Diocesano">
      <div class="form-group row">
        <label for="diocesis_id" class="col-sm-4 col-form-label"
          >Diócesis</label
        >
        <div class="col-sm-8">
          <select v-model="login2.diocesis_id" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="dio in diocesis"
              :value="dio.diocesis_id"
              :key="dio.diocesis_id"
            >
              {{ dio.nom_diocesis }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login2.diocesis_id.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="diocesis_id" class="col-sm-4 col-form-label"
          >Tipo de miembro</label
        >
        <div class="col-sm-8">
          <select v-model="login2.tipo_membresia_id" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="tm in tipoMembresias"
              :value="tm.tipo_membresia_id"
              :key="tm.tipo_membresia_id"
            >
              {{ tm.nom_tipo_membresia }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login2.tipo_membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="membresia_id" class="col-sm-4 col-form-label">ID Diocesano</label>
        <div class="col-sm-8">
          <input
            type="number"
            v-model="login2.membresia_id"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login2.membresia_id.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button class="btn-primary" v-on:click.stop.prevent="idTecleado">
          Aceptar
        </b-button>
        <b-button class="btn" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="qrmodal" title="Identificarme por código QR">
      <p>Aproxime su credencial a la cámara para poder leer el código QR</p>
      <qrcode-stream @decode="codigoEscaneado"></qrcode-stream>

      <template #modal-footer="{ hide }">
        <b-button @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>

    <b-modal ref="pregunta" title="Confirmación de datos">
      <p>Por favor elige una pregunta para confirmar tus datos</p>
      <div class="form-group row">
        <div class="col-sm-6">
          <select v-model="login3.pregunta" class="form-control">
            <option :value="null">Seleccione</option>
            <option
              v-for="pregunta in preguntas"
              :value="pregunta.campo"
              :key="pregunta.campo"
            >
              {{ pregunta.nombre }}
            </option>
          </select>
          <div class="errores" v-if="!$v.login3.pregunta.required">
            Requerido
          </div>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            v-model="login3.respuesta"
            class="form-control"
          />
          <div class="errores" v-if="!$v.login3.respuesta.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button
          class="btn-primary"
          :enabled="!guardando"
          @click.stop.prevent="iniciarSesionDatos"
        >
          Aceptar
        </b-button>
        <b-button @click="hide()">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required, requiredIf } from "vuelidate/lib/validators";
import { QrcodeStream } from 'vue-qrcode-reader';
//import { cantidad } from "@/libs/validadores";
const figura = 'promotor';

export default {
  name: "Evalua",
  components: {QrcodeStream},
  data() {
    return {
      login2: { diocesis_id: null, tipo_membresia_id: null},
      login3: { pregunta: null, respuesta: null},
      login4: { membresia_id: null},
      tema: null,
      temas: [
        { id: '1-3', nombre: 'Tema 1-3', tipo: 'tema', respuesta_id: null },
        { id: '4-6', nombre: 'Tema 4-6', tipo: 'tema', respuesta_id: null },
        { id: '1r', nombre: 'Primera Reunión General', tipo: 'reunion', respuesta_id: null },
        { id: '7-9', nombre: 'Tema 7-9', tipo: 'tema', respuesta_id: null },
        { id: '10-12', nombre: 'Tema 10-12', tipo: 'tema', respuesta_id: null },
        { id: '2r', nombre: 'Segunda Reunión General', tipo: 'reunion', respuesta_id: null },
        { id: '13-15', nombre: 'Tema 13-15', tipo: 'tema', respuesta_id: null },
        { id: '16-17', nombre: 'Tema 16-17', tipo: 'tema', respuesta_id: null },
        { id: '3r', nombre: 'Tercera Reunión General', tipo: 'reunion', respuesta_id: null },
      ],
      tipoMembresias: [],
      preguntas: [],
      preguntas2: [],
      registro: {},
      promotor: {},
      promotores: [],
      login: {},
      diocesis: [],
      guardando: false,
      ciclo: {},
      fase: 1,
      tipo_membresia_id: null,
      contestadas: {},
      info: {},

      errores: {}
    };
  },
  validations() {
    return {
      login2: {
        diocesis_id: { required },
        membresia_id: { required },
        tipo_membresia_id: { required },
      },
      login3: {
        pregunta: { required },
        respuesta: { required },
      },
      login4: {
        membresia_id: { required }
      },
      promotor: {required},
      tema: {required},
      preguntas2: {
        $each: {
            respuesta: { 
              required: requiredIf(p => { 
                return p.tipo == this.tema.tipo || p.tipo == 'ambos'
              }) 
            },
          },
      }
    };
  },
  methods: {
    leerIdNacional: function () {
      this.login3 = {pregunta: null, respuesta: null};
      this.$refs.idNacional.show();
    },
    leerId: function () {
      this.login3 = {pregunta: null, respuesta: null};
      this.$refs.id.show();
    },
    leerQr: function () {
      this.login = {};
      this.$refs.qrmodal.show();
      //this.$refs.qr.escanear();
    },
    idTecleado: function () {
      this.$v.login2.$touch();
      if (!this.$v.login2.$invalid) {
        api.registroConsultarFechas(this.login2).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { ...this.login2 };
            this.$refs.id.hide();
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },
    idNacionalTecleado: function () {
      this.$v.login4.$touch();
      if (!this.$v.login4.$invalid) {
        api.registroNacionalConsultarFechas(this.login4).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { ...this.login4 };
            //this.login3.pregunta = null,
            //this.login3.respuesta = null,
            this.$refs.idNacional.hide();
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },   
    iniciarSesionDatos: function () {
      this.$v.login3.$touch();
      if (!this.$v.login3.$invalid) {
        api.evaluacionLoginFechas(this.login3).then((r) => {
          let resp = r.data;
          if (resp.respuesta) {
            this.tokenr = resp.tokenr;
            localStorage.setItem('tokenr', resp.tokenr);
            this.$refs.pregunta.hide();
            this.cargarInformacion();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      }
    },
    cargarInformacion: function () {
      api.evaluacionDatosEquipero({tokenr: this.tokenr}).then((resp) => {
        if (resp.data.respuesta) {
          this.fase = 3;
          this.info = resp.data.datos;
          
          this.promotores = resp.data.promotores;
          if (this.promotores.length == 1) {
            this.promotor = this.promotores[0];
            this.listarEvaluacionesContestadas();
          }
        } else {
          this.tokenr = null
          localStorage.removeItem('tokenr');
        }
      });
    },
    guardar: function () {
      this.$v.promotor.$touch();
      this.$v.tema.$touch();
      this.$v.preguntas2.$touch();
      let valido = !this.$v.promotor.$invalid && !this.$v.tema.$invalid && !this.$v.preguntas2.$invalid;

      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        const respuestas = this.preguntas2.filter(p => p.respuesta != null).map(p =>{ return {'evalua_pregunta_id': p.evalua_pregunta_id,  'evalua_pregunta_opcion_id': p.respuesta}});
        const datos = { 
          tokenr: this.tokenr,
          respuestas: respuestas, 
          tema: this.tema.id, 
          membresia_id: this.info.membesia_id, 
          evaluado_id: this.promotor.membresia_id,
          evalua_respuesta_id: this.contestadas[this.tema.id]?.id,
        };

        api.evaluacionGuarda(datos).then((response) => {
          let datos = response.data;
          if (datos != null && datos.respuesta) {
            this.$refs.retro.show();
            this.nuevo();
          } else {
            this.$parent.error("Error al guardar la información: " + (datos != null ? datos.mensaje : 'error interno'));
          }
          this.guardando = false;
        }, function () {
          this.$parent.error("Error al guardar la información");
          this.guardando = false;
        });
      } else {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    cancelar: function () {
      if (confirm('¿Desea cancelar su evaluación?')) {
        this.nuevo();
      }
    },
    cambiarPromotor: function() {
      this.preguntas2.forEach(p => p.respuesta = null);
    },
    cambiarTema: function() {
      const contestada = this.contestadas[this.tema.id]?.resp || {};
      this.preguntas2.forEach(p => {
        p.respuesta = contestada[p.evalua_pregunta_id];
      });
    },
    nuevo: function () {
      this.tokenr = null;
      localStorage.setItem('tokenr', null);
      this.info = {};
      this.login = {};
      this.promotor = null;
      this.tema = null;
      this.fase = 1;
      this.temas.forEach(t => t.evalua_respuesta_id = null);
    },

    codigoEscaneado: function (codigo) {
      if (codigo.match(/^[A-Z]{3}-[JM]A?-\d+?$/)) {
        this.$refs.qrmodal.hide();
        api.registroConsultarQR(codigo).then((r) => {
          const resp = r.data;
          if (resp.respuesta) {
            this.preguntas = resp.preguntas;
            this.login3 = { pregunta: null, respuesta: null,  'qr': codigo };
            this.$refs.pregunta.show();
          } else {
            this.$parent.error(resp.mensaje);
          }
        });
      } else {
        this.$parent.error("Codigo incorrecto '" + codigo + "'");
      }
    },
    
    verificaLogin: function () {
      const tokenr = localStorage.getItem('tokenr', null);
      if (tokenr != null) {
        this.tokenr = tokenr;
        this.cargarInformacion();
      }
    },
    listarDiocesis: function () {
      api.listarRegistroDiocesis().then(resp => {
        this.diocesis = resp.data;
      });
    },
    listarTipoMembresias: function () {
      api.listarRegistroTiposMembresia().then((r) => {
        this.tipoMembresias = r.data;
      });
    },    
    listarPreguntas: function () {
      api.evaluacionListarPreguntas(figura).then((response) => {
        response.data.forEach(p => p.respuesta = null)
        this.preguntas2 = response.data;
      }, () => {
        this.$parent.error('Error 100: No se pueden obtener las preguntas');
      });
    },
    listarEvaluacionesContestadas: function () {
      this.contestadas = {}
      this.temas.forEach(t => t.respuesta_id = null);
      if (this.promotor == null) return;

      const datos = {
        evaluado_id: this.promotor.membresia_id,
        tokenr: this.tokenr
      }
      api.evaluacionContestada(datos).then((response) => {
        this.contestadas = response.data;
        this.temas.forEach(t => {
          if (t.id in this.contestadas) {
            t.respuesta_id = this.contestadas[t.id].id;
          }
        });
      });
    },
    validaActivo: function () {
      api.obtenerCicloVigente().then((resp) => {
        this.ciclo = resp.data;
      });
    },
    validar_quitar: function (campo) {
      if (campo == "") {
        return "Requerido";
      }
      return null;
    }
  },
  mounted() {

    this.validaActivo();
    this.verificaLogin();
    this.listarDiocesis();
    this.listarTipoMembresias();
    this.listarPreguntas();
  },
};
</script>
