<template>
  <div>
    <h4>
      <font-awesome-icon icon="calendar" /> {{ titulo }}
    </h4>
    <div class="card">
      <h6 class="card-header">Datos del Evento</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="evento_id">ID del Evento</label>
            <input
              readonly="readonly"
              maxlength="40"
              v-model.trim="evento.evento_id"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <select
              v-model="evento.diocesis_id"
              class="form-control"
              v-on:change="actualizaSectores"
            >
              <option
                v-for="dio in diocesis"
                :value="dio.diocesis_id"
                :key="dio.diocesis_id"
              >
                {{ dio.nom_diocesis }}
              </option>
            </select>
            <div class="errores" v-if="!$v.evento.diocesis_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <select v-model="evento.sector_id" class="form-control">
              <option
                v-for="sector in sectores"
                :value="sector.sector_id"
                :key="sector.sector_id"
              >
                {{ sector.nom_sector }}
              </option>
            </select>
            <!--Creo que esta validación que sigue está de más, porque cuando entra a esta ventana
            ya hay un Sector seleccionado, y además no te permite dejar en blanco este "Select"-->
            <div class="errores" v-if="!$v.evento.sector_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Nombre del curso">
              <b-form-select
                v-model="evento.formacion_id"
                :options="formaciones"
                value-field="formacion_id"
                text-field="nom_formacion"
              >
              </b-form-select>
              <div class="errores" v-if="!$v.evento.formacion_id.required">
                Requerido
              </div>
            </b-form-group>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_ini">Fecha de Inicio:</label>
            <input
              type="date"
              id="fecha_ini"
              v-model.trim="evento.fecha_ini"
              class="form-control"
            />
            <div class="errores" v-if="!$v.evento.fecha_ini.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_ini">Hora de Inicio:</label>
            <input
              type="time"
              id="hora_ini"
              v-model.trim="evento.hora_ini"
              class="form-control"
            />
            <div class="errores" v-if="!$v.evento.hora_ini.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="fecha_fin">Fecha de término:</label>
            <input
              type="date"
              id="fecha_fin"
              v-model.trim="evento.fecha_fin"
              class="form-control"
            />
            <div class="errores" v-if="!$v.evento.fecha_fin.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="hora_fin">Hora de término:</label>
            <input
              type="time"
              id="hora_fin"
              v-model.trim="evento.hora_fin"
              class="form-control"
            />
            <div class="errores" v-if="!$v.evento.hora_fin.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Facilitadores">
              <b-form-select
                v-model="evento.rol_id"
                :options="roles"
                value-field="rol_id"
                text-field="nom_rol"
              >
              </b-form-select>
            </b-form-group>
            <div class="errores" v-if="!$v.evento.rol_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="lugar_evento">Lugar del evento</label>
            <input
              maxlength="255"
              v-model.trim="evento.lugar"
              class="form-control"
            />
            <div class="errores" v-if="!$v.evento.lugar.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="observaciones">Observaciones</label>
            <input
              maxlength="250"
              v-model.trim="evento.observaciones"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="adjunto" class="upload-label">Adjuntar archivo</label>
            <archivo-pdf v-model="evento.adjunto" />
          </div>
        </div>
        <!-- form-row Datos del evento -->
      </div>
      <!-- card-body Datos del evento -->
    </div>
    <!-- card Datos del evento -->
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-primary"
        :disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Guardar
      </button>
      <button
        class="btn btn-secondary"
        v-on:click.stop.prevent="$router.go(-1)"
      >
        Regresar
      </button>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Participantes</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes: {{ evento.asistentes.length }}
          </div>
          <div class="col-md-12 text-right botones-accion">
            <b-button @click="exportarExcel" :disabled="evento.asistentes.length == 0">Exportar Excel</b-button>
            <button
              class="btn btn-secondary"
              @click="$refs.credencial.show()"
              v-show="evento.evento_id != null"
            >
              <font-awesome-icon icon="search" />
              Registrar con credencial
            </button>
            <button
              class="btn btn-secondary"
              @click="$refs.id.show()"
              v-show="evento.evento_id != null"
            >
              <font-awesome-icon icon="search" />
              Registrar con ID
            </button>
            <button
              class="btn btn-secondary"
              @click="$refs.idd.show()"
              v-show="evento.evento_id != null"
            >
              <font-awesome-icon icon="search" />
              Registrar con ID diocesano
            </button>
            <button
              class="btn btn-secondary"
              @click="$refs.nombre.show()"
              v-show="evento.evento_id != null"
            >
              <font-awesome-icon icon="search" />
              Registrar por nombre o apellido
            </button>
          </div>
          <!-- Este grid representa la tabla donde se visualizarán los registros al evento -->
          <br />
          <ve-table
            style="width: 100%"
            rowKeyFieldName="membresia_id"
            border-y
            :fixed-header="true"
            :columns="columnas"
            :table-data="evento.asistentes"
            :max-height="400"
            :cell-selection-option="{ enable: false }"
            :virtualScrollOption="{ enable: true }"
          />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Participantes No MFC</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            No. participantes no mfc: {{ evento.asistentes_no.length }}
          </div>
          <div class="col-md-6 text-right botones-accion">
            <b-button @click="exportarExcelNoMfc" :disabled="evento.asistentes_no.length == 0">Exportar Excel</b-button>
            <button
              class="btn btn-secondary"
              @click="$refs.nomfc.show()"
              v-show="evento.evento_id != null"
            >
              <font-awesome-icon icon="search" />
              Registrar asistentes NO MFC
            </button>
          </div>
          <!-- Este grid representa la tabla donde se visualizarán los registros al evento -->
          <br />
          <ve-table
            style="width: 100%"
            rowKeyFieldName="evento_no_mfc_id"
            border-y
            :fixed-header="true"
            :columns="columnasno"
            :table-data="evento.asistentes_no"
            :max-height="200"
            :cell-selection-option="{ enable: false }"
            :virtualScrollOption="{ enable: true }"
          />
        </div>
        <!-- form-row Asistencia -->
      </div>
      <!-- card-body Asistencia -->
    </div>
    <!-- card Asistencia -->

    <!-- Aquí empieza el código para generar la ventana modal -->
    <b-modal ref="credencial" title="Registro de asistentes con QR">
      <!-- Si el registro es con Credencial -->
      <p>Aproxime su credencial a la cámara para poder leer el código QR</p>
      <qrcode-stream @decode="guardarQr"></qrcode-stream>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal ref="id" title="Registro de asistentes por ID">
      <!-- Si el registro es con ID de miembro -->
      <form @submit.prevent="guardarId()">
        <b-form-group label="ID">
          <b-form-input v-model="id.id"></b-form-input>
          <div class="errores" v-if="!$v.id.id.required">Requerido</div>
          <div class="errores" v-if="!$v.id.id.numeros">Ingrese solo números</div>
        </b-form-group>
      </form>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarId()">
          Registrar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal ref="idd" title="Registro de asistentes por ID diocesano">
      <!-- Si el registro es con ID de miembro -->
      <form @submit.prevent="guardarIdD()">
        <b-form-group label="Diócesis">
          <b-form-select 
            v-model="idd.diocesis_id"
            :options="diocesis"
            value-field="diocesis_id"
            text-field="nom_diocesis"
          ></b-form-select>

          <div class="errores" v-if="!$v.idd.diocesis_id.required">Requerido</div>
        </b-form-group>
        <b-form-group label="Tipo de miembro">
          <b-form-select 
            v-model="idd.tipo_membresia_id"
            :options="tipos_membresia"
            value-field="tipo_membresia_id"
            text-field="nom_tipo_membresia"
          ></b-form-select>

          <div class="errores" v-if="!$v.idd.tipo_membresia_id.required">Requerido</div>
        </b-form-group>
        <b-form-group label="ID">
          <b-form-input v-model="idd.id"></b-form-input>
          <div class="errores" v-if="!$v.idd.id.required">Requerido</div>
          <div class="errores" v-if="!$v.idd.id.numeros">Ingrese solo números</div>
        </b-form-group>
      </form>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarIdD()">
          Registrar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal  ref="nombre" title="Registro de asistentes por nombre">
      <!-- Si el registro es por Nombre y apellido -->
      <b-form-group label="Tipo de miembro">
        <b-form-select 
          v-model="idd.tipo_membresia_id"
          :options="tipos_membresia"
          value-field="tipo_membresia_id"
          text-field="nom_tipo_membresia"
        >
          <b-form-select-option :value="null">Todos</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Nombre y/o Apellido">
        <b-form-input v-model="nombre.nombre" @input="cambioNombreDebounced()"></b-form-input>
        <div class="errores" v-if="!$v.nombre.nombre.required">Requerido</div>
        <div class="errores" v-if="!$v.nombre.nombre.letras">Ingrese solo letras</div>
      </b-form-group>
      <b-form-group label="Seleccionar Asistente">
        <b-form-select :select-size="4"
          v-model="nombre.membresia_id"
        >
          <b-form-select-option
              v-for="asis in asistentesEncontrados"
              :key="asis.membresia_id"
              :value="asis.membresia_id"
              :title="`${asis.nombre_completo} ${asis.nom_tipo_membresia.toUpperCase()} ${asis.nom_rol} ${asis.sector}, ${asis.diocesis}`"
              >{{ `${asis.nombre_completo} ${asis.nom_tipo_membresia.toUpperCase()} ${asis.sector} ${asis.diocesis}`
              }}</b-form-select-option
            >
        </b-form-select>
        <div class="errores" v-if="!$v.nombre.membresia_id.required">Requerido</div>
      </b-form-group>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarNombre()">
          Registrar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <b-modal ref="nomfc" title="Registro de asistentes no MFC">
      <!-- Si el registro es para asistentes NO MFC -->
      <b-form-group label="Nombre">
        <b-form-input v-model="nomfc.nombre"></b-form-input>
        <div class="errores" v-if="!$v.nomfc.nombre.required">Requerido</div>
        <div class="errores" v-if="!$v.nomfc.nombre.letras">Ingrese solo letras</div>
      </b-form-group>
      <b-form-group label="Dirección">
        <b-form-input v-model="nomfc.direccion"></b-form-input>
      </b-form-group>
      <b-form-group label="Teléfono">
        <b-form-input maxlength="10" v-model="nomfc.telefono"></b-form-input>
        <div class="errores" v-if="!$v.nomfc.telefono.telefono">
          Ingrese solo números (10 dígitos)
        </div>
      </b-form-group>
      <b-form-group label="Correo electrónico">
        <b-form-input v-model="nomfc.correo"></b-form-input>
      </b-form-group>
      <div class="errores" v-if="!$v.nomfc.correo.correo">
        Ingrese una dirección de correo válido
      </div>
      <b-form-group label="Casado por la Iglesia">
        <b-form-checkbox v-model="nomfc.casado_iglesia">
          ¿Casado por la Iglesia?
        </b-form-checkbox>
      </b-form-group>

      <!-- Personalizar el footer -->
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarNoMfc()">
          Registrar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    <!-- Aquí termina el código que generó la ventana modal-->
    <b-modal
      v-model="guardando"
      title="Guardando"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center">
        <barra-progreso v-model="avanceGuardando" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { telefono, letras, correo, numeros } from "@/libs/validadores";
import ArchivoPdf from "@/components/ArchivoPdf.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import { exportarExcel } from "@/utils"
import BarraProgreso from "@/components/BarraProgreso";

function debounce(fn, delay){
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
   }
}

export default {
  name: "eventosEditar",
  components: { QrcodeStream, ArchivoPdf, BarraProgreso },
  data() {
    return {
      diocesis: [],
      sectores: [],
      formaciones: [],
      guardando: false,
      roles: [],
      tipos_membresia: [],

      nomfc: {},
      id: { id: null },
      idd: { diocesis_id:null, tipo_membresia_id: null, id: null },
      nombre: { tipo_membresia_id: null,  nombre: null, membresia_id: null },

      asistentesEncontrados: [],
      Constantes: Constantes,
      titulo: "",
      evento: {
        evento_id: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        lugar: null,
        adjunto: null,
        rol_id: null, //este campo al parecer no existe en la tabla "evento". Anteriormente era "capacitador"
        observaciones: null,
        asistentes: [],
        asistentes_no: [],
      },

      permisos: {
        agregar: ["", "evento-agregar"],
        editar: ["", "evento-editar"],
        estatus: ["", "evento-estatus"],
      },
      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nom_tipo_membresia",
          key: "d",
          title: "Tipo miembro",
          align: "left",
        },
        {
          field: "nombre_completo",
          key: "e",
          title: "Nombre",
          align: "left",
        },
        {
          field: "nom_rol",
          key: "f",
          title: "Rol",
          align: "left",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => this.eliminarParticipante(d.row, d.rowIndex)}
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
      columnasno: [
        {
          field: "evento_no_mfc_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "nombre",
          key: "b",
          title: "Nombre",
          align: "left",
        },
        {
          field: "direccion",
          key: "c",
          title: "Dirección",
          align: "left",
        },
        {
          field: "telefono",
          key: "d",
          title: "Teléfono",
          align: "left",
        },
        {
          field: "casados_iglesia",
          key: "e",
          title: "Casados iglesia",
          align: "left",
        },
        
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => this.eliminarParticipanteNo(d.row, d.rowIndex)}
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      
      evento: {
        diocesis_id: { required },
        sector_id: { required },
        fecha_ini: { required },
        hora_ini: { required },
        fecha_fin: { required },
        hora_fin: { required },
        rol_id: { required: false }, // En el sistema anterior este campo era "capacitador"
        lugar: { required },
        formacion_id: { required },
      },
      id: { 
        id: {required, numeros }
      },
      idd: { diocesis_id:{required}, tipo_membresia_id: {required}, id: {required, numeros} },
      nombre: {
        nombre: { required, letras },
        membresia_id: {required},
      },
      nomfc: {
        nombre: {required, letras},
        telefono: {telefono},
        correo: {correo},
      }
    };
  },
  //  };
  methods: {
    exportarExcel() {
      exportarExcel(this.evento.asistentes);
    },
    exportarExcelNoMfc() {
      exportarExcel(this.evento.asistentes_no);
    },
    cambioNombre() {
      console.log(this.nombre.nombre);
      const nombre = this.nombre.nombre.trim();
      if (nombre && nombre.length >= 3) {
         api.registrarBuscarParticipanteNombre(this.nombre.tipo_membresia_id ?? 0, this.nombre.nombre).then((r => {
          const resp = r.data;
          this.nombre.membresia_id = null;
          if (resp.respuesta) {
            this.asistentesEncontrados = r.data.encontrados;
          } else {
            this.$parent.error(resp.mensaje);
          }
         }))
      }
    },

    guardarNombre() {
      this.$v.nombre.$touch();
      if (this.$v.nombre.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      if (this.evento.asistentes.some(p => p.membresia_id == this.nombre.membresia_id)) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api.registrarParticipanteId(this.evento.evento_id, this.nombre.membresia_id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.nombre.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarId() {
      this.$v.id.$touch();
      if (this.$v.id.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      if (this.evento.asistentes.some(p => p.membresia_id == this.id.id)) {
        this.$parent.error("Participante ya registrado");
        return;
      }
      api.registrarParticipanteId(this.evento.evento_id, this.id.id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.id.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarQr(qr) {
      api.registrarParticipanteQr(this.evento.evento_id, qr).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          if (this.evento.asistentes.some(p => p.membresia_id == resp.asistente.membresia_id)) {
            this.$parent.error("Participante ya registrado");
            return;
          }
          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.credencial.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    guardarIdD() {
      this.$v.idd.$touch();
      if (this.$v.idd.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      api.registrarParticipanteIdd(this.evento.evento_id, this.idd.diocesis_id, this.idd.tipo_membresia_id, this.idd.id).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          if (this.evento.asistentes.some(p => p.membresia_id == resp.asistente.membresia_id)) {
            this.$parent.error("Participante ya registrado");
            return;
          }

          this.evento.asistentes.unshift(resp.asistente);
          this.$refs.idd.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },
    guardarNoMfc() {
      this.$v.nomfc.$touch();
      if (this.$v.nomfc.$invalid) {
        this.$parent.error("Faltan datos por registrar o existen datos incorrectos");
        return;
      }
      api.registrarParticipanteNo(this.evento.evento_id, this.nomfc).then((response) => {
        const resp = response.data;
        if (resp.respuesta) {
          this.evento.asistentes_no.unshift(resp.asistente);
          this.$refs.nomfc.hide();
        } else {
          this.$parent.error(resp.mensaje);
        }
      });
    },

    eliminarParticipante(datos, pos) {
      this.$parent.confirmar(`¿Desea eliminar al participante ${datos.nombre_completo}?`).then(() => {
          api.eliminarParticipante(datos.evento_det_id).then(r => {
            if (r.data.respuesta) {
              this.$parent.mensaje("Participante eliminado");
              this.evento.asistentes.splice(pos, 1);
            } else {
              this.$parent.error(r.data.mensaje);
            }
          });
      });
    },
    eliminarParticipanteNo(datos, pos) {
      this.$parent.confirmar(`¿Desea eliminar al participante ${datos.nombre}?`).then(() => {
          api.eliminarParticipanteNo(datos.evento_no_mfc_id).then(r => {
            if (r.data.respuesta) {
              this.$parent.mensaje("Participante eliminado");
              this.evento.asistentes_no.splice(pos, 1);
            } else {
              this.$parent.error(r.data.mensaje);
            }
          });
      });
    },
    resetModalValues() {
      this.nombre = "";
      this.direccion = "";
      this.telefono = "";
      this.correo = "";
      this.casado_iglesia = false;
    },


    submitForm() {
      // Aquí ponemos el código de lo que queremos hacer con los datos capturados
      console.log("Nombre:", this.nombre);
      console.log("Dirección:", this.direccion);
      console.log("Teléfono:", this.telefono);
      console.log("Correo electrónico:", this.correo);
      console.log("Casado por la Iglesia", this.casado_iglesia);

      //Aquí van los otros campos que se quieren registrar en el evento
      this.closeModal();
    },

    guardar() {
      this.$v.evento.$touch();
      let valido = !this.$v.evento.$invalid;
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarEvento(this.evento)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.evento.evento_id = datos.id;
              this.$parent.mensaje("Información guardada correctamente");
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    listaTiposMembresia: function () {
      api.listarTipoMembresias().then((r) => {
        this.tipos_membresia = r.data;
      });
    },

    listaFormaciones: function (tipo) {
      api.listarFormaciones(tipo).then((r) => {
        this.formaciones = r.data;
      });
    },
    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles() {
      api.listarRolesTodos().then((r) => {
        if (Array.isArray(r.data)) {
          this.roles = r.data;
        } else {
          this.roles = [];
        }
      });
    },

    actualizaSectores: function () {
      var id = this.evento.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },

    obtenerEvento: function (id) {
      api.obtenerEvento(id).then((r) => {
        this.evento = r.data.evento;
        this.tipo = this.evento.tipo_formacion_id;
        this.actualizaSectores();
        this.listaFormaciones(this.tipo);
        this.titulo =
          "Edición de eventos de " + Constantes.TIPOS_EVENTOS[this.tipo];
      });
    },
  },
  computed: {},
  mounted: function () {
    const tipo = parseInt(this.$route.params.tipo) ?? 0;
    if (this.$route.params.id) {
      this.obtenerEvento(this.$route.params.id);
    } else {
      this.tipo = Number.parseInt(this.$route.params.tipo);
      this.titulo = "Agregar evento de " + Constantes.TIPOS_EVENTOS[tipo];
      this.listaFormaciones(tipo);
    }
    this.listaDiocesis();
    this.listaRoles();
    this.listaTiposMembresia();
  },
  created() {
    this.cambioNombreDebounced = debounce(() => {
      this.cambioNombre();
    }, 2500);
  },
};
</script>
<style scoped>

</style>
