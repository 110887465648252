<template>
  <div>
    <h4>Administración de roles</h4>
    <b-card header="Filtros">
      <form @submit.prevent="actualizarRoles">
        <b-form-row>
          <b-col md="3">
            <b-form-group label="Tipo miembro">
              <b-form-select
                v-model="busqueda.tipo_membresia_id"
                :options="listadoTiposMembresia"
                value-field="tipo_membresia_id"
                text-field="nom_tipo_membresia"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Jerarquía">
              <b-form-select
                v-model="busqueda.jerarquia_id"
                :options="listadoJerarquias"
                value-field="jerarquia_id"
                text-field="jerarquia"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div class="busqueda">
          <b-button @click="limpiarBusqueda"
            ><font-awesome-icon icon="eraser" /> Limpiar</b-button
          >&nbsp;
          <b-button variant="secondary" type="submit"
            ><font-awesome-icon icon="search" /> Buscar</b-button
          >
        </div>
      </form>
    </b-card>
    <div class="acciones">
      <div class="text-right">
        <b-button @click="agregar">Agregar</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="rol_id"
      :columns="columnas"
      :table-data="listado"
      :border-y="true"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />

    <b-modal scrollable size="lg" ref="detalle" v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del rol</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-md-4">
          <label>Clave rol</label>
          <input
            maxlength="50"
            style="max-width: 200px"
            v-model.trim="actual.clave_rol"
            class="form-control"
            :class="estatus($v.actual.clave_rol)"
          />
          <div class="errores" v-if="!$v.actual.clave_rol.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-md-8">
          <label>Nombre rol</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="actual.nom_rol"
            class="form-control"
            :class="estatus($v.actual.nom_rol)"
          />
          <div class="errores" v-if="!$v.actual.nom_rol.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-md-6">
          <b-form-group label="Tipo miembro">
            <b-form-select
            :class="estatus($v.actual.tipo_membresia_id)"
              v-model="actual.tipo_membresia_id"
              :options="listadoTiposMembresia"
              value-field="tipo_membresia_id"
              text-field="nom_tipo_membresia"
            >
              <b-form-select-option :value="null"
                >Seleccione</b-form-select-option
              >
            </b-form-select>
            <div class="errores" v-if="!$v.actual.tipo_membresia_id.required">
              Requerido
            </div>
          </b-form-group>
        </div>
        <div class="form-group col-md-6">
          <b-form-group label="Jerarquía">
            <b-form-select
            :class="estatus($v.actual.jerarquia_id)"
              v-model="actual.jerarquia_id"
              :options="listadoJerarquias"
              value-field="jerarquia_id"
              text-field="jerarquia"
            >
              <b-form-select-option :value="null"
                >Seleccione</b-form-select-option
              >
            </b-form-select>
            <div class="errores" v-if="!$v.actual.jerarquia_id.required">
              Requerido
            </div>
          </b-form-group>
        </div>
        <div class="form-group col-md-12">
          <label>Permisos</label>
          <div style="height: 150px; overflow-y: auto">
            <tree-funciones-rol
              class="item"
              :item="organigramas"
            ></tree-funciones-rol>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import TreeFuncionesRol from "@/components/TreeFuncionesRol.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  components: { TreeFuncionesRol },
  data() {
    return {
      titulo: "Agregar rol",
      listadoTiposMembresia: [],
      listadoJerarquias: [],
      listado: [],
      listo: [],
      organigramas: {},
      busqueda: [],
      actual: {
        area: null,
        responsable: null,
        cargo: null,
        email: null,
        telefono: null,
        telefonod: null,
      },
      columnas: [
        { field: "clave_rol", key: "a", title: "Clave", align: "left" },
        { field: "nom_rol", key: "b", title: "Nombre rol", align: "left" },
        {
          renderBodyCell: (d) => d.row.tipo_membresia.nom_tipo_membresia,
          key: "c",
          title: "Tipo membresia",
        },
        {
          renderBodyCell: (d) => d.row.jerarquia.jerarquia,
          key: "d",
          title: "Jerarquia",
        },
        { renderBodyCell: this.acciones, key: "e", title: "Acciones" },
      ],
    };
  },
  validations() {
    return {
      actual: {
          clave_rol: {
          required,
        },      nom_rol: {
          required,
        },
        tipo_membresia_id: {
          required,
        },
        jerarquia_id: {
          required,
        },
      },
    };
  },
  methods: {
    acciones(t) {
      return (
        <span>
          <a href="javascript:;" onClick={() => this.editar(t.row)}>
            <font-awesome-icon size="lg" icon="edit" />
          </a>
          <a href="javascript:;" onClick={() => this.eliminar(t.row)}>
            <font-awesome-icon size="lg" icon="trash" />
          </a>
        </span>
      );
    },
    agregar() {
      this.titulo = "Agregar rol";
      this.actual = {tipo_membresia_id: null, jerarquia_id: null};
      this.$refs.detalle.show();
    },
    editar(elemento) {
      for (var l of this.listo) {
        l.seleccionado = elemento.permisos.includes(l.funcion_sistema_id);
      }
      this.titulo = "Editar rol";
      this.actual = Object.assign({}, elemento);
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar el rol: " + elemento.nom_rol)
        .then(() => {
        api
          .eliminarRol(elemento.rol_id)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizarRoles();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {
            this.$parent.aviso("No se pudo eliminar " + error);
          });
      });
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        let ps = [];
        for (var l of this.listo) {
          if (l.seleccionado && l.funcion_sistema_id) {
            ps.push(l.funcion_sistema_id);
          }
        }
        this.actual.permisos = ps;
        api
          .guardarRol(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarRoles();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    limpiarBusqueda() {
      this.busqueda = {
        tipo_membresia_id: null,
        jerarquia_id: null,
      };
    },
    actualizarTiposMembresia() {
      api.listarTipoMembresias().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoTiposMembresia = r.data;
        } else {
          this.listadoTiposMembresia = [];
        }
      });
    },
    actualizarJerarquias() {
      api.listarJerarquias().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoJerarquias = r.data;
        } else {
          this.listadoJerarquias = [];
        }
      });
    },
    actualizarRoles() {
      api.listarRolesDetalle(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    actualizarOrganigrama() {
      api.listarFunciones("").then((response) => {
        if (response.data) {
          this.organigramas = response.data;
          this._agregaFunciones(this.organigramas);
        }
      });
    },
    _agregaFunciones(org) {
      org.seleccionado = false;
      this.listo.push(org);
      if (Array.isArray(org.children)) {
        for (let o of org.children) {
          this._agregaFunciones(o);
        }
      }
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.limpiarBusqueda();
    this.actualizarOrganigrama();
    this.actualizarTiposMembresia();
    this.actualizarJerarquias();
    this.actualizarRoles();
  },
};
</script>

