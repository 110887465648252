<template>
  <div>
    <h4>Contraseñas equiperos</h4>
    <filtros-usuarios v-model="busqueda" ref="busqueda" :mostrar-rol="false" @input="actualizar" />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="membresia_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />

    <b-modal scrollable ref="usuario" title="Actualizar contraseña">
      <form @submit.prevent="actualizarContrasena">
        <input autocomplete="false" type="hidden" />
        <div class="d-flex justify-content-center align-items-center">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="usuario">Usuario</label>
              <input
                maxlength="80"
                autocomplete="off"
                readonly
                class="form-control"
                v-model.trim="usuario.usuario"
              />
              <div class="errores" v-if="!$v.usuario.usuario.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="contrasena_actual">Contraseña actual</label>
              <input
                type="password"
                autocomplete="off"
                maxlength="80"
                style="max-width: 500px"
                v-model.trim="usuario.contrasena"
                class="form-control"
              />
              <div class="errores" v-if="!$v.usuario.contrasena.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="nueva_contrasena">Contraseña nueva</label>
              <input
                type="password"
                autocomplete="off"
                maxlength="80"
                style="max-width: 500px"
                v-model="usuario.nuevaContrasena"
                class="form-control"
              />
              <div class="errores" v-if="!$v.usuario.nuevaContrasena.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="nueva_contrasena2">Confirmar contraseña nueva</label>
              <input
                type="password"
                autocomplete="off"
                maxlength="80"
                style="max-width: 500px"
                class="form-control"
                v-model="usuario.nuevaContrasena2"
              />
              <div class="errores" v-if="!$v.usuario.nuevaContrasena2.required">
                Requerido
              </div>
              <div class="errores" v-if="!$v.usuario.nuevaContrasena2.iguales">
                La contraseña nueva y la confirmación de contraseña no coinciden
              </div>
            </div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ hide }">
        <b-button
          type="submit"
          variant="primary"
          class="float-right"
          @click="actualizarContrasena()"
        >
          Actualizar contraseña
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosUsuarios from "@/components/FiltrosUsuarios.vue";
import { nextTick } from "vue";
import { exportarExcel } from "@/utils"

export default {
  name: "Membresia",
  components: { FiltrosUsuarios },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      usuario: {},
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo: "",
      busqueda: {},
      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nombre_completo",
          key: "d",
          title: "Nombre",
          align: "left",
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "left",
        },
        {
          field: "usuario",
          key: "f",
          title: "Usuario",
          align: "left",
        },
        {
          field: "contrasena",
          key: "g",
          title: "Contraseña",
          align: "left",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => this.cambiarContrasena(d.row)}
                >
                  <font-awesome-icon size="lg" icon="key" />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations: {
    usuario: {
      usuario: { required },
      contrasena: { required },
      nuevaContrasena: { required },
      nuevaContrasena2: { required, iguales: sameAs("nuevaContrasena") },
    },
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      api.listarEquiperos(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    cambiarContrasena(usuario) {
      this.usuario = usuario;
      this.$refs.usuario.show();
    },
    async actualizarContrasena() {
      this.$v.usuario.$touch();
      if (!this.$v.usuario.$invalid) {
        const datos = {
          membresia_id: this.usuario.membresia_id,
          usuario: this.usuario.usuario,
          contrasena: this.usuario.contrasena,
          nueva_contrasena: this.usuario.nuevaContrasena,
        };

        this.guardando = true;

        try {
          let respuesta = await api.actualizarContrasena(datos); //Se envían los datos al Back-end
          if (respuesta.data.respuesta) {
            this.$parent.mensaje(respuesta.data.mensaje); //El mensaje lo envían desde Back-end
            this.actualizar();
            this.$refs.usuario.hide();
          } else {
            this.$parent.error(
              "No se pudo actualizar la contraseña: " + respuesta.data.mensaje
            );
          }
        } catch (error) {
          this.$parent.error("Error de comuncación con el servidor: " + error);
        } finally {
          this.guardando = false;
        }
      }
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.busqueda = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

