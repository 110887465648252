<template>
  <div>
    <h4 class="modal-title"><font-awesome-icon icon="user" /> {{ titulo }}</h4>
    <div class="card">
      <h6 class="card-header">Datos de miembro</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <select
              v-model="membresia.diocesis_id"
              class="form-control"
              v-on:change="actualizaSectores"
            >
              <option
                v-for="dio in diocesis"
                :value="dio.diocesis_id"
                :key="dio.diocesis_id"
              >
                {{ dio.nom_diocesis }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.diocesis_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <select
              v-model="membresia.sector_id"
              class="form-control"
            >
              <option
                v-for="sector in sectores"
                :value="sector.sector_id"
                :key="sector.sector_id"
              >
                {{ sector.nom_sector }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.sector_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="membresia_id">ID</label>
            <input
              readonly="readonly"
              maxlength="40"
              v-model.trim="membresia.membresia_id"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <b-form-group label="Estatus">
              <b-form-select
                v-model="membresia.estatus_id"
                :disabled="!$parent.permisos(permisos.estatus[tipo])"
                :options="estatuses"
                value-field="estatus_id"
                text-field="nom_estatus"
              >
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <div class="errores" v-if="!$v.membresia.estatus_id.required">
              Requerido
            </div>

          </div>
          <div class="col-md-12" v-if="membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE"><strong>Usar el rol "EQUIPERO(A)" cuando el adolescente pase a joven</strong></div>
          <div class="form-group col-md-3">
            <label for="rol">Rol Principal</label>
            <select
              v-model="membresia.rol_id"
              class="form-control"
              v-on:change="listaNiveles"
            >
              <option
                v-for="rol in roles"
                :value="rol.rol_id"
                :key="rol.rol_id"
              >
                {{ rol.nom_rol }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.rol_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="rol">Nivel</label>
            <select
              v-model="membresia.nivel_id"
              class="form-control"
            >
              <option
                v-for="nivel in niveles"
                :value="nivel.nivel_id"
                :key="nivel.nivel_id"
              >
                {{ nivel.nom_nivel }}
              </option>
            </select>
            <div class="errores" v-if="!$v.membresia.nivel_id.required">
              Requerido
            </div>
          </div>          
          <div class="form-group col-md-3">
            <label for="anio_ingreso">Año de Ingreso al MFC</label>
            <input v-model.trim="membresia.anio_ingreso" class="form-control" />
            <div class="errores" v-if="!$v.membresia.anio_ingreso.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inscripcion">Inscripción</label>
            <input
              maxlength="10"
              type="text"
              v-model.trim="membresia.inscripcion"
              class="form-control"
            />
            <div class="errores" v-if="!$v.membresia.inscripcion.required">
              Requerido
            </div>
            <div class="errores" v-if="!$v.membresia.inscripcion.cantidad">
              Incorrecto
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="ofrenda">Ofrenda mensual</label>
            <input
              maxlength="10"
              type="text"
              v-model.trim="membresia.ofrenda"
              class="form-control"
            />
            <div class="errores" v-if="!$v.membresia.ofrenda.required">
              Requerido
            </div>
            <div class="errores" v-if="!$v.membresia.ofrenda.cantidad">
              Incorrecto
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Roles adicionales</h6>
      <div class="card-body">
          <div class="text-right">
            <button class="btn btn-secondary" v-on:click="agregar_rol">
              Agregar rol adicional
            </button>
          </div>
          <br />
          <table class="table table-bordered">
            <tr>
              <th>Diócesis</th>
              <th>Sector</th>
              <th>Rol</th>
              <th>Nivel</th>
              <th>Zona</th>
              <th>Equipo</th>
              <th></th>
            </tr>
            <tr
              v-for="(rol_adicional, pos) in $v.membresia.roles_adicionales.$each.$iter"
              :key="rol_adicional.$model.rol_membresia_id"
            >
              <td>
                <div v-if="rol_adicional.$model.rol_membresia_id <= 0">
                  <select                    
                    v-model="rol_adicional.$model.diocesis_id"
                    class="form-control"
                    v-on:change="actualizaSectoresRol(pos, rol_adicional.$model);"
                  >
                    <option
                      v-for="dio in diocesis"
                      :value="dio.diocesis_id"
                      :key="dio.diocesis_id"
                    >
                      {{ dio.nom_diocesis }}
                    </option>
                  </select>
                  <div class="errores" v-if="!rol_adicional.diocesis_id.required">
                    Requerido
                  </div>
                </div>
                <span v-if="rol_adicional.$model.rol_membresia_id > 0">{{  rol_adicional.$model.diocesis }}</span>
              </td>
              <td>
                <div v-if="rol_adicional.$model.rol_membresia_id <= 0">
                  <select                    
                    v-model="rol_adicional.$model.sector_id"
                    class="form-control"
                  >
                    <option
                      v-for="sector in sectores_rol[pos]"
                      :value="sector.sector_id"
                      :key="sector.sector_id"
                    >
                      {{ sector.nom_sector }}
                    </option>
                  </select>
                  <div class="errores" v-if="!rol_adicional.sector_id.required">
                    Requerido
                  </div>
                </div>
                <span v-if="rol_adicional.$model.rol_membresia_id > 0">{{  rol_adicional.$model.sector }}</span>
              </td>
              <td>
                <div v-if="rol_adicional.$model.rol_membresia_id <= 0">
                  <select
                    v-model="rol_adicional.$model.rol_id"
                    class="form-control"
                    v-on:change="actualizaNivelesRol(pos, rol_adicional.$model);"
                  >
                    <option
                      v-for="rol in rolesAdicionales"
                      :value="rol.rol_id"
                      :key="rol.rol_id"
                    >
                      {{ rol.nom_rol }}
                    </option>
                  </select>
                  <div class="errores" v-if="!rol_adicional.rol_id.required">
                    Requerido
                  </div>
                </div>
                <span v-if="rol_adicional.$model.rol_membresia_id > 0">{{  rol_adicional.$model.rol }}</span>
              </td>
              <td>
                <div v-if="rol_adicional.$model.rol_membresia_id <= 0">

                  <select
                    v-model="rol_adicional.$model.nivel_id"
                    class="form-control"
                  >
                    <option 
                      v-for="nivel in niveles_rol[pos]"
                      :value="nivel.nivel_id"
                      :key="nivel.nivel_id"
                    >
                      {{ nivel.nom_nivel }}
                    </option>
                  </select>
                  <div class="errores" v-if="!rol_adicional.nivel_id.required">
                    Requerido
                  </div>    
                </div>
                <span v-if="rol_adicional.$model.rol_membresia_id > 0">{{  rol_adicional.$model.nivel }} </span>
              </td>
              <td>
               <input 
                  maxlength="5"
                  v-model.trim="rol_adicional.$model.zona"
                  class="form-control"
                />
                <div class="errores" v-if="rol_adicional.zona == ''">Requerido</div>
              </td>
              <td>
                <input
                  maxlength="5"
                  v-model.trim="rol_adicional.$model.num_equipo"
                  class="form-control"
                />
                <div class="errores" v-if="rol_adicional.num_equipo == ''">Requerido</div>
              </td>
              <td>
                <button
                  class="btn btn-secondary"
                  title="Quitar"
                  v-on:click="quitar_rol(pos)"
                >
                  <font-awesome-icon size="lg" icon="trash" />
                </button>
              </td>
            </tr>
            <tr v-if="membresia.roles_adicionales.length == 0">
              <td colspan="6">No hay roles adicionales</td>
            </tr>
          </table>

      </div>
    </div>
    <br />
    <datos-matrimonio
      v-model="membresia.matrimonio"
      :mostrar-foto="true"
      ref="matrimonio"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_MATRIMONIO"
    />
    <datos-mare
      v-model="membresia.mare"
      :mostrar-foto="true"
      ref="mare"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_MARE"
    />
    <datos-joven
      v-model="membresia.joven"
      :mostrar-foto="true"
      ref="joven"
      v-if="
        membresia.tipo_membresia_id == Constantes.TIPO_JOVEN ||
        membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE
      "
    />
    <datos-ae
      v-model="membresia.ae"
      :mostrar-foto="true"
      ref="ae"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_AE"
    />
    <br />
    <div class="col-md-12 text-center botones-accion">
      <button
        class="btn btn-primary"
        :disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Guardar
      </button>
      <button class="btn btn-secondary" v-on:click.stop.prevent="$router.go(-1)">
        Cancelar
      </button>
    </div>
  </div>
</template>
<script>
import DatosMatrimonio from "@/components/DatosMatrimonio.vue";
import DatosJoven from "@/components/DatosJoven.vue";
import DatosMare from "@/components/DatosMare.vue";
import DatosAe from "@/components/DatosAe.vue";
import api from "@/api";
import { Constantes } from "@/api";
import { required } from "vuelidate/lib/validators";
import { cantidad } from "@/libs/validadores";

export default {
  name: "Registro",
  components: { DatosMatrimonio, DatosJoven, DatosMare, DatosAe },
  data() {
    return {
      Constantes: Constantes,
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo: '',
      diocesis: [],
      membresia: { roles_adicionales: []},
      roles: [],
      rolesAdicionales: [],
      todoNiveles: [],
      niveles: [],
      guardando: false,
      sectores: [],
      sectores_rol: {0: [], 1: [], 2: [], 3: [], 4:[]},
      niveles_rol: {0: [], 1: [], 2: [], 3: [], 4:[]},
      estatuses: [],
      permisos: {
        agregar: ['','membresia-matrimonios-agregar','membresia-jovenes-agregar', 'membresia-mares-agregar', 'membresia-ae-agregar', 'membresia-adolescentes-agregar'],
        editar: ['','membresia-matrimonios-editar','membresia-jovenes-editar', 'membresia-mares-editar', 'membresia-ae-editar', 'membresia-adolescentes-editar'],
        estatus: ['','membresia-matrimonios-estatus','membresia-jovenes-estatus', 'membresia-mares-estatus', 'membresia-ae-estatus', 'membresia-adolescentes-estatus'],
      },

    };
  },
  validations() {
    return {
      membresia: {
        anio_ingreso: { required },
        diocesis_id: { required },
        sector_id: { required },
        estatus_id: { required },
        nivel_id: { required },
        inscripcion: { required, cantidad },
        ofrenda: { required, cantidad },
        rol_id: { required },

        roles_adicionales:{
          $each: {
            diocesis_id: { required },
            sector_id: { required },
            rol_id: { required },
            nivel_id: { required },
          },
        },

      },
    };
  },
  methods: {
    agregar_rol: function () {
      this.membresia.roles_adicionales.push({ rol_membresia_id: -this.membresia.roles_adicionales.length, diocesis_id: null, rol_id: null, sector_id: null,nivel_id: null });
    },
    quitar_rol: function (pos) {
      if (this.membresia.roles_adicionales[pos].rol_membresia_id > 0) {
        this.membresia.roles_quitar.push(this.membresia.roles_adicionales[pos].rol_membresia_id);
      }
      this.membresia.roles_adicionales.splice(pos, 1);
    },    
    guardar: function () {
      this.$v.membresia.$touch();
      let valido = !this.$v.membresia.$invalid;
      if (this.membresia.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) {
        valido = valido && this.$refs.matrimonio.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_JOVEN || this.membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE) {
        valido = valido && this.$refs.joven.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_MARE) {
        valido = valido && this.$refs.mare.valido();
      } else if(this.membresia.tipo_membresia_id == Constantes.TIPO_AE) {
        valido = valido && this.$refs.ae.valido();
      }
      if (valido) {
        if (this.guardando) {
          return;
        }
        this.guardando = true;

        api
          .guardarMembresia(this.membresia)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.$parent.mensaje(
                "Información guardada correctamente");
                this.$router.go(-1)
            } else {
              this.$parent.error(
                "Error al guardar la información: " +
                  (datos != null ? datos.mensaje : "error interno")
              );
            }
            this.guardando = false;
          })
          .catch((error) => {
            this.guardando = false;
            this.$parent.error("Error al guardar la información: " + error);
          });
      } else {
        this.$parent.error(
          "Faltan datos por registrar o existen datos incorrectos"
        );
      }
    },
    
    nuevo: function (tipo) {

      this.membresia = {
        titulo: '',
        tipo_membresia_id: tipo, 
        membresia_id: 0,
        roles_adicionales: [],
        matrimonio: { hijos: [] },
        mare: { hijos: [] },
        joven: {},
        ae: {},
      };
    },


    listaEstatuses: function () {
      api.listarEstatusMembresia().then((r) => {
        this.estatuses = r.data;
      });
    },
    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles: function () {
      api.listarRoles(this.membresia.tipo_membresia_id).then((r) => {
        this.roles = r.data;
        this.rolesAdicionales = this.roles.filter(r => !Constantes.EQUIPEROS.includes(r.rol_id));
      });
    },
    listaTodosNiveles: function () {
      api.listarNiveles().then((r) => {
        this.todosNiveles = r.data;
        this.listaNiveles()
      });
    },

    listaNiveles: function () {
      let niveles = []
      if (Constantes.PROMOTORES_ADOLESCENTES.includes(this.membresia.rol_id)) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE);
      } else if(this.membresia.rol_id == 985) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_JOVEN);
      } else {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == this.membresia.tipo_membresia_id);
      }

      if (niveles.filter(n => n.nivel_id == this.membresia.nivel_id).length == 0) {
        this.membresia.nivel_id = null;
      }
      this.niveles = niveles;

    },
    actualizaNivelesRol: function (pos, rol_adicional) {
      rol_adicional.nivel_id = "";
      this.niveles_rol[pos] = [];

      let niveles = []
      if (Constantes.PROMOTORES_ADOLESCENTES.includes(rol_adicional.rol_id)) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE);
      } else if(this.membresia.rol_id == 985) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_JOVEN);
      } else {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == this.membresia.tipo_membresia_id);
      }

      if (niveles.filter(n => n.nivel_id == this.membresia.nivel_id).length == 0) {
        rol_adicional.nivel_id = null;
      }
      this.niveles_rol[pos] = niveles;

    },
    actualizaSectores: function () {
      var id = this.membresia.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },
    actualizaSectoresRol: function (pos, rol_adicional) {
      rol_adicional.sector_id = "";
      var id = rol_adicional.diocesis_id;
      this.sectores_rol[pos] = [];
      api.listarSectores(id).then((r) => {
        this.sectores_rol[pos] = r.data;
      });
    },    
    obtenerMembresia: function (id) {
      api.obtenerMembresia(id).then((r) => {
        this.membresia = r.data.membresia;
        this.actualizaSectores();
        this.listaRoles();   
        this.listaTodosNiveles();   
        this.membresia.roles_quitar = [];
        this.titulo = "Edición de miembro de "+Constantes.TIPOS_MEMBRESIA[this.membresia.tipo_membresia_id];
        this.tipo = this.membresia.tipo_membresia_id;
      });
    },
  },
  computed: {},
  mounted: function () {
    const tipo_membresia = parseInt(this.$route.params.tipo) ?? 0;
    this.nuevo(tipo_membresia);
    if (this.$route.params.id) {
      this.obtenerMembresia(this.$route.params.id);
    } else {
      this.tipo = Number.parseInt(this.$route.params.tipo);
      this.titulo = "Agregar miembro de "+Constantes.TIPOS_MEMBRESIA[tipo_membresia];
      this.listaRoles();   
      this.listaTodosNiveles();   
      this.membresia.roles_quitar = [];
    }
    this.listaDiocesis();
    this.listaEstatuses();
  },
};
</script>
<style scoped>
</style>
