<template>
  <div>
    <h4>Administración de diócesis</h4>
    <b-card header="Filtros">
      <form @submit.prevent="actualizarDiocesis">
        <b-form-row>
          <b-col md="4">
            <b-form-group label="Región">
              <b-form-select
                v-model="busqueda.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
      
        </b-form-row>
        <b-button @click="limpiarBusqueda"
          ><font-awesome-icon icon="eraser" /> Limpiar</b-button
        >&nbsp;
        <b-button type="submit"
          ><font-awesome-icon icon="search" /> Buscar</b-button
        >
      </form>
    </b-card>
    <div class="acciones">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <ve-table
      rowKeyFieldName="diocesis_id"
      :columns="columnas"
      :table-data="listadoDiocesis"
      :border-y="true"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
    <b-modal ref="detalle" scrollable v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos de la diócesis</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-12">
          <label>Clave</label>
          <input
            maxlength="3"
            style="max-width: 500px"
            v-model.trim="diocesis.clavedio"
            class="form-control"
            :class="estatus($v.diocesis.clavedio)"
          />
          <div class="errores" v-if="!$v.diocesis.clavedio.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <label>Nombre</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="diocesis.nom_diocesis"
            class="form-control"
            :class="estatus($v.diocesis.nom_diocesis)"
          />
          <div class="errores" v-if="!$v.diocesis.nom_diocesis.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <label>Región</label>
          <b-form-select
                v-model="diocesis.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
                :class="estatus($v.diocesis.region_id)"
              >
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </b-form-select>
          <div class="errores" v-if="!$v.diocesis.region_id.required">
            Requerido
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar diocesis",
      listadoDiocesis: [],
      listadoRegiones: [],
      listadoSiNo: [
        { valor: true, texto: "Sí" },
        { valor: false, texto: "No" },
      ],
      diocesis: {},
      busqueda: {},
      columnas: [
        {
          field: "clavedio",
          key: "a",
          title: "Clave",
          align: "left",
        },
        {
          field: "nom_diocesis",
          key: "b",
          title: "Nombre",
          align: "left",
        },
        {
          renderBodyCell: (d) => d.row.region.nom_region,
          key: "c",
          title: "Región",
          align: "left",
        },
        {
          renderBodyCell: (d) => (
            <span>
              <a href="javascript:;" onClick={() => this.editar(d.row)}>
                <font-awesome-icon size="lg" icon="edit" />
              </a>
              <a href="javascript:;" onClick={() => this.eliminar(d.row)}>
                <font-awesome-icon size="lg" icon="trash" />
              </a>
            </span>
          ),
          key: "f",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      diocesis: {
        clavedio: {
          required,
        },        
        nom_diocesis: {
          required,
        },
        region_id: {
          required,
        },
        activo: {
          required: false,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar diócesis";
      this.diocesis = {region_id: null};
      this.$refs.detalle.show();
    },
    editar(elemento) {
      (this.titulo = "Editar diócesis"),
        (this.diocesis = Object.assign({}, elemento));
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar la diócesis: " + elemento.nom_diocesis)
        .then(() => {
          api
            .eliminarDiocesis(elemento.diocesis_id)
            .then((resp) => {
              let r = resp.data;
              if (r.respuesta) {
                this.actualizarDiocesis();
              } else {
                this.$parent.aviso(r.mensaje);
              }
            })
            .catch((error) => {
              this.$parent.aviso("No se pudo eliminar " + error);
            });
        });
    },
    guardar() {
      this.$v.diocesis.$touch();
      if (!this.$v.diocesis.$invalid) {
        api
          .guardarDiocesis(this.diocesis)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarDiocesis();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    limpiarBusqueda() {
      this.busqueda = {
        region_id: null,
      }
    },

    actualizarDiocesis() {
      api.listarDiocesisDetalle(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
        } else {
          this.listadoDiocesis = [];
        }
      });
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.limpiarBusqueda();
    this.actualizarRegiones();
    this.actualizarDiocesis();
  },
};
</script>

