<template>
  <div>
    <h4>Actas de región</h4>
    <filtros-actas-region
      v-model="busqueda"
      ref="busqueda"
      @input="actualizar"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoActas.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoActas.length == 0">Exportar Excel</b-button>
        <b-button :to="`/actas_region/agregar/`" v-show="$parent.permisos(permisos.agregar)">Agregar</b-button>
      </div>
    </div>
    <ve-table 
      rowKeyFieldName="actar_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoActas"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosActasRegion from "@/components/FiltrosActasRegion.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "ActasRegion",
  components: { FiltrosActasRegion },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoActas: [],
      permisos: {
        agregar: 'actas-region-agregar',
        editar: 'actas-region-editar',
        consultar: 'actas-region-consultar',
      },
      busqueda: {},
      columnas: [
        {
          field: "region",
          key: "region",
          title: "Región",
          align: "left",
        },
        {
          field: "ciclo",
          key: "ciclo",
          title: "Ciclo",
          align: "left",
        },
        {
          field: "nom_mes",
          key: "nom_mes",
          title: "Mes",
          align: "left",
        },  
        {
          field: "fecha_registro",
          key: "fecha_registro",
          title: "Fecha de registro",
          align: "left",
        },
        {
          field: "fecha",
          key: "fecha",
          title: "Fecha de resumen",
          align: "left",
        },
        {
          field: "fecha_envio_a4n",
          key: "fecha_envio_a4n",
          title: "Visible desde",
          align: "left",
        },      
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <router-link to={"/actas_region/editar/" + d.row.actar_id} v-show={this.$parent.permisos(this.permisos.editar)}>
                  <font-awesome-icon size="lg" icon="edit"   />
                </router-link>
                <router-link to={"/actas_region/consultar/" + d.row.actar_id} v-show={this.$parent.permisos(this.permisos.consultar)}>
                  <font-awesome-icon size="lg" icon="file-alt"   />
                </router-link>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoActas);
    },
    actualizar() {
      api.listarActasRegion(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoActas = r.data;
        } else {
          this.listadoActas = [];
        }
      });
    },
    guardar() {
      this.$v.sector.$touch();
      if (!this.$v.sector.$invalid) {
        api
          .guardarSector(this.sector)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarSectores();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.busqueda = {};
        this.listadoActas = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

