<template>
  <b-card header="Filtros">
    <b-form-row>
      <b-col md="4">
        <b-form-group label="Región">
          <b-form-select
            v-model="busqueda.region_id"
            :options="listadoRegiones"
            value-field="region_id"
            text-field="nom_region"
            v-on:change="filtrarDiocesis"
          >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Diócesis">
          <b-form-select
            v-model="busqueda.diocesis_id"
            :options="listadoDiocesisFiltro"
            value-field="diocesis_id"
            text-field="nom_diocesis"
            v-on:change="actualizarSectores"
          >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Sector">
          <b-form-select
            v-model="busqueda.sector_id"
            :options="listadoSectores"
            value-field="sector_id"
            text-field="nom_sector"
            @change="buscar"
          >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Tipo de miembro">
          <b-form-select
            v-model="busqueda.tipo_membresia_id"
            :options="listadoTiposMembresia"
            value-field="tipo_membresia_id"
            text-field="nom_tipo_membresia"
            @change="filtrarRolNivel"
          >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4" v-if="mostrarRol">
        <b-form-group label="Rol">
          <b-form-select v-model="busqueda.rol_id" @change="filtrarRolNivel">
            <b-form-select-option
              v-for="rol in listadoRolesFiltro"
              :key="rol.rol_id"
              :value="rol.rol_id"
              >{{
                rol.nom_rol +
                " [" +
                Constantes.TIPOS_MEMBRESIA[
                  rol.tipo_membresia_id
                ].toUpperCase() +
                "]"
              }}</b-form-select-option
            >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Nivel">
          <b-form-select v-model="busqueda.nivel_id" @change="buscar">
            <b-form-select-option
              v-for="nivel in listadoNivelesFiltro"
              :key="nivel.nivel_id"
              :value="nivel.nivel_id"
              >{{
                nivel.nom_nivel +
                " [" +
                Constantes.TIPOS_MEMBRESIA[
                  nivel.tipo_membresia_id
                ].toUpperCase() +
                "]"
              }}</b-form-select-option
            >
            <b-form-select-option :value="null">Todos</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <hr />
    <form @submit.prevent="buscarMembresia">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="busqueda_por">Buscar miembros por</label>
          <div class="input-select-container d-flex">
            <select
              class="form-control custom-width"
              @change="limpiaIdNombre"
              v-model="busquedaMembresia.tipo"
            >
              <option value="buscar_id">ID</option>
              <option value="buscar_nombre">Nombre o Apellido</option>
            </select>
            <input
              v-if="busquedaMembresia.tipo == 'buscar_id'"
              maxlength="10"
              class="form-control custom-width"
              v-model="busquedaMembresia.id"
            />
            <input
              v-if="busquedaMembresia.tipo == 'buscar_nombre'"
              maxlength="100"
              class="form-control custom-width"
              v-model="busquedaMembresia.nombre"
            />
          </div>
          &nbsp;
          <div class="button-container">
            <b-button
              type="submit"
              :disabled="$v.busquedaMembresia.$invalid"
              :title="
                busquedaMembresia.tipo === 'buscar_id'
                  ? 'Teclea un valor numérico'
                  : 'Teclea un valor alfabético'
              "
            >
              <font-awesome-icon icon="search" /> Buscar
            </b-button>
            &nbsp;
            <b-button title="Limpiar filtros" @click="limpiarBusqueda()">
              <font-awesome-icon icon="eraser" /> Limpiar
            </b-button>
          </div>
        </div>
      </div>
    </form>
  </b-card>
</template>
<script >
import api from "@/api";
import { requiredIf } from "vuelidate/lib/validators";
import { numeros, letras } from "@/libs/validadores";
import { Constantes } from "@/api";
export default {
  name: "FiltrosMembresia",
  props: {
    value: {
      type: Object,
      required: true,
    },
    mostrarRol: {
      type: Boolean,
      default: true,
    },
    mostrarEstado: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Constantes: Constantes,
      busqueda: this.value,
      busquedaMembresia: {},
      listadoRegiones: [],
      listadoDiocesis: [],
      listadoTiposMembresia: [],
      listadoDiocesisFiltro: [],
      listadoSectores: [],
      listadoRoles: [],
      listadoRolesFiltro: [],
      listadoNiveles: [],
      listadoNivelesFiltro: [],
    };
  },

  validations() {
    return {
      busquedaMembresia: {
        id: { numeros, required: requiredIf((m) => m.tipo == "buscar_id") },
        nombre: {
          letras,
          required: requiredIf((m) => m.tipo == "buscar_nombre"),
        },
      },
    };
  },
  methods: {
    limpiaIdNombre() {
      if (this.busquedaMembresia.tipo == "buscar_id") {
        this.busquedaMembresia.nombre = null;
      } else {
        this.busquedaMembresia.id = null;
      }
    },
    onInputChange() {
      // Este espacio es cuando se quiera hacer una acción conforme se teclea en el "input"
      this.showLabel = false;
    },
    buscarMembresia() {
      let busqueda = { ...this.busqueda };
      if (this.busquedaMembresia.tipo == "buscar_id") {
        busqueda.id = this.busquedaMembresia.id;
      } else {
        busqueda.nombre = this.busquedaMembresia.nombre;
      }
      this.$emit("input", busqueda);
    },

    buscar() {
      this.$emit("input", { ...this.busqueda });
    },
    limpiarBusqueda() {
      this.busqueda = {
        region_id: null,
        diocesis_id: null,
        sector_id: null,
        rol_id: null,
        nivel_id: null,
        tipo_membresia_id: null,
      };
      this.busquedaMembresia = {
        nombre: null,
        id: null,
        tipo: "buscar_id",
      };
      this.$emit("input", { ...this.busqueda });
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
      });
    },
    actualizarDiocesis() {
      api.listarDiocesis().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
          this.listadoDiocesisFiltro = r.data;
        } else {
          this.listadoDiocesis = [];
        }
      });
    },
    filtrarDiocesis() {
      let existe = false;
      this.listadoDiocesisFiltro = this.listadoDiocesis.filter((d) => {
        const agregar =
          this.busqueda.region_id == null ||
          this.busqueda.region_id == d.region_id;
        if (agregar) {
          if (d.diocesis_id == this.busqueda.diocesis_id) {
            existe = true;
          }
        }
        return agregar;
      });
      if (!existe) {
        this.busqueda.diocesis_id = null;
        this.actualizarSectores();
      }
      this.buscar();
    },
    filtrarRolNivel() {
      const tipo_membresia_id = this.busqueda.tipo_membresia_id;
      let existe = false;
      this.listadoRolesFiltro = this.listadoRoles.filter((r) => {
        const agregar = tipo_membresia_id == null || tipo_membresia_id == r.tipo_membresia_id;
        if (agregar) {
          if (r.tipo_membresia_id == tipo_membresia_id) {
            existe = true;
          }
        }
        return agregar;
      })
      if (!existe) {
        this.busqueda.rol_id = null;
      }
      existe = false;
      this.listadoNivelesFiltro = this.listadoNiveles.filter((n) => {
        const agregar = tipo_membresia_id == null || tipo_membresia_id == n.tipo_membresia_id;
        if (agregar) {
          if (n.tipo_membresia_id == tipo_membresia_id) {
            existe = true;
          }
        }
        return agregar;
      })
      if (!existe) {
        this.busqueda.nivel_id = null;
      }
      this.buscar();
    },
    actualizarSectores() {
      this.busqueda.sector_id = null;
      if (this.busqueda.diocesis_id != null) {
        api.listarSectoresDiocesis(this.busqueda.diocesis_id).then((r) => {
          if (Array.isArray(r.data)) {
            this.listadoSectores = r.data;
          } else {
            this.listadoSectores = [];
          }
        });
      } else {
        this.listadoSectores = [];
      }
      this.buscar();
    },
    actualizarTiposMembresia() {
      api.listarTipoMembresias().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoTiposMembresia = r.data;
        } else {
          this.listadoTiposMembresia = [];
        }
      });
    },
    actualizarRoles() {
      api.listarRoles().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRoles = r.data;
        } else {
          this.listadoRoles = [];
        }
        this.listadoRolesFiltro = this.listadoRoles;
      });
    },
    actualizarNiveles() {
      api.listarNiveles().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoNiveles = r.data;
        } else {
          this.listadoNiveles = [];
        }
        this.listadoNivelesFiltro = this.listadoNiveles;
      });
    },
    actualizarEstadosMembresia() {
      api.listarEstatusMembresia().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoEstatusMembresia = r.data;
        } else {
          this.listadoEstatusMembresia = [];
        }
      });
    },
    inicializa() {
      this.limpiarBusqueda();

      this.actualizarRegiones();
      this.actualizarDiocesis();
      this.actualizarRoles();
      this.actualizarNiveles();
      this.actualizarTiposMembresia();
    },
  },
  mounted() {
    this.inicializa();
  },
};
</script>

<style>
.input-select-container {
  display: flex; /* Hace que los elementos hijos se muestren en línea */
}

.input-select-container select.custom-width,
.input-select-container input.custom-width {
  width: 250px; /* Establecer un ancho específico */
  margin-right: 10px; /* Espacio a la derecha entre los elementos del contenedor */
}

.button-container {
  /* Estilos para el contenedor del botón */
  display: flex;
  align-items: flex-end; /* Alinear el botón en la parte inferior */
}
</style>