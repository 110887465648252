<template>
  <div>
    <h4>Administración de sectores</h4>
    <b-card header="Filtros">
      <form @submit.prevent="actualizarSectores">
        <b-form-row>
          <b-col md="4">
            <b-form-group label="Diócesis">
              <b-form-select
                v-model="busqueda.diocesis_id"
                :options="listadoDiocesis"
                value-field="diocesis_id"
                text-field="nom_diocesis"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Activo">
              <b-form-select
                v-model="busqueda.activo"
                :options="listadoSiNo"
                value-field="valor"
                text-field="texto"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Presector">
              <b-form-select
                v-model="busqueda.es_presector"
                :options="listadoSiNo"
                value-field="valor"
                text-field="texto"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Nacional Diocesano">
              <b-form-select
                v-model="busqueda.es_diocesano_nacional"
                :options="listadoSiNo"
                value-field="valor"
                text-field="texto"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-button @click="limpiarBusqueda"
          ><font-awesome-icon icon="eraser" /> Limpiar</b-button
        >&nbsp;
        <b-button type="submit"
          ><font-awesome-icon icon="search" /> Buscar</b-button
        >
      </form>
    </b-card>
    <div class="acciones">
      <b-button @click="agregar">Agregar</b-button>
    </div>
    <ve-table
      rowKeyFieldName="sector_id"
      :columns="columnas"
      :table-data="listado"
      :border-y="true"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
    <b-modal ref="detalle" scrollable v-bind:title="titulo">
      <p class="text-justify">
        <b>Proporciona los datos del sector</b>
      </p>
      <div class="form-row grupo">
        <div class="form-group col-12">
          <label>Nombre</label>
          <input
            maxlength="100"
            style="max-width: 500px"
            v-model.trim="sector.nom_sector"
            class="form-control"
            :class="estatus($v.sector.nom_sector)"
          />
          <div class="errores" v-if="!$v.sector.nom_sector.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <label>Diócesis</label>
          <b-form-select
                v-model="sector.diocesis_id"
                :options="listadoDiocesis"
                value-field="diocesis_id"
                text-field="nom_diocesis"
                :class="estatus($v.sector.diocesis_id)"
              >
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </b-form-select>
          <div class="errores" v-if="!$v.sector.diocesis_id.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox"
              id="es_presector"
              v-model.trim="sector.es_presector"
              class="form-check-input"
              :class="estatus($v.sector.es_presector)"
            />
            <label class="form-check-label" for="es_presector">Es presector</label>
          </div>
          <div class="errores" v-if="!$v.sector.es_presector.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input
              type="checkbox" id="es_dn"
              v-model.trim="sector.es_diocesano_nacional"
              class="form-check-input"
              :class="estatus($v.sector.es_diocesano_nacional)"
            />
            <label class="form-check-label" for="es_dn">Es Diocesano o Nacional</label>
          </div>
          <div class="errores" v-if="!$v.sector.es_diocesano_nacional.required">
            Requerido
          </div>
        </div>
        <div class="form-group col-12">
          
          <div class="form-check">
            <input id="es_activo"
              type="checkbox"
              v-model.trim="sector.activo"
              class="form-check-input"
              :class="estatus($v.sector.activo)"
            />
            <label class="form-check-label" for="es_activo">Activo</label>
          </div>
          <div class="errores" v-if="!$v.sector.activo.required">Requerido</div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar sector",
      listadoDiocesis: [],
      listadoSiNo: [
        { valor: true, texto: "Sí" },
        { valor: false, texto: "No" },
      ],
      listado: [],
      sector: {},
      diocesis: [],
      busqueda: { },
      columnas: [
        {
          field: "nom_sector",
          key: "a",
          title: "Nombre",
          align: "left",
        },
        {
          renderBodyCell: (d) => d.row.diocesis.nom_diocesis,
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          renderBodyCell: (d) =>
            d.row.es_presector ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "c",
          title: "Presector",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.es_diocesano_nacional ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "d",
          title: "Nacional/Diocesano",
          align: "center",
        },
        {
          renderBodyCell: (d) =>
            d.row.activo ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "e",
          title: "Activo",
          align: "center",
        },
        {
          renderBodyCell: (d) => (
            <span>
              <a href="javascript:;" onClick={() => this.editar(d.row)}>
                <font-awesome-icon size="lg" icon="edit" />
              </a>
              <a href="javascript:;" onClick={() => this.eliminar(d.row)}>
                <font-awesome-icon size="lg" icon="trash" />
              </a>
            </span>
          ),
          key: "f",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      sector: {
        nom_sector: {
          required,
        },
        diocesis_id: {
          required,
        },
        es_diocesano_nacional: {
          required: false,
        },
        es_presector: {
          required: false,
        },
        activo: {
          required: false,
        },
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar sector";
      this.sector = {diocesis_id: null};
      this.$refs.detalle.show();
    },
    editar(elemento) {
      (this.titulo = "Editar sector"),
        (this.sector = Object.assign({}, elemento));
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar el sector: " + elemento.nom_sector)
        .then(() => {
          api
            .eliminarSector(elemento.sector_id)
            .then((resp) => {
              let r = resp.data;
              if (r.respuesta) {
                this.actualizarSectores();
              } else {
                this.$parent.aviso(r.mensaje);
              }
            })
            .catch((error) => {
              this.$parent.aviso("No se pudo eliminar " + error);
            });
        });
    },
    guardar() {
      this.$v.sector.$touch();
      if (!this.$v.sector.$invalid) {
        api
          .guardarSector(this.sector)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarSectores();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    limpiarBusqueda() {
      this.busqueda = {
        diocesis_id: null,
        activo: null,
        es_presector: null,
        es_diocesano_nacional: null,  };    
    },
    actualizarSectores() {
      api.listarSectoresDetalle(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listado = r.data;
        } else {
          this.listado = [];
        }
      });
    },
    actualizarDiocesis() {
      api.listarDiocesis().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoDiocesis = r.data;
        } else {
          this.listadoDiocesis = [];
        }
      });
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.limpiarBusqueda();
    this.actualizarSectores();
    this.actualizarDiocesis();
  },
};
</script>

