<template>
  <div>
    <h4>Directorio de Oficios y Profesiones de Matrimonios</h4>
    <filtros-membresia
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      @input="actualizar"
      :mostrar-estado="false"
      :mostrar-rol="false"
      :mostrar-zona="true"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
        <b-button v-if="this.busqueda.sector_id > 0 && $parent.permisos(permisos.editar[tipo])" @click="guardar">Guardar</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="membresia_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "DirectorioServicios",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      busqueda: {},
      cambios: {},
      permisos: {
        editar: ['','matrimonios-equipo-editar','jovenes-equipo-editar', 'mares-equipo-editar', 'ae-equipo-editar', 'adolescentes-equipo-editar'],
      },

      columnas: [
        {
          field: "region",
          key: "reg",
          title: "Región",
          align: "left",
          width: 140,
        },
        {
          field: "diocesis",
          key: "dio",
          title: "Diócesis",
          align: "left",
          width: 140,
        },
        {
          field: "sector",
          key: "sec",
          title: "Sector",
          align: "left",
          width: 160,
        },
        {
          field: "membresia_id",
          key: "id",
          title: "RID",
          align: "left",
          width: 190,
        },
        {
          field: "nombre_completo",
          key: "nombres",
          title: "Nombres",
          align: "left",
          width: 190,
        },
        {
          field: "ocupacion_el",
          key: "ocu_el",
          title: "Ocupación él",
          align: "left",
          width: 190,
        },
        {
          field: "ocupacion_ella",
          key: "ocu_ella",
          title: "Ocupación ella",
          align: "left",
          width: 190,
        },
        {
          field: "celulares",
          key: "cel",
          title: "Celulares",
          align: "left",
          width: 190,
        },
        {
          field: "correos",
          key: "email",
          title: "Correos",
          align: "left",
          width: 190,
        },
        {
          field: "tel_casa",
          key: "casa",
          title: "Teléfono casa",
          align: "left",
          width: 190,
        },
    ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      this.cambios = {},
      api.listarDirServicios(this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    cellDataChange(row) {
      this.cambios[row.key] = row;

    },
    
    guardar() {
      if (Object.keys(this.cambios).length > 0) {
        const datos = Object.entries(this.cambios).map(([, v]) => { return {membresia_id: v.membresia_id, rol_membresia_id: v.rol_membresia_id, num_equipo: v.num_equipo, zona: v.zona }});
        api
          .guardarEquipos(datos)
          .then((resp) => {
            this.$parent.mensaje(resp.data.mensaje);
            this.cambios = {};
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("No se han realizado cambios");
      }
    },

  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.cambios = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

