
<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="nombre">Nombre(s)</label>
                <input
                  maxlength="50"
                  v-model.trim="ae.nombre"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.nombre.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.ae.nombre.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <input
                  maxlength="50"
                  v-model.trim="ae.apellidop"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.apellidop.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.ae.apellidop.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <input
                  maxlength="50"
                  v-model.trim="ae.apellidom"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.apellidom.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.ae.apellidom.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="sexo">Sexo</label>
                <select v-model="ae.sexo" class="form-control">
                  <option value="H">Hombre</option>
                  <option value="M">Mujer</option>
                </select>
                <div class="errores" v-if="!$v.ae.sexo.required">Requerido</div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia</label>
                <input
                  maxlength="60"
                  v-model.trim="ae.parroquia"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.parroquia.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="ae.fecha_nac"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.fecha_nac.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ordenacion">Fecha de ordenación</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="ae.ordenacion"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.ordenacion.required">
                  Requerido
                </div>
              </div>
              <!--          <div class="form-group col-md-3">
            <label for="grado_estudio_id">Grado de estudios</label>
            <select v-model="ae.grado_estudio_id" class="form-control">
              <option
                v-for="grado in gradoestudios"
                :value="grado.grado_estudio_id"
                :key="grado.grado_estudio_id"
              >
                {{ grado.nom_grado }}
              </option>
            </select>
            <div
              class="errores"
              v-if="!$v.ae.grado_estudio_id.required"
            >
              Requerido
            </div>
          </div> -->
              <div class="form-group col-md-3">
                <label for="titulo_eclesiastico">Título eclesiástico</label>
                <input
                  maxlength="50"
                  v-model.trim="ae.titulo_eclesiastico"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.titulo_eclesiastico.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.ae.titulo_eclesiastico.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>
                <input
                  maxlength="10"
                  type="text"
                  v-model.trim="ae.celular"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.celular.telefono">
                  Ingrese solo números (10 dígitos)
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="correo">Correo electrónico</label>
                <input
                  maxlength="100"
                  type="email"
                  v-model.trim="ae.correo"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.ae.correo.correo">
                  Ingrese una dirección de correo válido
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <archivo-imagen
              v-if="mostrarFoto"
              style="width: 180px; height: 180px"
              ref="foto"
              v-model="ae.foto"
            ></archivo-imagen>
          </div>            
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <input
              maxlength="50"
              v-model.trim="ae.calle"
              class="form-control"
            />
            <div class="errores" v-if="!$v.ae.calle.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <select
              v-model="ae.estado_id"
              v-on:change="actualizaMunicipios"
              class="form-control"
            >
              <option
                v-for="estado in estados"
                :value="estado.estado_id"
                :key="estado.estado_id"
              >
                {{ estado.nom_estado }}
              </option>
            </select>
            <div class="errores" v-if="!$v.ae.estado_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <select
              v-model="ae.municipio_id"
              v-on:change="actualizaLocalidades"
              class="form-control"
            >
              <option
                v-for="municipio in municipios"
                :value="municipio.municipio_id"
                :key="municipio.municipio_id"
              >
                {{ municipio.nom_municipio }}
              </option>
            </select>
            <div class="errores" v-if="!$v.ae.municipio_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <select
              v-model="ae.localidad_id"
              v-on:change="actualizaCp"
              class="form-control"
            >
              <option
                v-for="localidad in localidades"
                :value="localidad.localidad_id"
                :key="localidad.localidad_id"
              >
                {{ localidad.nom_localidad }} - {{ localidad.tipo_localidad }}
              </option>
            </select>
            <div class="errores" v-if="!$v.ae.localidad_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <input
              maxlength="4"
              type="number"
              v-model="ae.codigo_postal"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <input
              maxlength="10"
              type="text"
              v-model="ae.telefono_domicilio"
              class="form-control"
            />
            <div class="errores" v-if="!$v.ae.telefono_domicilio.telefono">
              Ingrese solo números (número con Lada)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import { required } from "vuelidate/lib/validators";
import { letras, telefono, correo } from "@/libs/validadores";
export default {
  name: "DatosAe",
  components: { ArchivoImagen },    
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      estados: [],
      municipios: [],
      localidades: [],
      ae: {},
      estado: 1,
      fase: 1,
      hijos: [],
      gradoestudios: [],
    };
  },
  validations() {
    return {
      ae: {
        nombre: { required, letras },
        apellidop: { required, letras },
        apellidom: { required, letras },
        parroquia: { letras },
        fecha_nac: { required },
        sexo: { required },
        ordenacion: { required },
        titulo_eclesiastico: { required, letras },
        celular: { telefono },
        correo: { correo },
        telefono_domicilio: { telefono },
        calle: { required },
        estado_id: { required },
        municipio_id: { required },
        localidad_id: { required },
        codigo_postal: { required },
      },
    };
  },
  methods: {
    valido: function () {
      this.$v.ae.$touch();
      return !this.$v.ae.$invalid;
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.ae.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.ae.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.ae.localidad_id;
      let ae = this.ae;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          ae.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaAE: function () {
      this.ae.estado_id != null && this.actualizaMunicipios();
      this.ae.municipio_id != null && this.actualizaLocalidades();
      this.ae.localidad_id != null && this.actualizaCp();
    },
  },
  computed: {},
  mounted: function () {
    this.ae = this.value;
    this.inicializaAE();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
