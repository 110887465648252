<template>
  <div>
    <h4>Funciones del sistema</h4>
    <ul>
      <tree-funciones
        class="item"
        :item="organigramas"
        @add-nodo="addNodo"
        @edit-files="editFiles"
        @edit-nodo="editNodo"
        @del-nodo="delNodo"
        @exp-nodo="expNodo"
      ></tree-funciones>
    </ul>

    <b-modal
      size="lg"
      ref="organigrama"
      v-bind:title="
        organigrama.funcion_sistema_id ? 'Editar función' : 'Agregar función'
      "
      centered
      hide-backdrop
    >
      <div class="form-group row">
        <label class="col-md-5 col-form-label">Tipo</label>
        <div class="col-md-7">
          <select
            v-model="organigrama.tipo"
            :class="estatus($v.organigrama.tipo)"
            class="form-control"
          >
            <option value="pagina">Página</option>
            <option value="opcion">Opción</option>
          </select>
          <div class="errores" v-if="!$v.organigrama.tipo.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-5 col-form-label">Nombre: </label>
        <div class="col-md-7">
          <input
            class="form-control"
            type="text"
            v-model="organigrama.nom_funcion"
            :class="estatus($v.organigrama.nom_funcion)"
          />
          <div class="errores" v-if="!$v.organigrama.nom_funcion.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-5 col-form-label">Clave: </label>
        <div class="col-md-7">
          <input
            class="form-control"
            type="text"
            v-model="organigrama.clave"
            :class="estatus($v.organigrama.clave)"
          />
          <div class="errores" v-if="!$v.organigrama.clave.required">
            Requerido
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-md-12 col-form-label">Roles asignados:</label>
        <div class="col-md-12" >
          <div class="form-control" style="height: 200px; overflow-y: auto">
            <div v-for="rol in roles" :key="rol.rol_id">
              <input :id="`r${rol.rol_id}`" v-model="rol.seleccionado" type="checkbox" /> <label :for="`r${rol.rol_id}`">{{ rol.nom_rol }}</label>
            </div>
          </div>
        </div>
      </div>
      <br />
      <template #modal-footer="{ hide }">
        <button v-on:click="guardar" class="btn btn-primary">Guardar</button>
        <b-button class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>    
<script>
import TreeFunciones from "@/components/TreeFunciones.vue";
import api from "@/api";
import { required } from "vuelidate/lib/validators";
// define the tree-item component
export default {
  components: { TreeFunciones },
  data() {
    return {
      stipo: 1,
      id: null,
      organigrama: {},
      padre: {},
      rolesOriginal: [],
      roles: [],
      organigramas: {},
      municipios: [],
      localidades: [],
      guardando: false,
    };
  },
  validations() {
    return {
      organigrama: {
        nom_funcion: {
          required,
        },
        clave: {
          required,
        },
        tipo: {
          required,
        },
      },
    };
  },
  methods: {
    guardar: function () {
      this.$v.organigrama.$touch();
      if (!this.$v.organigrama.$invalid) {
        const roles = this.roles.filter(r => r.seleccionado).map(r => r.rol_id) 
        this.organigrama.roles = roles;
        api
          .guardarFuncion(this.organigrama)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.organigrama.funcion_sistema_id = datos.funcion_sistema_id;
              this.organigrama.ruta = datos.ruta;
              if (!("children" in this.padre)) {
                this.padre.children = [];
              }
              this.padre.children.push(this.organigrama);
              this.organigrama = {};
              this.actualiza();
              //alert(datos.mensaje);
              this.$refs.organigrama.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch((error) => {
            alert("Error al guardar la información: " + error);
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },

    addNodo: function (item) {
      this.padre = item;
      this.organigrama = {
        padre_funcion_id: item.funcion_sistema_id,
        padre_ruta: item.ruta,
      };
      this.enlaces = [];
      this.roles = this.rolesOriginal.map(r => {r.seleccionado = false; return r;});
      this.$refs.organigrama.show();
    },
    editFiles: function (item) {
      window.open(`organigrama/${item.funcion_sistema_id}`);
    },
    editNodo: function (item) {
      this.organigrama = Object.assign({}, item);
      api.listarFuncionPermisos(item.funcion_sistema_id).then(resp => {
        const activos = resp.data.map(r => r.rol_id);
        this.roles = this.rolesOriginal.map(r => {r.seleccionado = activos.includes(r.rol_id); return r;});
      })
      this.$refs.organigrama.show();
      
    },
    expNodo: function (item) {
      this.$router.push({ path: `/organigrama/${item.funcion_sistema_id}` });
    },
    regresar: function () {
      this.$router.push({ path: "/organigrama" });
    },
    delNodo: function (item) {
      this.$parent
        .confirmar("Confirma eliminar a: " + item.nom_funcion)
        .then(() => {
          api.eliminarFuncion(item.funcion_sistema_id).then((response) => {
            if (response.data.respuesta) {
              this.actualiza();
            } else {
              this.$parent.aviso(response.data.mensaje);
            }
          });
        })
        .catch((error) => {
          this.$parent.aviso("No se pudo eliminar " + error);
        });
    },

    //Metodos de actualización
    actualiza: function () {
      let querys = "";
      var id = this.$route.params.funcion_sistema_id;
      if (id > 0) {
        this.funcion_sistema_id = id;
      } else {
        this.funcion_sistema_id = null;
      }
      if (this.funcion_sistema_id != null) {
        querys = "?id=" + this.funcion_sistema_id;
      }
      api.listarFunciones(querys).then((response) => {
        if (response.data) {
          this.organigramas = response.data;
        }
      });
      api.listarRoles().then((response) => {
        this.rolesOriginal = response.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted: function () {
    this.actualiza();
  },
};
</script>
<style>
li {
  list-style: none;
  margin-left: 0px;
}

ul {
  padding-inline-start: 20px;
}
</style>
