import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import Snackbar from 'vuejs-snackbar';
import axios from 'axios';
import VueEasytable from "vue-easytable";
//import * as XLSX from 'xlsx';
import 'boxicons';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "vue-easytable/libs/theme-default/index.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "@fontsource/rubik"; 
import "@fontsource/rubik/400.css"; 
import "@fontsource/rubik/400-italic.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCropAlt, faTrash, faExclamationCircle, faFileUpload, faSearch,
  faHome, faImage, faCamera, faVideo, faEdit, faFile, faPlusSquare,
  faFilePdf, faInfoCircle, faFileAlt, faChevronDown, faAngleRight,
  faArrowAltCircleLeft, faBriefcase, faTrashAlt, faUser, faFileExport, faFileImport,
  faPlus, faFolder, faFolderOpen, faList, faClipboard, faCheckSquare, faThList, faEraser,
  faArrowCircleLeft,faAddressCard, faSave, faKey, faCoins, faCalendar, faQrcode, faTasks,
  faUsers, faUserFriends, faUserTimes, faPenSquare, faPen,
} from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'


library.add(faHome)
library.add(faBriefcase)
library.add(faCamera);
library.add(faImage);
library.add(faCropAlt);
library.add(faFileUpload);
library.add(faVideo);
library.add(faEdit);
library.add(faTrash);
library.add(faFilePdf);
library.add(faPlusSquare);
library.add(faFontAwesome)
library.add(faFile);
library.add(faInfoCircle);
library.add(faExclamationCircle);
library.add(faFileAlt);
library.add(faPlus)
library.add(faTrashAlt)
library.add(faChevronDown)
library.add(faAngleRight)
library.add(faArrowAltCircleLeft);
library.add(faFileExport)
library.add(faFileImport)
library.add(faUser)
library.add(faList)
library.add(faFolder)
library.add(faFolderOpen)
library.add(faClipboard);
library.add(faCheckSquare);
library.add(faThList);
library.add(faSearch);
library.add(faEraser);
library.add(faSave);
library.add(faArrowCircleLeft);
library.add(faAddressCard);
library.add(faKey);
library.add(faCoins);
library.add(faCalendar);
library.add(faQrcode);
library.add(faTasks);
library.add(faUsers);
library.add(faUserFriends);
library.add(faUserTimes);
library.add(faPenSquare);
library.add(faPen);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('snackbar', Snackbar);


Vue.use(BootstrapVue, {
  theme: {
    "primary": "#FFCA28",
    "secondary": "#1976D2",
    "accent": "#82B1FF",
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FB8C00"
  }
})
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueEasytable);
require('./assets/css/main.css');
axios.defaults.withCredentials = true

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
