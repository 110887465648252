import Evalua from '@/views/Evalua.vue';
import EvaluaPromotor from '@/views/EvaluaPromotor.vue';
import CBFEquiperos from '@/views/CBFEquiperos.vue';
import CBFPromotores from '@/views/CBFPromotores.vue';
import store from '@/store';

export default [
  {
    path: '/evalua/:rol',
    name: 'Evalua',
    component: Evalua
  },
  {
    path: '/evalua-promotor',
    name: 'EvaluaPromotor',
    component: EvaluaPromotor
  },
  {
    path: '/cbf/eva-equiperos/:tipo',
    name: 'ResumenEquiperos',
    component: CBFEquiperos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/eva-promotores/:tipo',
    name: 'ResumenPromotores',
    component: CBFPromotores,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
];
