<template>
  <div>
    <h4>Resumen de evaluaciones de equipos de {{ titulo }}</h4>
    <filtros-membresia
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      @input="actualizar"
      :mostrar-estado="false"
      :mostrar-rol="false"
      :mostrar-zona="true"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
        <b-button v-if="this.busqueda.sector_id > 0 && $parent.permisos(permisos.editar[tipo])" @click="guardar">Guardar</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="key"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "Equipos",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      busqueda: {},
      cambios: {},
      permisos: {
        editar: ['','matrimonios-equipo-editar','jovenes-equipo-editar', 'mares-equipo-editar', 'ae-equipo-editar', 'adolescentes-equipo-editar'],
      },

      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
          width: 90,
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
          width: 110,
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
          width: 160,
        },
        {
          key: "d",
          title: "Nombre",
          align: "left",
          width: 240,
          renderBodyCell: ({row}) => 
            row.modificado ? (
              <b>{row.nombre_completo}</b>
             ): (
              row.nombre_completo
             )
          ,
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "center",
          width: 40,
        },
        {
          renderBodyCell: (d) =>
            d.row.principal ? (
              <font-awesome-icon size="lg" icon="check-square" />
            ) : (
              ""
            ),
          key: "f",
          title: "Rol principal",
          align: "center",
          width: 50,
        },
        {
          field: "nivel",
          key: "g",
          title: "Nivel",
          align: "center",
          width: 40,
        },
        {
          key: "zona",
          title: "Zona",
          align: "center",
          field: "zona",
          width: 40,
        },
        {
          key: "f2",
          title: "Equipo",
          align: "center",
          field: "num_equipo",
          width: 40,
        },
        {
          key: "t1",
          title: "Tema 1",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['1'],
        },
        {
          key: "t2",
          title: "Tema 2",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['2'],
        },
        {
          key: "t3",
          title: "Tema 3",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['3'],
        },
        {
          key: "t4",
          title: "Tema 4",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['4'],
        },
        {
          key: "t5",
          title: "Tema 5",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['5'],
        },
        {
          key: "t6",
          title: "Tema 6",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['6'],
        },
        {
          key: "t7",
          title: "Tema 7",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['7'],
        },
        {
          key: "t8",
          title: "Tema 8",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['8'],
        },
        {
          key: "t9",
          title: "Tema 9",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['9'],
        },
        {
          key: "t10",
          title: "Tema 10",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['10'],
        },
        {
          key: "t11",
          title: "Tema 11",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['11'],
        },
        {
          key: "t12",
          title: "Tema 12",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['12'],
        },
        {
          key: "t13",
          title: "Tema 13",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['13'],
        },
        {
          key: "t14",
          title: "Tema 14",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['14'],
        },
        {
          key: "t15",
          title: "Tema 15",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['15'],
        },
        {
          key: "t16",
          title: "Tema 16",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['16'],
        },
        {
          key: "t17",
          title: "Tema 17",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['17'],
        },
        {
          key: "trg1",
          title: "RG1",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['RG1'],
        },
        {
          key: "trg2",
          title: "RG2",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['RG2'],
        },
        {
          key: "trg3",
          title: "RG3",
          align: "center",
          width: 30,
          renderBodyCell: (d) => d.row['RG3'],
        },
      ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      this.cambios = {},
      api.listarResumenCBF(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    cellDataChange(row) {
      this.cambios[row.key] = row;

    },
    
    guardar() {
      if (Object.keys(this.cambios).length > 0) {
        const datos = Object.entries(this.cambios).map(([, v]) => { return {membresia_id: v.membresia_id, rol_membresia_id: v.rol_membresia_id, num_equipo: v.num_equipo, zona: v.zona }});
        api
          .guardarEquipos(datos)
          .then((resp) => {
            this.$parent.mensaje(resp.data.mensaje);
            this.cambios = {};
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("No se han realizado cambios");
      }
    },

  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.cambios = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

