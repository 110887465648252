import { Bar, mixins } from 'vue-chartjs'
import Chart from 'chart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

Chart.scaleService.updateScaleDefaults('linear', {
  ticks: {
    min: 0
  }
});
export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: [ 'opciones'],
  mounted () {
    this.addPlugin(ChartJsPluginDataLabels);
    var opts = this.opciones;
    if (typeof opts !== 'object') {
      opts = {}
    } 
    opts['scales'] = {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
      
    },
    opts['plugins'] = {
        datalabels: {
          color: "black",
          textAlign: "center",
          anchor: 'start',
          font: {
            weight: "bold",
            size: 14,
          }
        }
    }
    opts['responsive'] = true;
    opts['maintainAspectRatio'] = false;
    opts['height'] = 300;
    this.renderChart(this.chartData, opts);
  }
}