<template>
  <div>
    <h4>Actas {{ Constantes.JERARQUIAS[jerarquia] }} {{  Constantes.TIPOS_MEMBRESIA[tipo] }}</h4>
    <filtros-eventos
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      :mostrar-tipo-membresia="false"
      @input="actualizar"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoActas.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoActas.length == 0">Exportar Excel</b-button>
        <b-button v-show="$parent.permisos(permisos[jerarquia].agregar[tipo])" :to="`/actas/agregar/${tipo}/${jerarquia}/`">Agregar</b-button>
      </div>
    </div>
    <ve-table 
      rowKeyFieldName="acta_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoActas"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosEventos from "@/components/FiltrosEventos.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "Evento",
  components: { FiltrosEventos },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoActas: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      jerarquia: Number.parseInt(this.$route.params.nivel),
      permisos: {        
        6: {
          agregar: ['','actass-matrimonios-agregar','actass-jovenes-agregar', 'actass-mares-agregar'],
          editar: ['','actass-matrimonios-editar','actass-jovenes-editar', 'actass-mares-consultar'],
          consultar: ['','actass-matrimonios-consultar','actass-jovenes-consultar', 'actass-mares-consultar'],
        },
        7: {
          agregar: ['','actasd-matrimonios-agregar','actasd-jovenes-agregar', 'actasd-mares-agregar'],
          editar: ['','actasd-matrimonios-editar','actasd-jovenes-editar', 'actasd-mares-consultar'],
          consultar: ['','actasd-matrimonios-consultar','actasd-jovenes-consultar', 'actasd-mares-consultar'],
        },
      },
      busqueda: {},
      columnas: [
        {
          field: "no",
          key: "a",
          title: "No. acta",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "tipo",
          key: "d",
          title: "Tipo",
          align: "left",
        },
        {
          field: "estado",
          key: "estado",
          title: "Estado",
          align: "left",
        },
        {
          field: "fecha",
          key: "e",
          title: "Fecha",
          align: "left",
        },
        {
                
          key: "lugar",
          title: "Lugar",
          align: "center",
          field: 'lugar',
          
        },        
        {
          field: "ciclo",
          key: "ciclo",
          title: "Ciclo",
          align: "center",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <router-link to={"/actas/editar/" + d.row.acta_id} v-show={this.$parent.permisos(this.permisos[this.jerarquia].editar[this.tipo])}>
                  <font-awesome-icon size="lg" icon="edit"   />
                </router-link>
                <router-link to={"/actas/consultar/" + d.row.acta_id} v-show={this.$parent.permisos(this.permisos[this.jerarquia].consultar[this.tipo])}>
                  <font-awesome-icon size="lg" icon="file-alt"   />
                </router-link>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoActas);
    },
    actualizar() {
      api.listarActas(this.tipo, this.jerarquia, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoActas = r.data;
        } else {
          this.listadoActas = [];
        }
      });
    },
    guardar() {
      this.$v.sector.$touch();
      if (!this.$v.sector.$invalid) {
        api
          .guardarSector(this.sector)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarSectores();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.jerarquia = Number.parseInt(this.$route.params.nivel);
        this.busqueda = {};
        this.listadoActas = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

