import CBFProximas from '@/views/CBFProximas.vue';
import CBFReuniones from '@/views/CBFReuniones.vue';
import Resumen from '@/views/Resumen.vue';
import S05 from '@/views/S05.vue';
import Ofrendas from '@/views/Ofrendas.vue';
import ReporteBaja from '@/views/ReporteBaja.vue'; 
import Equipos from '@/views/Equipos.vue';
import store from '@/store';

export default [
  {
    path: '/cbf/bajas/:tipo',
    name: 'ReporteBaja',
    component: ReporteBaja,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) { 
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/equipos/:tipo',
    name: 'Equipos',
    component: Equipos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/proximas/:tipo',
    name: 'CBFProximas',
    component: CBFProximas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/reuniones/:tipo',
    name: 'CBFReuniones',
    component: CBFReuniones,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/resumen/:tipo',
    name: 'Resumen',
    component: Resumen,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/membresia/ofrendas/:tipo',
    name: 'Ofrendas',
    component: Ofrendas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/cbf/s05/:tipo',
    name: 'S05',
    component: S05,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
];
