<!--REVISAR EL CAMPO TELEFONO DOMICILIO DEL JOVEN, tenía referencia telefono_domicilio
pero ese campo ya estaba ocupado por el celular, así que cambié la refencia a "tel_domicilio" -->
<template>
  <div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nombre">Nombre(s)</label>
                <input
                  maxlength="30"
                  v-model.trim="joven.nombre"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.nombre.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.joven.nombre.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <input
                  maxlength="30"
                  v-model.trim="joven.apellidop"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.apellidop.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.joven.apellidop.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <input
                  maxlength="30"
                  v-model.trim="joven.apellidom"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.apellidom.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.joven.apellidom.letras">
                  Ingrese solo letras
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="sexo">Sexo</label>
                <select v-model="joven.sexo" class="form-control">
                  <option value="H">Hombre</option>
                  <option value="M">Mujer</option>
                </select>
                <div class="errores" v-if="!$v.joven.sexo.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <input
                  type="date"
                  id="fechaboda"
                  v-model.trim="joven.fecha_nac"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.fecha_nac.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="pasatiempo">Pasatiempo</label>
                <input
                  maxlength="50"
                  v-model.trim="joven.pasatiempo"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.pasatiempo.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_id">Grado de estudios</label>
                <select v-model="joven.grado_estudio_id" class="form-control">
                  <option
                    v-for="grado in gradoestudios"
                    :value="grado.grado_estudio_id"
                    :key="grado.grado_estudio_id"
                  >
                    {{ grado.nom_grado }}
                  </option>
                </select>
                <div class="errores" v-if="!$v.joven.grado_estudio_id.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>
                <input
                  maxlength="10"
                  type="text"
                  v-model.trim="joven.celular"
                  class="form-control"
                />
                <div
                  class="errores"
                  v-if="!$v.joven.celular.telefono"
                >
                  Ingrese solo números (10 dígitos)
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo">Correo electrónico</label>
                <input
                  maxlength="50"
                  type="email"
                  v-model.trim="joven.correo"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.correo.correo">
                  Ingrese una dirección de correo válido
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia a la que asiste</label>
                <input
                  maxlength="60"
                  v-model.trim="joven.parroquia"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.joven.parroquia.letras">
                  Ingrese solo letras
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <archivo-imagen
              v-if="mostrarFoto"
              style="width: 180px; height: 180px"
              ref="foto"
              v-model="joven.foto"
            ></archivo-imagen>
          </div>            
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los Padres</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="nom_padre">Nombre completo del Padre o Tutor</label>
            <input
              maxlength="50"
              v-model.trim="joven.nom_padre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.nom_padre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.nom_padre.letras">
              Ingrese solo letras
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="correo_padre">Correo electrónico Padre</label>
            <input
              maxlength="50"
              v-model.trim="joven.correo_padre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.correo_padre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.correo_padre.correo">
              Ingrese una dirección de correo válido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="tel_padre">Celular Padre</label>
            <input
              maxlength="10"
              v-model.trim="joven.tel_padre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.tel_padre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.tel_padre.telefono">
              Ingrese solo números (10 dígitos)
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="nom_madre">Nombre completo de la Madre</label>
            <input
              maxlength="50"
              v-model.trim="joven.nom_madre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.nom_madre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.nom_madre.letras">
              Ingrese solo letras
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="correo_madre">Correo electrónico Madre</label>
            <input
              maxlength="50"
              v-model.trim="joven.correo_madre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.correo_madre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.correo_madre.correo">
              Ingrese una dirección de correo válido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="tel_madre">Celular Madre</label>
            <input
              maxlength="10"
              v-model.trim="joven.tel_madre"
              class="form-control"
            />
            <!--            <div class="errores" v-if="!$v.joven.tel_madre.required">
              Requerido
            </div> -->
            <div class="errores" v-if="!$v.joven.tel_madre.telefono">
              Ingrese solo números (10 dígitos)
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <input
              maxlength="50"
              v-model.trim="joven.calle"
              class="form-control"
            />
            <div class="errores" v-if="!$v.joven.calle.required">Requerido</div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <select
              v-model="joven.estado_id"
              v-on:change="actualizaMunicipios"
              class="form-control"
            >
              <option
                v-for="estado in estados"
                :value="estado.estado_id"
                :key="estado.estado_id"
              >
                {{ estado.nom_estado }}
              </option>
            </select>
            <div class="errores" v-if="!$v.joven.estado_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <select
              v-model="joven.municipio_id"
              v-on:change="actualizaLocalidades"
              class="form-control"
            >
              <option
                v-for="municipio in municipios"
                :value="municipio.municipio_id"
                :key="municipio.municipio_id"
              >
                {{ municipio.nom_municipio }}
              </option>
            </select>
            <div class="errores" v-if="!$v.joven.municipio_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <select
              v-model="joven.localidad_id"
              v-on:change="actualizaCp"
              class="form-control"
            >
              <option
                v-for="localidad in localidades"
                :value="localidad.localidad_id"
                :key="localidad.localidad_id"
              >
                {{ localidad.nom_localidad }} - {{ localidad.tipo_localidad }}
              </option>
            </select>
            <div class="errores" v-if="!$v.joven.localidad_id.required">
              Requerido
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <input
              maxlength="4"
              type="number"
              v-model="joven.codigo_postal"
              class="form-control"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <input
              maxlength="10"
              type="text"
              v-model="joven.telefono_domicilio"
              class="form-control"
            />
            <div class="errores" v-if="!$v.joven.telefono_domicilio.telefono">
              Ingrese solo números (número con Lada)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import ArchivoImagen from "@/components/ArchivoImagen.vue";
import { required } from "vuelidate/lib/validators";
import { letras, telefono, correo } from "@/libs/validadores";
export default {
  name: "DatosJoven",
  components: { ArchivoImagen },    
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      estados: [],
      municipios: [],
      localidades: [],
      joven: {},
      estado: 1,
      fase: 1,
      hijos: [],
      gradoestudios: [],
    };
  },
  validations() {
    return {
      joven: {
        nombre: { required, letras },
        apellidop: { required, letras },
        apellidom: { required, letras },
        sexo: { required },
        fecha_nac: { required },
        pasatiempo: { required },
        grado_estudio_id: { required },
        celular: { telefono },
        telefono_domicilio: { telefono },
        correo: { correo },
        parroquia: { letras },
        nom_padre: { letras },
        correo_padre: { correo },
        tel_padre: { telefono },
        nom_madre: { letras },
        correo_madre: { correo },
        tel_madre: { telefono },
        calle: { required },
        estado_id: { required },
        municipio_id: { required },
        localidad_id: { required },
      },
    };
  },
  methods: {
    valido: function () {
      this.$v.joven.$touch();
      return !this.$v.joven.$invalid;
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.joven.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.joven.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.joven.localidad_id;
      let joven = this.joven;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          joven.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaJoven: function () {
      this.joven.estado_id != null && this.actualizaMunicipios();
      this.joven.municipio_id != null && this.actualizaLocalidades();
      this.joven.localidad_id != null && this.actualizaCp();
    },
  },
  computed: {},
  mounted: function () {
    this.joven = this.value;
    this.inicializaJoven();
    this.listaEstados();
    this.listaGradoEstudios();

  },
};
</script>
