import Usuarios from '@/views/Usuarios.vue';
import Equiperos from '@/views/Equiperos.vue';
import Roles from '@/views/Roles.vue';
import Sectores from '@/views/Sectores.vue';
import Diocesis from '@/views/Diocesis.vue';
import Ciclos from '@/views/Ciclos.vue';
import Funciones from '@/views/Funciones.vue';
import Bitacora from '@/views/Bitacora.vue';
import DirectorioServicios from '@/views/DirectorioServicios.vue';
import store from '@/store';

export default [
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/equiperos',
    name: 'Equiperos',
    component: Equiperos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/funciones',
    name: 'Funciones',
    component: Funciones,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/sectores',
    name: 'Sectores',
    component: Sectores,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/diocesis',
    name: 'Diocesis',
    component: Diocesis,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/ciclos',
    name: 'Ciclos',
    component: Ciclos,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/bitacora',
    name: 'Bitacora',
    component: Bitacora,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/directorio-servicios',
    name: 'DirectorioServicios',
    component: DirectorioServicios,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/acceso');
      }
    }
  }
];
