<template>
  <div>
    <div v-if="reporte == null">
      <h4>Reportes: {{ titulo }}</h4>
      <b-card header="Fase 1">
        <b-form-row>
          <b-col md="4"> Reporte </b-col>
          <b-col md="8">
            <b-form-select
              v-model="fase1"
              v-on:change="mostrarReporte1(fase1)"
            >
              <option :value="null" disabled>Seleccione</option>
              <option v-for="reporte in reportes1" :value="reporte" :key="reporte.reporte_id">
                {{ reporte.nom_reporte }}
              </option>
            </b-form-select>
          </b-col>
          <b-col md="4" v-if="this.$parent.permisos('reportes-d01')"> D-01 Miembros por Diócesis </b-col>
          <b-col md="8" v-if="this.$parent.permisos('reportes-d01')">
            <b-form-select
              v-model="d01"
              v-on:change="mostrarD01(d01)"
            >
              <option :value="null" disabled>Seleccione</option>
              <option v-for="dio in diocesis" :value="dio" :key="dio.diocesis_id">
                {{ dio.nom_diocesis }}
              </option>
            </b-form-select>
          </b-col>
          <b-col md="4" v-if="this.$parent.permisos('reportes-s03')"> S-03 Miembros por Sector </b-col>
          <b-col md="8" v-if="this.$parent.permisos('reportes-s03')">
            <b-form-select
              v-model="s03"
              v-on:change="mostrarS03(s03)"
            >
              <option :value="null" disabled>Seleccione</option>
              <option v-for="sector in sectores" :value="sector" :key="sector.sector_id">
                {{ sector.nom_sector }}
              </option>
            </b-form-select>
          </b-col>
        </b-form-row>
      </b-card>
      <br />
      <b-card header="Fase 2">
        <b-form-row>
          <b-col md="4"> Reporte </b-col>
          <b-col md="8">
            <b-form-select
              v-model="fase2"
              v-on:change="mostrarReporte2(fase2)"
            >
              <option :value="null" disabled>Seleccione</option>
              <option v-for="reporte in reportes2" :value="reporte" :key="reporte.reporte_id">
                {{ reporte.nom_reporte }}
              </option>
            </b-form-select>
          </b-col>
        </b-form-row>
      </b-card>
      <br />
      <b-card header="Fase 3">
        <b-form-row>
          <b-col md="4"> Reporte </b-col>
          <b-col md="8">
            <b-form-select
              v-model="fase3"

              v-on:change="mostrarReporte3(fase3)"
            >
              <option :value="null" disabled>Seleccione</option>
              <option v-for="reporte in reportes3" :value="reporte" :key="reporte.reporte_id">
                {{ reporte.nom_reporte }}
              </option>
            </b-form-select>
          </b-col>
        </b-form-row>
      </b-card>
    </div>
    <div v-if="reporte != null">
      <h4>{{ reporte.nom_reporte }}</h4>
      <filtros-reportes 
        v-model="busqueda"
        ref="busqueda"
        :mostrar="reporte.filtros"
        :tipo-membresia="tipo"
        @input="actualizarReporte"/>
      <div class="row acciones">
        <div class="col-6 text-left">
          Registros encontrados: {{ datos.length }}
        </div>
        <div class="col-6 botones-accion">
          <b-button @click="regresar">Regresar</b-button
          >
          <b-button @click="exportarExcel" :disabled="datos.length == 0"
            >Exportar Excel</b-button
          >
        </div>
      </div>
      <ve-table
        rowKeyFieldName="rownum"
        border-y
        :fixed-header="true"
        :columns="columnas"
        :table-data="datos"
        :max-height="400"
        :cell-selection-option="{ enable: false }"
        :virtualScrollOption="{ enable: true }"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosReportes from "@/components/FiltrosReportes.vue";
import { nextTick } from "vue";
import { exportarExcel } from "@/utils";

export default {
  name: "Evento",
  components: { FiltrosReportes },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      reportes1: [],
      reportes2: [],
      reportes3: [],
      diocesis: [],
      sectores: [],
      fase1: null,
      fase2: null,
      fase3: null,
      s03: null,
      d01: null,
      datos: [],
      busqueda: {},
      mostrar: {},
      reporte: null,
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      permisos: {
        agregar: [
          "",
          "eventos-cap-agregar",
          "eventos-mf-agregar",
          "eventos-fun-agregar",
          "eventos-gral-agregar",
          "",
          "eventos-a2-agregar",
          "",
          "",
          "eventos-nac-agregar",
        ],
        editar: [
          "",
          "eventos-cap-editar",
          "eventos-mf-editar",
          "eventos-fun-editar",
          "eventos-gral-editar",
          "",
          "eventos-a2-editar",
          "",
          "",
          "eventos-nac-editar",
        ],
      },
      columnas: [],
    };
  },
  validations() {
    return {};
  },
  methods: {
    mostrarReporte1(reporte) {
      this.fase2 = null;
      this.fase3 = null;
      
      this.columnas = [];
      this.datos = []; 
      if (reporte != null) {
        this.reporte = reporte;   
        this.actualizarReporte();
      } else {
        this.reporte = {filtros:{}};
      }
    },
    mostrarReporte2(reporte) {
      this.fase1 = null;
      this.fase3 = null;

      this.columnas = [];
      this.datos = []; 
      if (reporte != null) {
        this.reporte = reporte;   
        this.actualizarReporte();
      } else {
        this.reporte = {filtros:{}};
      }
    },
    mostrarReporte3(reporte) {
      this.fase1 = null;
      this.fase2 = null;

      this.columnas = [];
      this.datos = []; 
      if (reporte != null) {
        this.reporte = reporte;   
        this.actualizarReporte();
      } else {
        this.reporte = {filtros:{}};
      }
    },
    mostrarD01(dio) {
      this.$router.push(`/d01/${dio.diocesis_id}/`);
    },
    mostrarS03(sector) {
      this.$router.push(`/s03/${sector.sector_id}/`);
    },
    actualizarReporte() {
      if (this.reporte) {
        console.log(this.reporte);
        api.ejecutarReporte(this.reporte.reporte_id, this.busqueda).then(r => {
          const datos = r.data;
          this.columnas = datos.columnas;
          this.datos = datos.datos; 
        });
      }
    },
    regresar() {
      this.fase1 = null;
      this.fase2 = null;
      this.fase3 = null;
      this.reporte = null;
    },
    exportarExcel() {
      const cols = this.columnas.map(c => c.field);
      exportarExcel(this.datos, cols);
    },
    async inicializa(tipo) {
      this.tipo = tipo;
      this.titulo = Constantes.TIPOS_MEMBRESIA[tipo];
      this.datos = [];
      this.columnas = [];
      this.datos = [];       
      this.reportes = [];
      this.fase1 = null;
      this.fase2 = null;
      this.fase3 = null;
      await nextTick();
      this.actualizar();
    },
    actualizar() {
      api.listarReportes(this.tipo).then((r) => {
        this.reportes1 = [];
        this.reportes2 = [];
        this.reportes3 = [];
        if (Array.isArray(r.data)) {
          r.data.forEach((rep) => {
            this["reportes" + rep.fase].push(rep);
          });
        }
      });
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      })
      api.listarSectores().then((r) => {
        this.sectores = r.data;
      })
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.inicializa(Number.parseInt(this.$route.params.tipo));
      }
    );
    this.inicializa(Number.parseInt(this.$route.params.tipo));
  },
};
</script>

