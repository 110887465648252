<template>
  <div>
    <h4>Evaluaciones de Equiperos {{ titulo }}</h4>
    <filtros-membresia
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      @input="actualizar"
      :mostrar-estado="false"
      :mostrar-rol="false"
      :mostrar-zona="true"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
        <b-button v-if="this.busqueda.sector_id > 0 && $parent.permisos(permisos.editar[tipo])" @click="guardar">Guardar</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="key"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "Equipos",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      busqueda: {},
      cambios: {},
      permisos: {
        editar: ['','matrimonios-equipo-editar','jovenes-equipo-editar', 'mares-equipo-editar', 'ae-equipo-editar', 'adolescentes-equipo-editar'],
      },

      columnas: [
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
          width: 140,
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
          width: 160,
        },
        {
          field: "nivel",
          key: "g",
          title: "Nivel",
          align: "center",
          width: 100,
        },
        {
          key: "zona",
          title: "Zona",
          align: "center",
          edit: true,
          renderBodyCell: ({ row }) => {
            if (this.busqueda.sector_id > 0 && this.$parent.permisos(this.permisos.editar[this.tipo])) {
              return (
                <input
                  class="form-control"
                  type="text"
                  maxlength="4"
                  size="4"
                  value={row.zona}
                  on-change={(event) => {
                    row.zona = event.target.value;
                    row.modificado = true;
                    this.cellDataChange(row);
                  }}
                ></input>
              );
            } else {
              return row.zona;
            }
          },
          width: 100,
        },
        {
          key: "f2",
          title: "Equipo",
          align: "center",
          edit: true,
          renderBodyCell: ({ row }) => {
            if (this.busqueda.sector_id > 0 && this.$parent.permisos(this.permisos.editar[this.tipo])) {
              return (
                <input
                  class="form-control"
                  type="text"
                  maxlength="4"
                  size="4"
                  value={row.num_equipo}
                  on-change={(event) => {
                    row.num_equipo = event.target.value;
                    row.modificado = true;
                    this.cellDataChange(row);
                  }}
                ></input>
              );
            } else {
              return row.num_equipo;
            }
          },
          width: 100,
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "left",
          width: 190,
        },
        {
          key: "d",
          title: "Nombre",
          align: "left",
          width: 240,
          renderBodyCell: ({row}) => 
            row.modificado ? (
              <b>{row.nombre_completo}</b>
             ): (
              row.nombre_completo
             )
          ,
        },
        {
          field: "califT1",
          key: "t1",
          title: "T1",
          align: "left",
          width: 100,
        },
        {
          field: "califT2",
          key: "t2",
          title: "T2",
          align: "left",
          width: 100,
        },
        {
          field: "califT3",
          key: "t3",
          title: "T3",
          align: "left",
          width: 100,
        },
        {
          field: "califT4",
          key: "t4",
          title: "T4",
          align: "left",
          width: 100,
        },
        {
          field: "califT5",
          key: "t5",
          title: "T5",
          align: "left",
          width: 100,
        },
        {
          field: "califT6",
          key: "t6",
          title: "T6",
          align: "left",
          width: 100,
        },
        {
          field: "califT7",
          key: "t7",
          title: "T7",
          align: "left",
          width: 100,
        },
        {
          field: "califT8",
          key: "t8",
          title: "T8",
          align: "left",
          width: 100,
        },
        {
          field: "califT9",
          key: "t9",
          title: "T9",
          align: "left",
          width: 100,
        },
        {
          field: "califT10",
          key: "t10",
          title: "T10",
          align: "left",
          width: 100,
        },
        {
          field: "califT11",
          key: "t11",
          title: "T11",
          align: "left",
          width: 100,
        },
        {
          field: "califT12",
          key: "t12",
          title: "T12",
          align: "left",
          width: 100,
        },
        {
          field: "califT13",
          key: "t13",
          title: "T13",
          align: "left",
          width: 100,
        },
        {
          field: "califT14",
          key: "t14",
          title: "T14",
          align: "left",
          width: 100,
        },
        {
          field: "califT15",
          key: "t15",
          title: "T15",
          align: "left",
          width: 100,
        },
        {
          field: "califT16",
          key: "t16",
          title: "T16",
          align: "left",
          width: 100,
        },
        {
          field: "califT17",
          key: "t17",
          title: "T17",
          align: "left",
          width: 100,
        },
        {
          field: "califR1",
          key: "r1",
          title: "R1",
          align: "left",
          width: 100,
        },
        {
          field: "califR2",
          key: "r2",
          title: "R2",
          align: "left",
          width: 100,
        },
        {
          field: "califR3",
          key: "r3",
          title: "R3",
          align: "left",
          width: 100,
        },
      ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      this.cambios = {},
      api.listarMembresiaCBF(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    cellDataChange(row) {
      this.cambios[row.key] = row;

    },
    
    guardar() {
      if (Object.keys(this.cambios).length > 0) {
        const datos = Object.entries(this.cambios).map(([, v]) => { return {membresia_id: v.membresia_id, rol_membresia_id: v.rol_membresia_id, num_equipo: v.num_equipo, zona: v.zona }});
        api
          .guardarEquipos(datos)
          .then((resp) => {
            this.$parent.mensaje(resp.data.mensaje);
            this.cambios = {};
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("No se han realizado cambios");
      }
    },

  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.cambios = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

