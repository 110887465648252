<template>
  <div>
    <h4 class="modal-title"><font-awesome-icon icon="key" /> Cambiar mi contraseña</h4>
    <form @submit.prevent="actualizarContrasena"> 
      <input autocomplete="false" type="hidden" />
      <div class="d-flex justify-content-center align-items-center">
        <div class="card mx-auto" style="max-width: 500px">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-12">                
                <label for="usuario">Usuario</label>
                <input
                  maxlength="80"
                  autocomplete="off"
                  style="max-width: 500px; border: 1px solid #ccc; padding: 5px; background-color: rgb(104, 102, 102); pointer-events: none;"
                  v-model.trim="$v.usuario.$model"
                  :class="['form-control', estatus($v.usuario)]"
                />
                <div class="errores" v-if="!$v.usuario.required">Requerido</div>
              </div>
              <div class="form-group col-md-12">
                <label for="contrasena_actual">Contraseña actual</label>
                <input
                  type="password"
                  autocomplete="off"
                  maxlength="80"
                  style="max-width: 500px"
                  v-model.trim="contrasena"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.contrasena.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="nueva_contrasena">Contraseña nueva</label>
                <input
                  type="password"
                  autocomplete="off"
                  maxlength="80"
                  style="max-width: 500px"
                  v-model="nuevaContrasena"
                  class="form-control"
                />
                <div class="errores" v-if="!$v.nuevaContrasena.required">
                  Requerido
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="nueva_contrasena2">Confirmar contraseña nueva</label>
                <input
                  type="password"
                  autocomplete="off"
                  maxlength="80"
                  style="max-width: 500px"
                  class="form-control"
                  v-model="nuevaContrasena2"
                />
                <div class="errores" v-if="!$v.nuevaContrasena2.required">
                  Requerido
                </div>
                <div class="errores" v-if="!$v.nuevaContrasena2.iguales">
                  La contraseña nueva y la confirmación de contraseña no coinciden
                </div>
              </div>
              </div>
                <div class="botones-accion text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    v-bind:disabled="guardando"
                    @click="actualizarContrasena"
                  >
                    Actualizar contraseña
                  </b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import api from "@/api";
export default {
  name: "ActualizacionContrasena",
  components: {},
  data() {
    return {
      usuario: "",
      contrasena: "",
      nuevaContrasena: "",
      nuevaContrasena2: "",
      guardando: false,
    };
  },
  validations: {
    usuario: { required },
    contrasena: { required },
    nuevaContrasena: { required },
    nuevaContrasena2: { required, iguales: sameAs ("nuevaContrasena") },
  },
  methods: {

    async actualizarContrasena() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const datos = {
          usuario: this.usuario,
          contrasena: this.contrasena,
          nueva_contrasena: this.nuevaContrasena,
        };

        this.guardando = true;

        try {
          let respuesta = await api.actualizarContrasena(datos);  //Se envían los datos al Back-end
          if (respuesta.data.respuesta) {
            this.$parent.mensaje(respuesta.data.mensaje);  //El mensaje lo envían desde Back-end
            this.$router.push("/");
          }
          else {
            this.$parent.error("No se pudo actualizar la contraseña: " + respuesta.data.mensaje);
          }
        }
        catch (error) {
          this.$parent.error("Error de comuncación con el servidor: " + error);
        }
        finally {
          this.guardando = false;
        }
      }
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error)
        return "dirty";
      return "error";
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
