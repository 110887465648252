<template>
  <div class="contenedorPrincipal">
    <div class="contenedorImagen">
      <img :src="rutaImagen" alt="Acceso restringido" class="imagen-acceso-restringido">
    </div>
    <div>
      <h1 class="tituloPrincipal">Página con acceso restringido</h1>
      <p class="parrafoPrincipal">
        Estás intentando entrar en una página a la que no tienes permitido acceder.
      </p>
      <div class="contenedorBoton">
        <b-button class="btn btn-primary" to="/">
          Ir a la página de inicio
        </b-button>
      </div>
<!--******* Aquí empiza el código que se implementó para el Grid de "Asignación de Roles *******"-->
        <div class="tablaGrid">
          <table>
            <thead>
              <tr>
                <th v-for="column in roles" :key="column">{{ column }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in funciones" :key="rowIndex">
                <td v-for="(column, columnIndex) in roles" :key="columnIndex">
                  <input
                    v-if="typeof row[column] === 'boolean'"
                    type="checkbox"
                    v-model="row[column]"
                    @change="updateCheckbox(row, column)"
                  />
                  <span v-else>{{ row[column] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
<!--******* Aquí termina el código que se implementó para el Grid de "Asignación de Roles *******"-->
      <snackbar baseSize="5rem" ref="snackbar" :holdTime="5000" position="top-center"/>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //Esto se agregó para trabajar con las tablas
export default {
  name: "Salir",
  components: { },
  data() {
    return {
      rutaImagen: require('@/assets/img/acceso-restringido.jpg'),
      funciones: [], // Aquí se guardarán los datos de las filas de la tabla
      roles: [], // Aquí se guardarán los nombres de las columnas de la tabla
    };
  },
  async mounted() {
    // Obtener los datos de las dos tablas desde la Base de Datos (simulado con Axios)
    try {
      const response1 = await axios.get("/api/table1"); // Reemplaza "/api/table1" con tu URL de la API para la primera tabla
      const response2 = await axios.get("/api/table2"); // Reemplaza "/api/table2" con tu URL de la API para la segunda tabla

      const table1Data = response1.data;
      const table2Data = response2.data;

      // Combina los datos de las dos tablas en una sola matriz de filas
      this.funciones = this.combineData(table1Data, table2Data);

      // Obtiene los nombres de las columnas (toma todas las claves de los objetos de la primera fila)
      this.roles = Object.keys(this.funciones[0]);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  },
  methods: {
    combineData(table1Data, table2Data) {
      // Aquí combinas los datos de las dos tablas según la lógica que necesites.
      // Puedes hacer un join, un merge o cualquier otro tipo de combinación.

      // Ejemplo: Unión simple de los datos
      return [...table1Data, ...table2Data];
    },
    updateCheckbox(row, column) {
      // Aquí puedes enviar la actualización del campo al servidor si es necesario.
      // En este ejemplo, solo actualizamos el dato localmente.
      console.log(row.id, column, row[column]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .contenedorPrincipal {
    display: flex;
    align-items: flex-start; /* Alinea los elementos en la parte superior del contenedor */
  }
  .contenedorImagen {
    margin-right: 20px; /* Ajusta el espaciado entre el contenido y la imagen */
  }
  .imagen-acceso-restringido {
    border-radius: 50%;
    width: 200px;
    height: auto;
  }
  .tituloPrincipal {
    color: #e61328 !important;
    font-weight: bold;
  }
  .parrafoPrincipal {
    font-size: 18px;
    color: #ff6600; /* Color naranja, puedes cambiarlo a tu preferencia */
    font-weight: bold;
  }
  .contenedorBoton {
    width: 100%; /* Ocupa todo el ancho disponible para evitar que el botón quede alineado con otros elementos */
    text-align: center; /* Centra el botón horizontalmente */
    margin-top: 20px; /* Espacio entre el contenido y el botón */
  }

</style>