<template>
  <div>
    <h4>Errores</h4>
    <div class="grupo">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Usuario:</label>
          <select class="form-control" v-model="busqueda.usuario_id">
            <option value="" selected>Todos</option>
            <option
              v-for="usuario in usuarios"
              :key="usuario.id"
              v-bind:value="usuario.id"
            >
              {{ usuario.nombre }} {{ usuario.paterno }} {{ usuario.materno }} 
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Fecha:</label>
          <b-form-datepicker
            v-model="busqueda.fecha"
            placeholder="Todas"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="text-right">
        <b-button @click="actualiza">Buscar</b-button>
      </div>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table table-condensed">
        <thead>
          <th>Usuario</th>
          <th>Error</th>
          <th>Contenido</th>
          <th>Adicional</th>
          <th>Ip</th>
          <th>Fecha</th>
        </thead>
        <tr v-for="elemento in listado" :key="elemento.id_solicitud">
          <td>{{ elemento.usuario.nombre }}</td>
          <td>{{ elemento.error }}</td>
          <td>{{ elemento.contenido }}</td>
          <td>{{ elemento.adicional }}</td>
          <td>{{ elemento.ip }}</td>
          <td>{{ elemento.creado }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import moment from 'moment';

export default {
  name: "Bitacora",
  data() {
    return {
      busqueda: { usuario_id: '', fecha: moment(Date.now()).format('YYYY-MM-DD') },
      listado: [],
      usuarios: [],
    };
  },
  methods: {
    actualiza() {
      api.listarErrores(this.busqueda).then((r) => {
        this.listado = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    api
      .listarUsuarios()
      .then((respuesta) => {
        this.usuarios = respuesta.data;
      });
    this.actualiza();
  },
};
</script>
