import Membresia from '@/views/Membresia.vue';
import MembresiaEditar from '@/views/MembresiaEditar.vue';
import store from '@/store';

export default [
  {
    path: '/membresia/agregar/:tipo',
    name: 'MembresiaAgregar',
    component: MembresiaEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/membresia/editar/:id',
    name: 'MembresiaEditar',
    component: MembresiaEditar,
    beforeEnter(to, from, next) {
      //if (store.getters.permisos.includes('membresia-matrimonios-editar')) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/membresia/listar/:tipo',
    name: 'Membresia',
    component: Membresia,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
];