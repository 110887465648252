import Vue from 'vue'
import { Buffer } from 'buffer';
import { utils, write }  from 'xlsx';

export const EventBus = new Vue()

export function jwtValido(jwt) {
    if (jwt == null) {
        return false;
    }
    if (jwt.split('.').length < 3) {
        return false
    }
    /*
    console.log("JWT:");
    console.log(jwt);
    console.log(Buffer.from(jwt.split('.')[1], 'base64').toString());
    */
    const data = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString())
    //console.log(new Date(data.exp * 1000))
    const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
    const now = new Date()
    return now < exp
}
export function tiempoMinimoPaso(jwt) {
    if (jwt == null) {
        return false;
    }
    if (jwt.split('.').length < 3) {
        return false
    }
    const data = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString())
    //console.log(new Date(data.exp * 1000))
    const iat = new Date(data.iat * 1000 + 60 * 1 * 1000) // 5 minutos //TODO: Ajustar tiempo
    //const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
    const now = new Date();
    //console.log(iat);
    //console.log(now);
    return now > iat;
}
export function exportarExcel(json, cols) {
    let worksheet;
    if (cols) {
        let opts = {};
        opts.header = cols;

        worksheet = utils.json_to_sheet(json, opts);
    } else {
        worksheet = utils.json_to_sheet(json);
    }
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Datos");

    const excelBuffer = write(workbook, {
        bookType: "xlsx",
        type: "array"
    });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const filename = "datos.xlsx";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Soporte para Internet Explorer
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        // Otros navegadores
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
