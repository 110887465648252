<template>
  <div>
    <h4>Control de ofrendas {{ titulo }}</h4>
    <filtros-membresia
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      @input="actualizar"
      :mostrar-estado="false"
      :mostrar-rol="false"
    />    
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="membresia_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
    <b-modal ref="ofrenda" title="Captura de ofrenda">
      <b-form-row>
        <b-col cols="12">
          <b-form-group label="Nombre">
            <b-input readonly v-model="ofrenda.nombre"></b-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Mes">
            <b-form-select
              v-model="ofrenda.mes"
              :options="meses"
              value-field="mes"
              text-field="nombre"
            >
              <b-form-select-option :value="null"
                >Seleccione</b-form-select-option
              >
            </b-form-select>
            <div class="errores" v-if="!$v.ofrenda.mes.required">
              Requerido
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Cantidad">
            <b-form-input v-model="ofrenda.cantidad"></b-form-input>
            <div class="errores" v-if="!$v.ofrenda.cantidad.required">
              Requerido
            </div>            
            <div class="errores" v-if="!$v.ofrenda.cantidad.cantidadn">
              Cantidad incorrecta
            </div>            
          </b-form-group>
        </b-col>
      </b-form-row>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardar()">
          Guardar 
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import { nextTick } from "vue";
import { cantidadn } from "@/libs/validadores";
import { required } from "vuelidate/lib/validators";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { exportarExcel } from "@/utils"


export default {
  name: "Equipos",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      mi_sector_id: 0,
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      busqueda: {},
      cambios: {},
      meses: [
        { mes: "9", nombre: "Septiembre" },
        { mes: "10", nombre: "Octubre" },
        { mes: "11", nombre: "Noviembre" },
        { mes: "12", nombre: "Diciembre" },
        { mes: "1", nombre: "Enero" },
        { mes: "2", nombre: "Febrero" },
        { mes: "3", nombre: "Marzo" },
        { mes: "4", nombre: "Abril" },
        { mes: "5", nombre: "Mayo" },
        { mes: "6", nombre: "Junio" },
      ],
      permisos: {
        editar: ['','matrimonios-ofrendas-registrar','jovenes-ofrendas-registrar', 'mares-ofrendas-registrar', 'ae-ofrendas-registrar', 'adolescentes-ofrendas-registrar'],
      },

      ofrenda: {},

      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
          width: 90,
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
          width: 140,
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
          width: 160,
        },
        {
          key: "d",
          title: "Nombre",
          align: "left",
          width: 240,
          renderBodyCell: ({ row }) =>
            row.modificado ? <b>{row.nombre_completo}</b> : row.nombre_completo,
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "left",
          width: 190,
        },
        {
          renderBodyCell: (d) => {
            return ((this.busqueda.sector_id > 0 || d.row.sector_id == this.mi_sector_id) && this.$parent.permisos(this.permisos.editar[this.tipo]) ) ? (
              <a href="javascript:;" onClick={() => this.solicitar(d.row)}>
                <font-awesome-icon size="lg" icon="coins" /> 
              </a>
            ) : (
              '' 
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
          width: 90,
        },
        {
          field: "inscripcion",
          key: "inscripcion",
          title: "Inscripción",
          align: "center",
          width: 60,
        },
        {
          field: "ofrenda",
          key: "ofrenda",
          title: "Ofrenda",
          align: "center",
          width: 60,
        },
        {
          field: "sep",
          key: "a9",
          title: "Sep",
          align: "center",
          width: 60,
        },
        {
          field: "oct",
          key: "a10",
          title: "Oct",
          align: "center",
          width: 60,
        },
        {
          field: "nov",
          key: "a11",
          title: "Nov",
          align: "center",
          width: 60,
        },
        {
          field: "dic",
          key: "a12",
          title: "Dic",
          align: "center",
          width: 60,
        },
        {
          field: "ene",
          key: "a1",
          title: "Ene",
          align: "center",
          width: 60,
        },
        {
          field: "feb",
          key: "a2",
          title: "Feb",
          align: "center",
          width: 60,
        },
        {
          field: "mar",
          key: "a3",
          title: "Mar",
          align: "center",
          width: 60,
        },
        {
          field: "abr",
          key: "a4",
          title: "Abr",
          align: "center",
          width: 60,
        },
        {
          field: "may",
          key: "a5",
          title: "May",
          align: "center",
          width: 60,
        },
        {
          field: "jun",
          key: "a6",
          title: "Jun",
          align: "center",
          width: 60,
        },
        {
          field: "esperado",
          key: "esperado",
          title: "Esperado",
          align: "center",
          width: 80,
        },
        {
          field: "acumulado",
          key: "acumulado",
          title: "Acumulado",
          align: "center",
          width: 80,
        },
      ],
    };
  },
validations() {
    return {
      ofrenda: {
        mes: {
          required,
        },
        cantidad: {
          required, cantidadn,
        },
      },
    };
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      api.listarMembresiaS09(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },

    solicitar(dato) {
      this.ofrenda = {
        membresia_id: dato.membresia_id,
        nombre: dato.nombre_completo,
        mes: null,
        cantidad: null,
      };
      this.$refs.ofrenda.show();
    },

    guardar() {
      this.$v.ofrenda.$touch();
      if (!this.$v.ofrenda.$invalid) {

        api
          .guardarOfrenda(this.ofrenda)
          .then((resp) => {
            this.$parent.mensaje(resp.data.mensaje);
            this.ofrenda = {};
            this.$refs.ofrenda.hide();
            this.actualizar();
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
  },
  mounted() {
    console.log(this.$store.getters.usuario);
    this.mi_sector_id = this.$store.getters.usuario.sector.sector_id;
    console.log("=====>"+this.mi_sector_id);
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.cambios = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

