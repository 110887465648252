<template>
  <div>
    <div class="d-flex justify-content-center align-items-center">
      <img src="../assets/img/pausa.jpg" class="img-fluid w-100" style="max-width: 800px"  alt="Fluid-grow image"  />
    </div>
  </div>
</template>

<script>
import * as utils from '@/utils'

export default {
  name: "Pausa",
  components: { },
  props: {  },
  mounted() {
    utils.EventBus.$on("datos-ciclo", () => {
      if (this.$store.getters.ciclo != null) {
        this.$router.push("/");
      }
      console.log("sesion-iniciada")
    });    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
