import Actas from '@/views/Actas.vue';
import ActasEditar from '@/views/ActasEditar.vue';
import ActasConsultar from '@/views/ActasConsultar.vue';
import ActasRegion from '@/views/ActasRegion.vue';
import ActasRegionEditar from '@/views/ActasRegionEditar.vue';
import ActasRegionConsultar from '@/views/ActasRegionConsultar.vue';
import store from '../store'

export default [
  {
    path: '/actas_region/listar',
    name: 'ActasRegion',
    component: ActasRegion,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/actas_region/editar/:id',
    name: 'ActasRegionEditar',
    component: ActasRegionEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/actas_region/consultar/:id',
    name: 'ActasRegionConsultar',
    component: ActasRegionConsultar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/actas_region/agregar',
    name: 'ActasRegionAgregar',
    component: ActasRegionEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/actas/listar/:tipo/:nivel',
    name: 'Actas',
    component: Actas,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
  {
    path: '/actas/editar/:id',
    name: 'ActasEditar',
    component: ActasEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/actas/consultar/:id',
    name: 'ActasConsultar',
    component: ActasConsultar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  }, 
  {
    path: '/actas/agregar/:tipo/:nivel',
    name: 'ActasAgregar',
    component: ActasEditar,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/sinpermisos')
      }
    }
  },
];