import { helpers } from "vuelidate/lib/validators";

export const telefono = (value) => { return !helpers.req(value) || /^\d{10}$/.test(value);}

export const cantidad = (value) => { return !helpers.req(value) || /^\d+\.?\d*$/.test(value);}

export const cantidadn = (value) => { return !helpers.req(value) || /^-?\d+\.?\d*$/.test(value); }

export const letras = (value) => { return !helpers.req(value) || /^[A-Za-zñÑáéíóúÁÉÍÓÚäÄëËïÏöÖüÜ'. ]+$/.test(value); }

export const correo = (value) => {return !helpers.req(value) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)}

export const numeros = (value) => {return !helpers.req(value) || /^[0-9]*$/.test(value)}


// eslint-disable-next-line no-unused-vars
function curpValida(curp) {
    if (typeof curp == "undefined") {
        return true;
    }
    if (curp == null) {
        return true;
    }
    if (curp.length != 18) {
        return false;
    }

    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    var validado = curp.match(re);

    if (!validado) { //Coincide con el formato general?
        return false;
    }

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10)
            return 0;
        return lngDigito;
    }
    if (validado[2] != digitoVerificador(validado[1]))
        return false;

    return true; //Validado
}
// eslint-disable-next-line no-unused-vars
function telValido(tel) {
    if (typeof tel == "undefined") {
        return true;
    }
    if (tel == null) return true;
    if (tel.length != 10) return false;
    var re = RegExp(/^(\d{10})$/g);
    var validado = tel.match(re);
    return validado;
}