<!-- eslint-disable vue/no-mutating-props -->
<template>
  <li>
    <div
      class="bg-light border-bottom p-1"
      :class="'nivel' + ((item.id % 5) + 1)"
    >
      <a
        href="javascript:;"
        @click="toggle"
        style="width: 12px; margin-left: 0px"
        v-if="isFolder"
      >
        <font-awesome-icon v-show="!isOpen" icon="angle-right" style="width: 12px;"/>
        <font-awesome-icon v-show="isOpen" icon="chevron-down" style="width: 12px;"/>
      </a>
      <input :style="{'margin-left': isFolder ? '0px': '12px'}" type="checkbox" class="custom-form-control"  v-model="item.seleccionado" />
      <font-awesome-icon
        icon="file"
        size="lg"
        v-if="item.tipo != 'opcion'"
        fixed-width
      />
      <font-awesome-icon
        icon="th-list"
        size="lg"
        v-if="item.tipo == 'opcion'"
        fixed-width
      />
      <a @click="toggle" href="javascript:;">
        {{ item.nom_funcion }} [{{ item.clave }}]
      </a>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <tree-funciones-rol
        class="item"
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        @exp-nodo="$emit('exp-nodo', $event)"
      ></tree-funciones-rol>
    </ul>
  </li>
</template>
<script>
import { API_URL } from "@/api";
export default {
  name: "TreeFuncionesRol",
  //template: "#item-template",
  props: {
    item: Object,
  },
  data: function () {
    return {
      ruta: API_URL,
      isOpen: true,
    };
  },
  methods: {
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("edit-nodo", this.item);
      }
    },
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    },
  },
};
</script>
<style scoped>
.custom-form-control {
  width: 40px;
  height: calc(0.5em + 0.5rem + 2px);
  padding: 0px;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>